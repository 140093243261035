import Image from "next/image";
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { useCookies } from 'react-cookie';
import MenuItem from '@mui/material/MenuItem';
import { isMobile } from "react-device-detect";
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useContext, useState, useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Text from "../../Text/Text";
import axios from "@/common/libs/axios";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import robotIcon from "@/assets/images/intireweb-robot.svg"
import LangSwitchBtn from "../LangSwitchBtn/LangSwitchBtn";
import UploadedFiles from "../../UploadedFiles/UploadedFiles";
import TranslationContext from "@/context/TranslationContext";
import Toast from "@/common/components/elements/ToastPopup/ToastPopup";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";

const DocsUploadTab = ({
  t,
  errors,
  activeLLM,
  frontendModel,
  supportedLanguages,
  selectedLanguages,
  onHandleLanguageChange,
  sourceFastLang,
  targetFastLang,
  onSetSourceFastLang,
  onSetTargetFastLang,
  onSetSelectedLanguages }) => {
  const frontend = frontendModel.prop;

  const translationcontext = useContext(TranslationContext);

  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);

  const isArabic = appcontext.locale === "ar";
  const model = translationcontext.model;
  const lLang = translationcontext.lLang;
  const setLLang = translationcontext.setLLang;
  const rLang = translationcontext.rLang;
  const setRLang = translationcontext.setRLang;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,//ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [open, setOpen] = useState(false);
  const [toastMsg, seToastMsg] = useState('');
  const [inputText, setInputText] = useState('');
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [websitePage, setWebsitePage] = useState(null);
  const [cookies, setCookie] = useCookies(['cookie-name']);
  const [showEntireMsg, setShowEntireMsg] = useState(false);
  const [extractionType, setExtractionType] = useState(null);
  const [sourceAnchorEl, setSourceAnchorEl] = useState(null);
  const [targetAnchorEl, setTargetAnchorEl] = useState(null);
  const [isSourceDropdownOpen, setSourceDropdownOpen] = useState(false);
  const [isTargetDropdownOpen, setTargetDropdownOpen] = useState(false);
  /* start enterprise plan*/

  const userPlan = usercontext.user?.plan;
  // Only show dropdowns for users with plan value of 3
  const shouldShowDropdowns = userPlan === 3 && frontend === 'business';

  const handleToastClose = () => {
    setOpen(false);
  };
  const handleLanguageChange = (value, type, isFastLanguage) => {
    if ((type === 'source' && value === selectedLanguages.target) || (type === 'target' && value === selectedLanguages.source)) {
      return;
    }
    onHandleLanguageChange(value, type, isFastLanguage);
    setSourceAnchorEl(null);
    setTargetAnchorEl(null);
  };

  const navigateToHistory = () => {
    setCookie('route', 'history');
    window.location.href = "/" + appcontext.locale;
  }
  const handleClick = (e, type) => {
    if (type === 'source') {
      setSourceDropdownOpen(true);
      setSourceAnchorEl(e.currentTarget);
    } else {
      setTargetDropdownOpen(true);
      setTargetAnchorEl(e.currentTarget);
    }
  };

  const addWebsite = async () => {
    const data = {
      model: model,
      url: inputText,
      type: extractionType,
      frontendModel: activeLLM ? 'llm' : frontend
    };
    setLoadingAdd(true);
    try {
      const res = await axios.post(`/api/user/extract-website`, data);
      setLoadingAdd(false);
      setInputText('');
      setExtractionType(null);
      if (data.type === "single") {
        setWebsitePage([{
          displaySize: "593KB",
          id: 0,
          name: data.url,
          size: 593001,
          type: "web",
          uploadState: 0,
          words: 0,
          doc_convert_token: res.data.doc_convert_token
        }]);
      } else {
        setShowEntireMsg(true);
      }
    } catch (error) {
      const errData = error?.response?.data?.message || 'Something went wrong';
      seToastMsg(errData);
      setOpen(true);
      setLoadingAdd(false);
    }
  };

  const handleClose = (type) => {
    if (type === 'source') {
      setSourceAnchorEl(null);
      setSourceDropdownOpen(false);
    } else {
      setTargetAnchorEl(null);
      setTargetDropdownOpen(false);
    }
  };

  const onLangSwitchClickHandler = () => {
    setLLang(rLang);
    setRLang(lLang);
    translationcontext.setModel(rLang.model + "-" + lLang.model);

    let sourceLangAlreadyExist = sourceFastLang.find(lang => lang.value == rLang.model);
    let targetLangAlreadyExist = targetFastLang.find(lang => lang.value == lLang.model);
    if (!sourceLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == rLang.model);
      const tempArray = [newLang, sourceFastLang[0]];
      onSetSourceFastLang(tempArray);
    }
    if (!targetLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == lLang.model);
      const tempArray = [newLang, targetFastLang[0]];
      onSetTargetFastLang(tempArray);
    }
    onSetSelectedLanguages(rLang, lLang);
  };

  const changeWebsiteUrl = (e) => {
    const inputText = e.target.value;
    setInputText(inputText);
  };

  const changeExtractionType = (e) => {
    const type = e.target.value;
    setExtractionType(type);
  };
  return (
    <div className="h-auto sm:h-[545px] flex flex-col justify-between items-center pb-4 px-2 sm:px-3 lg:mx-[20px] bg-white shadow-xl rounded-md">
      <Toast open={open} toastMsg={toastMsg} handleClose={handleToastClose} />
      {shouldShowDropdowns ? (
        <div className="switchBtn flex items-center justify-center relative">
          {/* Source Language Buttons */}
          <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
            {sourceFastLang.map((lang, key) => {
              return (
                <div className={`hidden md:flex ml-20 rounded-full  py-1 px-2 font-semibold
                    ${selectedLanguages.source === lang.value ? 'bg-red10' : ''}
                    ${selectedLanguages.target === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                  key={key} onClick={() => { handleLanguageChange(lang.value, 'source', true) }}>
                  {isArabic ? lang.labelAr : lang.labelEn}
                </div>
              )
            })}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e, 'source') }}
              aria-label="Open to show more"
              title="Open to show more"
            >
              {isSourceDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.source)?.labelEn}</span>
            <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 10 }}>
              <Select
                className="invisible"
                anchorel={sourceAnchorEl}
                open={Boolean(sourceAnchorEl)}
                MenuProps={MenuProps}
                onClose={() => { handleClose('source') }}
                onChange={(e) => handleLanguageChange(e.target.value, 'source')}
              >
                {supportedLanguages.map((lang) => (
                  <MenuItem
                    key={lang.value}
                    value={lang.value}
                    disabled={lang.value === selectedLanguages.target}
                  >
                    {isArabic ? lang.labelAr : lang.labelEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <LangSwitchBtn
            model={model}
            type={"doc"}
            lLang={lLang}
            rLang={rLang}
            frontendModel={frontend} // Pass the frontendModel prop
            onClickHandler={onLangSwitchClickHandler}
            onlyShowButton="true"
          />
          {/* Target Language Buttons */}
          <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
            {targetFastLang.map((lang, key) => {
              return (
                <div className={`hidden md:flex mr-20 rounded-full  py-1 px-2 font-semibold
                     ${selectedLanguages.target === lang.value ? 'bg-red10' : ''}
                     ${selectedLanguages.source === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                  key={key} onClick={() => { handleLanguageChange(lang.value, 'target') }}>
                  {isArabic ? lang.labelAr : lang.labelEn}
                </div>
              )
            })}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e, 'target') }}
              aria-label="Open to show more"
              title="Open to show more"
            >
              {isTargetDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.target)?.labelEn}</span>
            <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 10 }}>
              <Select
                className="invisible"
                anchorel={targetAnchorEl}
                open={Boolean(targetAnchorEl)}
                MenuProps={MenuProps}
                onClose={() => { handleClose('target') }}
                onChange={(e) => handleLanguageChange(e.target.value, 'target')}
              >
                {supportedLanguages.map((lang) => (
                  <MenuItem
                    key={lang.value}
                    value={lang.value}
                    disabled={lang.value === selectedLanguages.source}
                  >
                    {isArabic ? lang.labelAr : lang.labelEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      ) : (
        <LangSwitchBtn
          model={model}
          type={"doc"}
          lLang={lLang}
          rLang={rLang}
          frontendModel={frontend} // Pass the frontendModel prop
          onClickHandler={onLangSwitchClickHandler}
        />
      )}
      {websitePage ?
        <div
          className="w-[93%] relative py-3 lg:py-3 h-fit md:h-[500px] md:h-[470px] lg:h-[470px] xl:h-[470px] outline-dashed outline-2 outline-grey200 rounded-mm flex justify-center items-center"
        >
          <div className="text-center w-full">
            <AuthValidationErrors className="mb-4" errors={errors} t={t} />
            <div className="w-full sm:w-[95%] md:w-[95%] lg:w-[608px] xl:w-[630px] mx-auto overflow-auto">
              <UploadedFiles frontendUploadModel={frontend} files={websitePage} isWebFile="true" />
              <div
                className="w-full text-center cursor-pointer" onClick={() => { setWebsitePage(null) }}
              >
                <div className="items-center justify-center bg-grey-lighter">
                  <label className="text-black underline hover:text-green font-semibold hover:cursor-pointer">
                    <span className="text-sm leading-normal sm:text-sm md:text-base">
                      {t.TranslateAnotherWebsite}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        showEntireMsg ?
          <>
            <div
              className="w-[93%] relative py-3 lg:py-3 h-fit md:h-[500px] md:h-[470px] lg:h-[470px] xl:h-[470px] outline-dashed outline-2 outline-grey200 rounded-mm flex justify-center items-center"
            >
              <div className="text-center w-full md:w-1/2 flex flex-col gap-3">
                <Image src={robotIcon} width={200} height={200} />
                <h2 className="font-semibold text-md md:text-lg">{t.TranslationInProgress}!</h2>
                <p className="text-sm md:text-md">{t.HangTight} <span className="underline text-green font-medium cursor-pointer" onClick={navigateToHistory}>{t.HistoryTab}.</span></p>
                <p className="text-sm md:text-md underline text-black font-semibold cursor-pointer" onClick={() => { setShowEntireMsg(false) }}>{t.TranslateAnotherWebsite}</p>
              </div>
            </div>
          </>
          :
          <div
            className="bg-white flex flex-col justify-center text-center items-center px-3 w-[98%] py-3 lg:py-3 h-[500px] sm:h-[540px] md:h-[500px] md:h-[470px] lg:h-[470px] xl:h-[470px] outline-dashed bg-grey200opaque outline-grey200 rounded-mm"
          >
            <AuthValidationErrors className="mb-4" errors={errors} t={t} />
            <Text as="h5" className="text-md md:text-lg font-semibold mb-4">
              {t.EnterTheWebsiteURL}
            </Text>
            <div className="w-full md:w-1/2 flex-col md:flex-row flex justify-center gap-2">
              <OutlinedInput
                className="w-full p-2 rounded-xl"
                id="outlined-adornment-amount"
                placeholder={t.PleaseType}
                onChange={(e) => changeWebsiteUrl(e)}
              />
              <Button
                type="button"
                className="btn btn-green rounded-full text-base mt-0 text-nowrap"
                onClick={addWebsite}
                disabled={!inputText || !extractionType || loadingAdd}
              >
                {loadingAdd ? t.loadingAdd : t.Add}
              </Button>
            </div>
            <FormControl className="w-full md:w-1/2">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="w-full justify-between flex"
                onChange={(e) => changeExtractionType(e)}
              >
                <FormControlLabel value="entire" control={<Radio />} label={t.EntireWebsite} className="w-1/2 m-0 text-nowrap" />
                <FormControlLabel value="single" control={<Radio />} label={t.SinglePage} className="w-1/2 m-0" />
              </RadioGroup>
            </FormControl>
          </div>
      }
    </div >
  );
};

export default DocsUploadTab;
