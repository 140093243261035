const LikeIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76471 8V16C4.76471 16.2652 4.66555 16.5196 4.48904 16.7071C4.31254 16.8946 4.07314 17 3.82353 17H1.94118C1.69156 17 1.45217 16.8946 1.27566 16.7071C1.09916 16.5196 1 16.2652 1 16V9C1 8.73478 1.09916 8.48043 1.27566 8.29289C1.45217 8.10536 1.69156 8 1.94118 8H4.76471ZM4.76471 8C5.76317 8 6.72074 7.57857 7.42676 6.82843C8.13277 6.07828 8.52941 5.06087 8.52941 4V3C8.52941 2.46957 8.72773 1.96086 9.08074 1.58579C9.43375 1.21071 9.91253 1 10.4118 1C10.911 1 11.3898 1.21071 11.7428 1.58579C12.0958 1.96086 12.2941 2.46957 12.2941 3V8H15.1176C15.6169 8 16.0957 8.21071 16.4487 8.58579C16.8017 8.96086 17 9.46957 17 10L16.0588 15C15.9235 15.6135 15.6667 16.1402 15.3272 16.501C14.9877 16.8617 14.5838 17.0368 14.1765 17H7.58824C6.83939 17 6.12121 16.6839 5.5917 16.1213C5.06218 15.5587 4.76471 14.7956 4.76471 14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LikeIcon;
