import React, { useContext, useState, useEffect, useRef } from "react";
import Image from "next/image";
import UpgradePlan from './UpgradePlan';
import styles from "./Tabs.module.scss";
import LangIcon from "../Icons/LangIcon";
import DocsIcon from "../Icons/DocsIcon";
import GlobeIcon from "../Icons/GlobeIcon";
import Tooltip from '@mui/material/Tooltip';
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import Twidget from "../TranslationWidgets/Twidget/Twidget";
import TranslationContext from "@/context/TranslationContext";
import DocsUploadTab from "../TranslationWidgets/DocsTab/DocsUploadTab";
import DocsTranslator from "../TranslationWidgets/DocsTab/DocsTranslator";
import WebUploadTab from "../TranslationWidgets/WebExtractionTab/WebUploadTab";



const Tabs = () => {
  const appcontext = useContext(AppContext);
  const translationcontext = useContext(TranslationContext);
  const docs = translationcontext.docs;
  const [errors, setErrors] = useState([]);
  const cardRef = useRef();

  const [activeTab, setActiveTab] = useState(1);
  const [frontend, setFrontend] = useState(null);

  const twidgetProps = {
    business: { prop: "business" },
    legal: { prop: "legal" },
    ecommerce: { prop: "ecommerce" },
    callak: { prop: "callak" },
    openAi: { prop: "openAi" },
    llm: { prop: "llm" },
  };

  const supportedLanguages = [
    { labelAr: 'إنجليزي', labelEn: 'English', value: 'en' },
    { labelAr: 'عربي', labelEn: 'Arabic', value: 'ar' },
    { labelAr: 'تركي', labelEn: 'Turkish', value: 'tr' },
    { labelAr: 'فرنسي', labelEn: 'French', value: 'fr' },
    { labelAr: 'ألماني', labelEn: 'German', value: 'de' },
    { labelAr: 'ايطالي', labelEn: 'Italian', value: 'it' },
    { labelAr: 'أسباني', labelEn: 'Spanish', value: 'es' },
    { labelAr: 'أوكراني', labelEn: 'Ukrainian', value: 'uk' },
    { labelAr: 'روسي', labelEn: 'Russian', value: 'ru' },
    { labelAr: 'فيتنامي', labelEn: 'Vietnamese', value: 'vi' },
    { labelAr: 'برتغالي', labelEn: 'Portuguese', value: 'pt' },
    { labelAr: 'الأردية', labelEn: 'Urdu', value: 'ur' },
    { labelAr: 'هندي', labelEn: 'Hindi', value: 'hi' },
    { labelAr: 'صينى', labelEn: 'Chinese', value: 'zh' },
    { labelAr: 'فارسي', labelEn: 'Persian', value: 'fa' },
    { labelAr: 'هولندي', labelEn: 'Dutch', value: 'nl' },
    { labelAr: 'مالايالامي', labelEn: 'Malayalam', value: 'ml' },
    { labelAr: 'التاميل', labelEn: 'Tamil', value: 'ta' },
    { labelAr: 'كانادا', labelEn: 'Kannada', value: 'kn' },
    { labelAr: 'اليابانية', labelEn: 'Japanese', value: 'ja' },
    { labelAr: 'التايلاندية', labelEn: 'Thai', value: 'th' },
    { labelAr: 'أفريكانية', labelEn: 'Afrikaans', value: 'af' },
    { labelAr: 'ألبانية', labelEn: 'Albanian', value: 'sq' },
    { labelAr: 'أمهرية', labelEn: 'Amharic', value: 'am' },
    { labelAr: 'أرمينية', labelEn: 'Armenian', value: 'hy' },
    { labelAr: 'أذربيجانية', labelEn: 'Azerbaijani', value: 'az' },
    { labelAr: 'الباسكية', labelEn: 'Basque', value: 'eu' },
    { labelAr: 'بيلاروسية', labelEn: 'Belarusian', value: 'be' },
    { labelAr: 'بنغالية', labelEn: 'Bengali', value: 'bn' },
    { labelAr: 'بوسنية', labelEn: 'Bosnian', value: 'bs' },
    { labelAr: 'بلغارية', labelEn: 'Bulgarian', value: 'bg' },
    { labelAr: 'كتالانية', labelEn: 'Catalan', value: 'ca' },
    { labelAr: 'سبوانية', labelEn: 'Cebuano', value: 'ceb' },
    { labelAr: 'تشيتشوا', labelEn: 'Chichewa', value: 'ny' },
    { labelAr: 'قورسيكية', labelEn: 'Corsican', value: 'co' },
    { labelAr: 'كرواتية', labelEn: 'Croatian', value: 'hr' },
    { labelAr: 'تشيكية', labelEn: 'Czech', value: 'cs' },
    { labelAr: 'دنماركية', labelEn: 'Danish', value: 'da' },
    { labelAr: 'إسبرانتو', labelEn: 'Esperanto', value: 'eo' },
    { labelAr: 'إستونية', labelEn: 'Estonian', value: 'et' },
    { labelAr: 'فلبينية', labelEn: 'Filipino', value: 'tl' },
    { labelAr: 'فنلندية', labelEn: 'Finnish', value: 'fi' },
    { labelAr: 'فريزية', labelEn: 'Frisian', value: 'fy' },
    { labelAr: 'غاليكية', labelEn: 'Galician', value: 'gl' },
    { labelAr: 'جورجية', labelEn: 'Georgian', value: 'ka' },
    { labelAr: 'يونانية', labelEn: 'Greek', value: 'el' },
    { labelAr: 'غوجاراتية', labelEn: 'Gujarati', value: 'gu' },
    { labelAr: 'هاوسا', labelEn: 'Hausa', value: 'ha' },
    { labelAr: 'هاوائية', labelEn: 'Hawaiian', value: 'haw' },
    { labelAr: 'عبرية', labelEn: 'Hebrew', value: 'he' },
    { labelAr: 'همونغ', labelEn: 'Hmong', value: 'hmn' },
    { labelAr: 'هنغارية', labelEn: 'Hungarian', value: 'hu' },
    { labelAr: 'أيسلندية', labelEn: 'Icelandic', value: 'is' },
    { labelAr: 'إيغبو', labelEn: 'Igbo', value: 'ig' },
    { labelAr: 'إندونيسية', labelEn: 'Indonesian', value: 'id' },
    { labelAr: 'أيرلندية', labelEn: 'Irish', value: 'ga' },
    { labelAr: 'يابانية', labelEn: 'Japanese', value: 'ja' },
    { labelAr: 'جاوية', labelEn: 'Javanese', value: 'jv' },
    { labelAr: 'كازاخية', labelEn: 'Kazakh', value: 'kk' },
    { labelAr: 'خميرية', labelEn: 'Khmer', value: 'km' },
    { labelAr: 'كينيارواندية', labelEn: 'Kinyarwanda', value: 'rw' },
    { labelAr: 'كورية', labelEn: 'Korean', value: 'ko' },
    { labelAr: 'كردية', labelEn: 'Kurdish', value: 'ku' },
    { labelAr: 'قرغيزية', labelEn: 'Kyrgyz', value: 'ky' },
    { labelAr: 'لاوية', labelEn: 'Lao', value: 'lo' },
    { labelAr: 'لاتينية', labelEn: 'Latin', value: 'la' },
    { labelAr: 'لاتفية', labelEn: 'Latvian', value: 'lv' },
    { labelAr: 'ليتوانية', labelEn: 'Lithuanian', value: 'lt' },
    { labelAr: 'لوكسمبورغية', labelEn: 'Luxembourgish', value: 'lb' },
    { labelAr: 'مقدونية', labelEn: 'Macedonian', value: 'mk' },
    { labelAr: 'مالاغاشية', labelEn: 'Malagasy', value: 'mg' },
    { labelAr: 'ماليزية', labelEn: 'Malay', value: 'ms' },
    { labelAr: 'مالطية', labelEn: 'Maltese', value: 'mt' },
    { labelAr: 'ماورية', labelEn: 'Maori', value: 'mi' },
    { labelAr: 'مراثية', labelEn: 'Marathi', value: 'mr' },
    { labelAr: 'منغولية', labelEn: 'Mongolian', value: 'mn' },
    { labelAr: 'بورمية', labelEn: 'Myanmar (Burmese)', value: 'my' },
    { labelAr: 'نيبالية', labelEn: 'Nepali', value: 'ne' },
    { labelAr: 'نرويجية', labelEn: 'Norwegian', value: 'no' },
    { labelAr: 'أوديا', labelEn: 'Odia', value: 'or' },
    { labelAr: 'بشتونية', labelEn: 'Pashto', value: 'ps' },
    { labelAr: 'بولندية', labelEn: 'Polish', value: 'pl' },
    { labelAr: 'بنجابية', labelEn: 'Punjabi', value: 'pa' },
    { labelAr: 'رومانية', labelEn: 'Romanian', value: 'ro' },
    { labelAr: 'ساموية', labelEn: 'Samoan', value: 'sm' },
    { labelAr: 'أسكتلندية الغيلية', labelEn: 'Scots Gaelic', value: 'gd' },
    { labelAr: 'صربية', labelEn: 'Serbian', value: 'sr' },
    { labelAr: 'السيشوانية', labelEn: 'Sesotho', value: 'st' },
    { labelAr: 'شونا', labelEn: 'Shona', value: 'sn' },
    { labelAr: 'سندهي', labelEn: 'Sindhi', value: 'sd' },
    { labelAr: 'سنغالية', labelEn: 'Sinhala (Sinhalese)', value: 'si' },
    { labelAr: 'سلوفاكية', labelEn: 'Slovak', value: 'sk' },
    { labelAr: 'سلوفينية', labelEn: 'Slovenian', value: 'sl' },
    { labelAr: 'الصومالية', labelEn: 'Somali', value: 'so' },
    { labelAr: 'السوتية', labelEn: 'Southern Sotho', value: 'st' },
    { labelAr: 'سوندانية', labelEn: 'Sundanese', value: 'su' },
    { labelAr: 'سواحيلية', labelEn: 'Swahili', value: 'sw' },
    { labelAr: 'السويدية', labelEn: 'Swedish', value: 'sv' },
    { labelAr: 'تاجيكية', labelEn: 'Tajik', value: 'tg' },
    { labelAr: 'تتارية', labelEn: 'Tatar', value: 'tt' },
    { labelAr: 'تيلوغوية', labelEn: 'Telugu', value: 'te' },
    { labelAr: 'التركمانية', labelEn: 'Turkmen', value: 'tk' },
    { labelAr: 'الأوكرانية', labelEn: 'Ukrainian', value: 'uk' },
    { labelAr: 'الأردية', labelEn: 'Urdu', value: 'ur' },
    { labelAr: 'الأوزبكية', labelEn: 'Uzbek', value: 'uz' },
    { labelAr: 'الفنلندية', labelEn:'Finnish', value: 'fi' }
];


  const [sourceFastLang, setSourceFastLang] = useState([{ value: "en", labelEn: "English", labelAr: "إنجليزي" }, { value: "ar", labelEn: "Arabic", labelAr: "عربي" }]);
  const [targetFastLang, setTargetFastLang] = useState([{ value: "en", labelEn: "English", labelAr: "إنجليزي" }, { value: "ar", labelEn: "Arabic", labelAr: "عربي" }]);

  const changeTab = (tabIndex) => {
    if (activeLLM) {
      return;
    }
    if (tabIndex === 4 || tabIndex === 2) {
      translationcontext.setModel("en-ar");
    }
    setActiveTab(tabIndex);
  };

  const onSetSourceFastLang = (array) => {
    setSourceFastLang(array);
  };
  const onSetTargetFastLang = (array) => {
    setTargetFastLang(array);
  };
  const usercontext = useContext(UserContext);

  const user = usercontext.user;
  const userPlan = usercontext.user?.plan;
  const allowLLM = usercontext.user?.llm;
  const [activeLLM, setActiveLLM] = useState(false);
  const isEnterpriseUser = usercontext.user?.team_id;

  // Initialize source and target languages
  const [selectedLanguages, setSelectedLanguages] = useState({
    source: supportedLanguages[0].value,
    target: supportedLanguages[1].value,
  });
  const updateAllowLLM = () => {
    if ((selectedLanguages.source == 'ar' || selectedLanguages.source == 'en') &&
      (selectedLanguages.target == 'ar' || selectedLanguages.target == 'en') &&
      allowLLM) {
      setActiveLLM(true);
    } else {
      setActiveLLM(false);
    }
  }

  const isSameLanguage = selectedLanguages.source === selectedLanguages.target;
  useEffect(() => {
    updateModel();
    updateAllowLLM();
  }, [selectedLanguages]);

  useEffect(() => {
    setActiveLLM(allowLLM);
  }, [allowLLM]);

  useEffect(() => {
    const frontendWidget = activeTab === 1 ? twidgetProps.business : activeTab === 2 ? twidgetProps.callak : activeTab === 3 ? twidgetProps.legal : twidgetProps.ecommerce;
    console.log(frontendWidget);
    setFrontend(frontendWidget);
  }, [activeTab]);

  const updateModel = () => {
    const newModel = `${selectedLanguages.source}-${selectedLanguages.target}`;
    console.log("New Model:", newModel);
    translationcontext.setModel(newModel);
  };

  const onSetSelectedLanguages = (rLang, lLang) => {
    setSelectedLanguages({
      'source': rLang.model,
      'target': lLang.model
    })
  }
  const handleLanguageChange = (value, type, isFastLanguage) => {
    const Langarray = type === 'source' ? sourceFastLang : targetFastLang;
    const langAlreadyExist = Langarray.find(lang => lang.value == value);
    if (!isFastLanguage && !langAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == value);
      const tempArray = [newLang, Langarray[0]];
      if (type === 'source') {
        setSourceFastLang(tempArray);
      } else {
        setTargetFastLang(tempArray)
      }
    }
    setSelectedLanguages(prevLanguages => ({
      ...prevLanguages,
      [type]: value,
    }));
  };

  // Only show dropdowns for users with plan value of 3

  const t = appcontext.t;
  const locale = appcontext.locale;
  const isArabic = locale === "ar";
  const [openTab, setOpenTab] = useState(1);

  const onTabClick = (e, id) => {
    if ((!userPlan || userPlan !== 3) && id === 3) {
      return;
    }
    e.preventDefault();
    setOpenTab(id);
  };
  useEffect(() => {
    // Set the first tab as active when the component mounts
    setActiveTab(1);
    setFrontend(twidgetProps.business);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  return (
    <>
      <div className="flex justify-center items-center mt-[10px] md:mt-[10px] lg:mt-[20px] xl:mt-[30px]">
        <div className="w-full">
          <ul
            className={`md:w-fit md:mx-auto flex list-none flex-row md:bg-white md:rounded-full p-2 row-container overflow-scroll
                        mb-4 md:shadow-xl gap-2 md:gap-4 items-start md:items-center justify-between md:justify-center ${locale == "ar" ? "" : ""}`}
            role="tablist"
          >
            <li className="mr-1 last:mr-0 flex-none text-center cursor-pointer">
              <a
                className={
                  `flex flex-row  text-[14px] lg:text-[16px] items-center py-2 px-3 shadow-lg rounded-full                
                  ${locale == "ar" ? "" : "font-semibold"} ${openTab === 1 ? "text-white bg-black" : "text-black bg-white border md:border-none"}`}
                onClick={(e) => onTabClick(e, 1)}
                data-toggle="tab"
                role="tablist"
              >
                <span
                  className={`me-[2px] sm:me-[3px] md:me-[4px] lg:me-[6px] xl:me-[10px]`}
                >
                  {openTab === 1 ? <LangIcon fill={"#FFFFFF"} /> : <LangIcon fill={"#000000"} />}
                </span>{" "}
                {t.Text}
              </a>
            </li>
            <li className="-mb-px mr-1 last:mr-0 flex text-center cursor-pointer">
              <a
                className={`flex flex-row  text-[14px] lg:text-[16px] items-center py-2 px-3 shadow-lg rounded-full 
                            ${locale == "ar" ? "" : "font-semibold"} ${openTab === 2 ? "text-white bg-black" : "text-black bg-white border md:border-none"}`}
                onClick={(e) => onTabClick(e, 2)}
                data-toggle="tab"
                role="tablist"
              >
                <span
                  className={`me-[2px] sm:me-[3px] md:me-[4px] lg:me-[6px] xl:me-[10px]`}
                >
                  {openTab === 2 ? <DocsIcon fill={"#FFFFFF"} /> : <DocsIcon fill={"#000000"} />}
                </span>{" "}
                {t.Documents}
              </a>
            </li>
            <li className="-mb-px mr-1 last:mr-0 flex text-center cursor-pointer">
              <a
                className={`flex flex-row  text-[14px] lg:text-[16px] items-center py-2 px-3 shadow-lg rounded-full 
                            ${locale == "ar" ? "" : "font-semibold"} 
                            ${openTab === 3 ? "text-white bg-black" : "text-black bg-white border md:border-none"}
                            ${(!userPlan || userPlan !== 3 || !user.website_extraction) ? 'text-lightgrey' : ''}`}
                onClick={(e) => {
                  if (user?.website_extraction) {
                    onTabClick(e, 3);
                  }
                }}
                data-toggle="tab"
                role="tablist"
              >
                <span
                  className={`me-[2px] sm:me-[3px] md:me-[4px] lg:me-[6px] xl:me-[10px]`}
                >
                  {(!userPlan || userPlan !== 3 || !user.website_extraction) ? <GlobeIcon color={"#88898C"} /> : openTab === 3 ? <GlobeIcon color={"#FFFFFF"} /> : <GlobeIcon color={"#000000"} />}
                </span>
                <span className="text-nowrap">{t.WebExtraction}</span>
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full">
            <div className="flex justify-between w-full items-center">
              <ul className={`fmax-w-full overflow-scroll lg:overflow-auto row-container ${styles.desktopTablet} ${isArabic ? 'flex mr-16 lg:mr-32' : 'ml-16 lg:ml-32'}`}>
                <li className={`${styles.chips} ${activeLLM ? "cursor-auto" : activeTab === 1 ? styles.active : ""}`} onClick={() => changeTab(1)}>
                  <div className={styles.textWrapper}>{userPlan === 3 ? t.General : t.Business}</div>
                </li>
                {/*
                <li className={`${styles.chips} ${activeTab === 2 ? styles.active : ""}`} onClick={() => changeTab(2)}>
                  <div className={styles.textWrapper}>{t.Conversational}</div>
                </li>*/}
                <li className={`${styles.chips} ${activeLLM ? "cursor-auto" : activeTab === 3 ? styles.active : ""}`} onClick={() => changeTab(3)}>
                  <div className={styles.textWrapper}>{t.Legal}</div>
                </li>
                <li className={`${styles.chips} ${activeLLM ? "cursor-auto" : activeTab === 4 ? styles.active : ""}`} onClick={() => changeTab(4)}>
                  <div className={styles.textWrapper}>{t.Commerce}</div>
                </li>
              </ul >
              {isEnterpriseUser &&
                <>
                  <Tooltip title={` ${t.PronoiaTooltip} ${!allowLLM ? t.PronoiaDisabled : !activeLLM ? t.PronoiaEnabled : ""}`} arrow placement="top">
                    <div className={`mb-2 px-2 py-1 rounded-full h-fit flex items-center border`} style={{ background: !allowLLM ? "#F6F6F6" : activeLLM ? "#D3FED8" : "#FFE8E9", borderColor: !allowLLM ? "#D1D2D4" : activeLLM ? "#84FF92" : "#FEA4A8" }}>
                      <div className="mx-1 w-2.5 h-2.5 rounded-full" style={{ background: !allowLLM ? "#ABADB0" : activeLLM ? "#3CB54A" : "#DF2326" }}></div>
                      <p className="text-sm	font-medium">Pronoia</p>
                    </div>
                  </Tooltip>
                </>}
            </div>
            <div className="flex-auto">
              <div className="tab-content tab-space">
                {openTab === 1 &&
                  <>
                    {activeTab !== 5 ?
                      <>
                        <div id={activeTab === 1 ? "link1" : activeTab === 2 ? "link2" : activeTab === 3 ? "link3" : "link4"} >
                          <Twidget
                            t={t}
                            activeLLM={activeLLM}
                            frontend={frontend || twidgetProps.business}
                            supportedLanguages={supportedLanguages}
                            selectedLanguages={selectedLanguages}
                            sourceFastLang={sourceFastLang}
                            targetFastLang={targetFastLang}
                            onSetSourceFastLang={onSetSourceFastLang}
                            onSetTargetFastLang={onSetTargetFastLang}
                            onHandleLanguageChange={handleLanguageChange}
                            onSetSelectedLanguages={onSetSelectedLanguages} />
                        </div>
                      </> :
                      <>
                        <div id="link5">
                          {activeTab === 5 ? (
                            <Twidget
                              t={t}
                              activeLLM={activeLLM}
                              frontend={twidgetProps.openAi}
                              supportedLanguages={supportedLanguages}
                              selectedLanguages={selectedLanguages}
                              sourceFastLang={sourceFastLang}
                              targetFastLang={targetFastLang}
                              onSetSourceFastLang={onSetSourceFastLang}
                              onSetTargetFastLang={onSetTargetFastLang}
                              onHandleLanguageChange={handleLanguageChange}
                              onSetSelectedLanguages={onSetSelectedLanguages} />
                          ) : (
                            <UpgradePlan />
                          )}
                        </div>
                      </>
                    }
                  </>
                }
                {openTab === 2 &&
                  <>
                    <div id="link2">
                      {docs.length > 0 ? (
                        <DocsTranslator
                          t={t}
                          setErrors={setErrors}
                          errors={errors}
                          activeLLM={activeLLM}
                          frontendModel={activeTab === 1 ? twidgetProps.business : activeTab === 2 ? twidgetProps.callak : activeTab === 3 ? twidgetProps.legal : twidgetProps.ecommerce}
                          supportedLanguages={supportedLanguages}
                          selectedLanguages={selectedLanguages}
                          sourceFastLang={sourceFastLang}
                          targetFastLang={targetFastLang}
                          onSetSourceFastLang={onSetSourceFastLang}
                          onSetTargetFastLang={onSetTargetFastLang}
                          onHandleLanguageChange={handleLanguageChange}
                          onSetSelectedLanguages={onSetSelectedLanguages}
                        />
                      ) : (
                        <DocsUploadTab
                          t={t}
                          setErrors={setErrors}
                          errors={errors}
                          activeLLM={activeLLM}
                          frontendModel={activeTab === 1 ? twidgetProps.business : activeTab === 2 ? twidgetProps.callak : activeTab === 3 ? twidgetProps.legal : twidgetProps.ecommerce}
                          supportedLanguages={supportedLanguages}
                          selectedLanguages={selectedLanguages}
                          sourceFastLang={sourceFastLang}
                          targetFastLang={targetFastLang}
                          onSetSourceFastLang={onSetSourceFastLang}
                          onSetTargetFastLang={onSetTargetFastLang}
                          onHandleLanguageChange={handleLanguageChange}
                          onSetSelectedLanguages={onSetSelectedLanguages}
                        />
                      )}
                    </div>
                  </>
                }
                {openTab === 3 &&
                  <>
                    <div id="link3">
                      <WebUploadTab
                        t={t}
                        setErrors={setErrors}
                        errors={errors}
                        activeLLM={activeLLM}
                        frontendModel={activeTab === 1 ? twidgetProps.business : activeTab === 2 ? twidgetProps.callak : activeTab === 3 ? twidgetProps.legal : twidgetProps.ecommerce}
                        supportedLanguages={supportedLanguages}
                        selectedLanguages={selectedLanguages}
                        sourceFastLang={sourceFastLang}
                        targetFastLang={targetFastLang}
                        onSetSourceFastLang={onSetSourceFastLang}
                        onSetTargetFastLang={onSetTargetFastLang}
                        onHandleLanguageChange={handleLanguageChange}
                        onSetSelectedLanguages={onSetSelectedLanguages}
                      />
                    </div>
                  </>
                }
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  );
};

export default Tabs;
