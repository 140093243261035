import MenuItem from '@mui/material/MenuItem';
import { isMobile } from "react-device-detect";
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useContext, useReducer, useState, useEffect } from "react";

import Text from "../../Text/Text";
import Button from "../../Button/Button";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import UserContext from "@/context/UserContext";
import { ADMIN_ROLE_ID } from "@/common/utils/constant";
import LangSwitchBtn from "../LangSwitchBtn/LangSwitchBtn";
import UploadedFiles from "../../UploadedFiles/UploadedFiles";
import TranslationContext from "@/context/TranslationContext";
import AccountOverlay from "../../AccountOverlay/AccountOverlay";
import customMultiDownload from '@/common/utils/custom-multi-download';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";

const DocsTranslator = ({
  t,
  setErrors,
  errors,
  activeLLM,
  frontendModel,
  supportedLanguages,
  selectedLanguages,
  onHandleLanguageChange,
  sourceFastLang,
  targetFastLang,
  onSetSourceFastLang,
  onSetTargetFastLang,
  onSetSelectedLanguages
}) => {
  const { documentTranslation, downloadTranslatedFile, getfilecount } = useAuth(
    {
      middleware: "guest",
    }
  );
  const translationcontext = useContext(TranslationContext);
  const frontendTabs = frontendModel.prop;

  const model = translationcontext.model;
  const setDocs = translationcontext.setDocs;
  const setDisplayDocs = translationcontext.setDisplayDocs;
  const displayDocs = translationcontext.displayDocs;
  const docs = translationcontext.docs;
  const docLimitExceed = translationcontext.docLimitExceed;
  const setDocLimitExceed = translationcontext.setDocLimitExceed;
  const setDocLimitExceedMsg = translationcontext.setDocLimitExceedMsg;
  const docLimitExceedMsg = translationcontext.docLimitExceedMsg;

  const limitExceedType = translationcontext.limitExceedType;
  const setLimitExceedType = translationcontext.setLimitExceedType;
  const lLang = translationcontext.lLang;
  const setLLang = translationcontext.setLLang;
  const rLang = translationcontext.rLang;
  const setRLang = translationcontext.setRLang;
  const appcontext = useContext(AppContext);
  const locale = appcontext.locale;
  const isArabic = locale === "ar";
  const setAllTranslate = translationcontext.setAllTranslate;
  const allTranslate = translationcontext.allTranslate;
  const errorTranslate = translationcontext.errorTranslate;
  const setErrorTranslate = translationcontext.setErrorTranslate;
  const allTranslatedDocuments = translationcontext.allTranslatedDocuments;
  const setAllTranslatedDocuments = translationcontext.setAllTranslatedDocuments;

  const usercontext = useContext(UserContext);
  const allowedFormats = usercontext.allowedFormats;
  const maxDocSize = usercontext.maxDocSize;
  const user = usercontext.user;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,//ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [sourceAnchorEl, setSourceAnchorEl] = useState(null);
  const [targetAnchorEl, setTargetAnchorEl] = useState(null);
  const [isSourceDropdownOpen, setSourceDropdownOpen] = useState(false);
  const [isTargetDropdownOpen, setTargetDropdownOpen] = useState(false);
  /* start enterprise plan*/

  const userPlan = usercontext.user?.plan;
  // Only show dropdowns for users with plan value of 3
  const shouldShowDropdowns = userPlan === 3 && frontendTabs === 'business';

  /* end enterprise plan */
  const UploadDoc = (e) => {
    let newuploadedfiles = Array.from(e.target.files);
    setDocLimitExceed(false);
    setLimitExceedType(0);
    validateFiles(newuploadedfiles);
    setAllTranslate(false);
    setErrorTranslate(false);
  };

  const handleLanguageChange = (value, type, isFastLanguage) => {
    if ((type === 'source' && value === selectedLanguages.target) || (type === 'target' && value === selectedLanguages.source)) {
      return;
    }
    onHandleLanguageChange(value, type, isFastLanguage);
    setSourceAnchorEl(null);
    setTargetAnchorEl(null);
  };

  const handleClick = (e, type) => {
    if (type === 'source') {
      setSourceDropdownOpen(true);
      setSourceAnchorEl(e.currentTarget);
    } else {
      setTargetDropdownOpen(true);
      setTargetAnchorEl(e.currentTarget);
    }
  };

  const handleClose = (type) => {
    if (type === 'source') {
      setSourceAnchorEl(null);
      setSourceDropdownOpen(false);
    } else {
      setTargetAnchorEl(null);
      setTargetDropdownOpen(false);
    }
  };
  const validateFiles = async (uploadedfiles) => {
    let files = [];
    setErrors([]);
    let maxUploadSize = maxDocSize;
    let newuploadedfiles = [];
    let docfiles = Array.from(docs);
    let displayUploadedFiles = Array.from(displayDocs);

    let sizeMB = "";
    let count = displayDocs.length;
    for (let i = 0; i < uploadedfiles.length; i++) {
      let doc = uploadedfiles[i];
      const fileName = doc.name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var tmpAllowedFileFormat = allowedFormats?.toLowerCase()?.split(",");
      let isValidated = false;
      if (user?.role_id !== ADMIN_ROLE_ID) {
        if (!tmpAllowedFileFormat.includes(ext.toLowerCase()) && allowedFormats !== "_all") {
          setErrors([t.fileFormatError]);
          setLimitExceedType(5);
          isValidated = false;
        } else {
          isValidated = true;
        }
      } else {
        isValidated = true;
      }
      if (isValidated) {
        setLimitExceedType(0);
        let size = doc.size;
        let displaySize = "";
        let length = doc.size.toString().length;
        if (length < 4) {
          displaySize = size.toString() + "B";
          sizeMB = size / (1000 * 1000);
        } else if (length >= 4 && length < 7) {
          size = Math.round((size / 1000) * 10) / 10;
          displaySize = size.toString() + "KB";
          sizeMB = size / 1000;
        } else if (size > 6) {
          size = Math.round((size / (1000 * 1000)) * 10) / 10;
          displaySize = size.toString() + "MB";
          sizeMB = size;
        }
        if (sizeMB > parseFloat(maxUploadSize)) {
          setErrors([t.maxFileSize + " " + maxDocSize + t.MB]);
        } else {
          let file = {
            name: doc.name,
            words: 0,
            type: doc.type,
            size: doc.size,
            displaySize: displaySize,
            id: count++,
            uploadState: 0,
          };
          files.push(file);
          newuploadedfiles.push(doc);
        }
      }
    }
    if (files.length > 0) {
      let allDocs = docfiles.concat(newuploadedfiles);
      let allDisplayDocs = displayUploadedFiles.concat(files);
      setDisplayDocs(allDisplayDocs);
      setDocs(allDocs);
      // getFileWordsCount(newuploadedfiles, files);
    }
  };

  const updateAllFileStatues = () => {
    let uploadedDisplayFiles = [...displayDocs];
    for (let i = 0; i < uploadedDisplayFiles.length; i++) {
      let displayFile = { ...uploadedDisplayFiles[i] };
      if (displayFile.uploadState == 0) {
        displayFile.uploadState = 7;
      }
      uploadedDisplayFiles[i] = { ...displayFile };
      setDisplayDocs([...uploadedDisplayFiles]);
    }
  };
  const translateAllDocuments = async () => {
    let uploadedfiles = Array.from(docs);
    let uploadedDisplayFiles = [...displayDocs];

    if (uploadedfiles.length > 1) {
      updateAllFileStatues();
    }
    setTimeout(
      function () {
        uploadedDisplayFiles = [...displayDocs];
      },
      [1000]
    );
    setAllTranslate(true);
    setErrorTranslate(false);
    let translatedDocument = allTranslatedDocuments;
    for (let i = 0; i < uploadedfiles.length; i++) {
      let docfile = uploadedfiles[i];
      let displayFile = { ...uploadedDisplayFiles[i] };
      if (displayFile.uploadState == 0 || displayFile.uploadState == 7) {
        displayFile.uploadState = 1;

        // update sttaus
        uploadedDisplayFiles[i] = { ...displayFile };
        setDisplayDocs([...uploadedDisplayFiles]);
        displayFile.uploadState = 2;
        uploadedDisplayFiles[i] = { ...displayFile };
        setDisplayDocs([...uploadedDisplayFiles]);

        const docResponse = await documentTranslation({
          setErrors,
          docfile,
          model,
          frontendTabs: activeLLM ? 'llm' : frontendTabs
        });
        if (docResponse.status == 402) {
          setDocLimitExceed(true);
          setLimitExceedType(3);
          setDocLimitExceedMsg(docResponse.error);
          displayFile.uploadState = 0;
          uploadedDisplayFiles[i] = { ...displayFile };
          setAllTranslate(true);
          setErrorTranslate(true);
          setDisplayDocs([...uploadedDisplayFiles]);
        } else if (docResponse.status == 422) {
          displayFile.uploadState = 5;
          setErrorTranslate(true);
          uploadedDisplayFiles[i] = { ...displayFile };
          setDisplayDocs([...uploadedDisplayFiles]);
        }
        let projectId = docResponse.doc_convert_token;
        if (projectId) {
          let count = 0;
          let downloadpathinterval = setInterval(async function () {
            const response = await downloadTranslatedFile({
              setErrors,
              projectId,
            });
            displayFile.words = response.data.words_count;
            if (response.data.status == "create") {
              if (count >= 50) {
                clearInterval(downloadpathinterval);
                displayFile.uploadState = 5;
                uploadedDisplayFiles[i] = { ...displayFile };
                setDisplayDocs([...uploadedDisplayFiles]);
              }
              count++;
            } else if (response.data.status == "pretranslate") {
              displayFile.uploadState = 3;
              uploadedDisplayFiles[i] = { ...displayFile };
              setDisplayDocs([...uploadedDisplayFiles]);
            } else if (response.data.status == "generate") {
              // Check if the response data URL is not already present in translatedDocument
              if (!translatedDocument.includes(response.data.url)) {
                translatedDocument.push(response.data.url);
              }

              displayFile.uploadState = 4;

              // Clone the translatedDocument array into translatedDocumentArray
              let translatedDocumentArray = [...translatedDocument];

              clearInterval(downloadpathinterval);

              if (uploadedfiles.length == translatedDocumentArray.length) {

              }

              // Update the displayFile in the uploadedDisplayFiles array
              uploadedDisplayFiles[i] = { ...displayFile };
              setDisplayDocs([...uploadedDisplayFiles]);

              // Update the state variable allTranslatedDocuments with the translatedDocumentArray
              setAllTranslatedDocuments(translatedDocumentArray);
            } else if (response.data.status == "error") {
              setErrors([t.thereIsIssueWithUploadedFile]);
              displayFile.uploadState = 5;
              // update sttaus
              uploadedDisplayFiles[i] = { ...displayFile };
              setDisplayDocs([...uploadedDisplayFiles]);
              clearInterval(downloadpathinterval);
            } else {
              setErrors([t.thereIsIssueWithUploadedFile]);
              displayFile.uploadState = 5;
              // update sttaus
              uploadedDisplayFiles[i] = { ...displayFile };
              setDisplayDocs([...uploadedDisplayFiles]);
              clearInterval(downloadpathinterval);
            }
          }, 3000);
        } else {
          setErrors(docResponse.errors);
        }
      }
    }
  };

  function downloadAllTranslatedDocuments() {
    // Create a Set to store unique URLs
    const uniqueUrlsSet = new Set();

    // Remove duplicate URLs by adding them to the Set (Set automatically keeps only unique values)
    const uniqueTranslatedDocuments = allTranslatedDocuments.filter((url) => {
      if (!uniqueUrlsSet.has(url)) {
        uniqueUrlsSet.add(url);
        return true; // Include the URL in the filtered array
      }
      return false; // Skip duplicate URLs from the filtered array
    });
    customMultiDownload(uniqueTranslatedDocuments);
  }

  // reducer function to handle state changes
  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };
  // destructuring state and dispatch, initializing fileList to empty array
  const [data, dispatch] = useReducer(reducer, {
    inDropZone: false,
    fileList: [],
  });

  // onDragEnter sets inDropZone to true
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  // onDragLeave sets inDropZone to false
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };

  // onDragOver sets inDropZone to true
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // set dropEffect to copy i.e copy of the source item
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  // onDrop sets inDropZone to false and adds files to fileList
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // get files from event on the dataTransfer object as an array
    let files = [...e.dataTransfer.files];
    // ensure a file or files are dropped
    if (files && files.length > 0) {
      // loop over existing files
      const existingFiles = data.fileList.map((f) => f.name);
      // check if file already exists, if so, don't add to fileList
      // this is to prevent duplicates
      files = files.filter((f) => !existingFiles.includes(f.name));

      // dispatch action to add droped file or files to fileList
      dispatch({ type: "ADD_FILE_TO_LIST", files });
      // reset inDropZone to false
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
      validateFiles(files);
    }
  };
  const onLangSwitchClickHandler = () => {
    setLLang(rLang);
    setRLang(lLang);
    translationcontext.setModel(rLang.model + "-" + lLang.model);

    let sourceLangAlreadyExist = sourceFastLang.find(lang => lang.value == rLang.model);
    let targetLangAlreadyExist = targetFastLang.find(lang => lang.value == lLang.model);
    if (!sourceLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == rLang.model);
      const tempArray = [newLang, sourceFastLang[0]];
      onSetSourceFastLang(tempArray);
    }
    if (!targetLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == lLang.model);
      const tempArray = [newLang, targetFastLang[0]];
      onSetTargetFastLang(tempArray);
    }
    onSetSelectedLanguages(rLang, lLang);
  };

  window.onbeforeunload = (event) => {
    // Customize the message you want to display
    //const confirmationMessage = 'Are you sure you want to leave? Changes you made may not be saved.';

    // Set the custom message
    //event.returnValue = confirmationMessage;

    // Some browsers might not use event.returnValue anymore
    //return confirmationMessage;
    //return "Are you sure you want to leave this page?";
  };
  return (
    <div className="h-auto sm:min-h-[545px] flex flex-col justify-between items-center py-[5px] lg:mx-[20px] bg-white shadow-xl rounded-md relative">
      {shouldShowDropdowns ? (
        <div className="switchBtn flex items-center justify-center relative">
          {/* Source Language Buttons */}
          <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
            {sourceFastLang.map((lang, key) => {
              return (
                <div className={`hidden md:flex ml-20 rounded-full  py-1 px-2 font-semibold
                    ${selectedLanguages.source === lang.value ? 'bg-red10' : ''}
                    ${selectedLanguages.target === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                  key={key} onClick={() => { handleLanguageChange(lang.value, 'source', true) }}>
                  {isArabic ? lang.labelAr : lang.labelEn}
                </div>
              )
            })}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e, 'source') }}
              aria-label="Open to show more"
              title="Open to show more"
            >
              {isSourceDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.source)?.labelEn}</span>
            <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 10 }}>
              <Select
                className="invisible"
                anchorel={sourceAnchorEl}
                open={Boolean(sourceAnchorEl)}
                MenuProps={MenuProps}
                onClose={() => { handleClose('source') }}
                onChange={(e) => handleLanguageChange(e.target.value, 'source')}
              >
                {supportedLanguages.map((lang) => (
                  <MenuItem
                    key={lang.value}
                    value={lang.value}
                    disabled={lang.value === selectedLanguages.target}
                  >
                    {isArabic ? lang.labelAr : lang.labelEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <LangSwitchBtn
            model={model}
            type={"doc"}
            lLang={lLang}
            rLang={rLang}
            frontendModel={frontendTabs} // Pass the frontendModel prop
            onClickHandler={onLangSwitchClickHandler}
            onlyShowButton="true"
          />
          {/* Target Language Buttons */}
          <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
            {targetFastLang.map((lang, key) => {
              return (
                <div className={`hidden md:flex mr-20 rounded-full  py-1 px-2 font-semibold
                     ${selectedLanguages.target === lang.value ? 'bg-red10' : ''}
                     ${selectedLanguages.source === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                  key={key} onClick={() => { handleLanguageChange(lang.value, 'target') }}>
                  {isArabic ? lang.labelAr : lang.labelEn}
                </div>
              )
            })}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e, 'target') }}
              aria-label="Open to show more"
              title="Open to show more"
            >
              {isTargetDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.target)?.labelEn}</span>
            <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 10 }}>
              <Select
                className="invisible"
                anchorel={targetAnchorEl}
                open={Boolean(targetAnchorEl)}
                MenuProps={MenuProps}
                onClose={() => { handleClose('target') }}
                onChange={(e) => handleLanguageChange(e.target.value, 'target')}
              >
                {supportedLanguages.map((lang) => (
                  <MenuItem
                    key={lang.value}
                    value={lang.value}
                    disabled={lang.value === selectedLanguages.source}
                  >
                    {isArabic ? lang.labelAr : lang.labelEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      ) : (
        <LangSwitchBtn
          model={model}
          type={"doc"}
          lLang={lLang}
          rLang={rLang}
          frontendModel={frontendTabs} // Pass the frontendModel prop
          onClickHandler={onLangSwitchClickHandler}
        />
      )}
      <div
        className="w-[93%] relative py-3 lg:py-3 h-fit md:h-[500px] md:h-[470px] lg:h-[470px] xl:h-[470px] outline-dashed outline-2 outline-grey200 rounded-mm flex justify-center items-center"
        style={{ background: "rgba(246, 246, 246, 0.50)" }}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDrop={(e) => handleDrop(e)}
      >
        <div className="text-center w-full">
          <AuthValidationErrors className="mb-4" errors={errors} t={t} />
          <div className="w-full sm:w-[95%] md:w-[95%] lg:w-[608px] xl:w-[630px] mx-auto overflow-auto">
            <UploadedFiles frontendUploadModel={frontendTabs} files={translationcontext.displayDocs} />
            <div
              className={`flex flex-row justify-between items-center p-3 m-auto bg-white rounded-sm mx-3 ${locale == "ar" ? "flex-row-reverse" : ""
                }`}
            >
              <div className="items-center justify-center bg-grey-lighter">
                <label className="text-black underline hover:text-green font-semibold hover:cursor-pointer">
                  <span className="text-sm leading-normal sm:text-sm md:text-base">
                    {allTranslate}{t.UploadMoreDocuments}
                  </span>
                  <input
                    type="file"
                    multiple
                    className="hidden"
                    accept={
                      allowedFormats !== "_all"
                        ? "." + allowedFormats.split(",").join(",.")
                        : ""
                    }
                    onChange={(e) => UploadDoc(e)}
                  />
                </label>
              </div>
              {Array.from(docs).length == allTranslatedDocuments.filter($item => $item).length ? (
                <Button
                  as="button"
                  className="btn-green text-sm sm:text-sm md:text-base py-2 mt-0"
                  onClick={() => downloadAllTranslatedDocuments()}
                >
                  {t.DownloadAll}
                </Button>
              ) : (
                <Button
                  as="button"
                  disabled={allTranslate}
                  className="btn-green text-sm sm:text-sm md:text-base py-2 mt-0"
                  onClick={() => translateAllDocuments()}
                >
                  {t.TranslateAll}
                </Button>
              )}
            </div>
            {docLimitExceed && limitExceedType > 2 && (
              <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={docLimitExceedMsg} />
            )}
          </div>
        </div>
      </div>
      <div className="text-left w-[94%] mx-auto">
        <Text as="p" className={`my-3 text-sm ${isArabic ? 'text-right' : 'text-left'}`}>
          {t.Disclaimer}
        </Text>
      </div>
    </div>
  );
};

export default DocsTranslator;
