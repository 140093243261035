import JSZip from 'jszip';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const download = async (content, name) => {
  const blob = new Blob([content]);
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.style.display = 'none';
  document.body.append(a);
  a.click();
  await delay(10); 
  a.remove();
  URL.revokeObjectURL(url);
};

const mimeToExtension = {
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'pptx',
  'text/plain': 'txt',
  'application/x-xliff+xml': 'xliff',
};

export default async function customMultiDownload(urls, { rename } = {}) {
  if (!urls) {
    throw new Error('`urls` required');
  }

  const zip = new JSZip();

  for (const url of urls) {
    const response = await fetch(url);

    if (!response.ok) {
      console.error(`Failed to fetch file from URL: ${url}. Status: ${response.status} ${response.statusText}`);
      continue; // Skip to the next URL if fetch failed
    }

    const content = await response.blob();

    // Detect the MIME type from the response headers
    const contentType =  content.type;

    // Find the corresponding file extension from the MIME type
    const fileExtension = mimeToExtension[contentType] || 'unknown';

    // Generate a timestamp for the name
    const timestamp = Date.now();

    // Use the dynamically detected file extension in the name
    const name = typeof rename === 'function' ? rename({ url, timestamp, urls }) : `file_${timestamp}.${fileExtension}`;

    // Add the fetched file to the zip
    zip.file(name, content, { type: 'blob' });
  }

  // Generate the zip file
  const zipContent = await zip.generateAsync({ type: 'blob' });

  // Trigger the download of the zip file
  download(zipContent, 'download.zip');
}
