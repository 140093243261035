const PdfIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="49" viewBox="0 0 24 30" fill="none">
      <g opacity="0.3">
      <path opacity="0.3" d="M16.7604 6.50961C16.1043 6.50898 15.4753 6.24806 15.0114 5.78416C14.5475 5.32026 14.2866 4.69125 14.286 4.0352V0.599609H3.2292C2.37276 0.599609 1.55141 0.939837 0.945813 1.54543C0.34022 2.15102 0 2.97236 0 3.8288V23.2952C0.0012718 24.1508 0.34205 24.9709 0.947505 25.5755C1.55296 26.18 2.37359 26.5196 3.2292 26.5196H16.5024C17.358 26.5196 18.1786 26.18 18.7841 25.5755C19.3895 24.9709 19.7303 24.1508 19.7316 23.2952V6.50961H16.7604Z" fill="#FF3E4C"/>
      </g>
      <path d="M19.7317 6.50961H16.7605C16.1045 6.50898 15.4755 6.24806 15.0116 5.78416C14.5477 5.32026 14.2868 4.69125 14.2861 4.0352V0.599609L19.7317 6.50961Z" fill="#FF3E4C"/>
      <path d="M15.6718 18.7006H3.28301C3.16366 18.7006 3.0492 18.6531 2.96481 18.5688C2.88042 18.4844 2.83301 18.3699 2.83301 18.2506C2.83333 18.1314 2.88087 18.0173 2.96523 17.9331C3.04959 17.849 3.16387 17.8018 3.28301 17.8018H15.6718C15.7908 17.8018 15.905 17.849 15.9892 17.9332C16.0733 18.0173 16.1206 18.1315 16.1206 18.2506C16.1208 18.3096 16.1093 18.3681 16.0868 18.4227C16.0643 18.4773 16.0313 18.5269 15.9896 18.5687C15.9479 18.6105 15.8984 18.6437 15.8438 18.6663C15.7893 18.6889 15.7308 18.7006 15.6718 18.7006Z" fill="#FF3E4C"/>
      <path d="M15.6718 13.6317H3.28301C3.16387 13.6317 3.04959 13.5844 2.96523 13.5003C2.88087 13.4162 2.83333 13.302 2.83301 13.1829C2.83301 13.0636 2.88042 12.9491 2.96481 12.8647C3.0492 12.7803 3.16366 12.7329 3.28301 12.7329H15.6718C15.7308 12.7329 15.7893 12.7446 15.8438 12.7672C15.8984 12.7898 15.9479 12.823 15.9896 12.8648C16.0313 12.9066 16.0643 12.9562 16.0868 13.0108C16.1093 13.0654 16.1208 13.1239 16.1206 13.1829C16.1206 13.3019 16.0733 13.4161 15.9892 13.5003C15.905 13.5844 15.7908 13.6317 15.6718 13.6317Z" fill="#FF3E4C"/>
      <path d="M15.6718 16.1659H3.28301C3.16366 16.1659 3.0492 16.1185 2.96481 16.0341C2.88042 15.9497 2.83301 15.8352 2.83301 15.7159C2.83333 15.5967 2.88087 15.4826 2.96523 15.3985C3.04959 15.3143 3.16387 15.2671 3.28301 15.2671H15.6718C15.7908 15.2671 15.905 15.3144 15.9892 15.3985C16.0733 15.4827 16.1206 15.5969 16.1206 15.7159C16.1208 15.7749 16.1093 15.8334 16.0868 15.888C16.0643 15.9426 16.0313 15.9922 15.9896 16.034C15.9479 16.0758 15.8984 16.109 15.8438 16.1316C15.7893 16.1542 15.7308 16.1659 15.6718 16.1659Z" fill="#FF3E4C"/>
      <path d="M10.7854 11.097H3.28301C3.16387 11.097 3.04959 11.0498 2.96523 10.9657C2.88087 10.8815 2.83333 10.7674 2.83301 10.6482C2.83301 10.5289 2.88042 10.4144 2.96481 10.33C3.0492 10.2457 3.16366 10.1982 3.28301 10.1982H10.7854C10.9045 10.1986 11.0187 10.2461 11.1028 10.3305C11.187 10.4148 11.2342 10.5291 11.2342 10.6482C11.2339 10.7672 11.1865 10.8811 11.1024 10.9652C11.0183 11.0493 10.9043 11.0967 10.7854 11.097Z" fill="#FF3E4C"/>
      <path d="M10.7854 8.56286H3.28301C3.16387 8.56286 3.04959 8.5156 2.96523 8.43146C2.88087 8.34733 2.83333 8.2332 2.83301 8.11406C2.83301 7.99472 2.88042 7.88023 2.96481 7.79584C3.0492 7.71145 3.16366 7.66406 3.28301 7.66406H10.7854C10.9045 7.66438 11.0187 7.71192 11.1028 7.79628C11.187 7.88064 11.2342 7.99492 11.2342 8.11406C11.2339 8.23299 11.1865 8.34696 11.1024 8.43105C11.0183 8.51515 10.9043 8.56254 10.7854 8.56286Z" fill="#FF3E4C"/>
      <path d="M22.0726 21.0728H7.06782C6.00346 21.0728 5.14062 21.9356 5.14062 23V27.4736C5.14062 28.5379 6.00346 29.4008 7.06782 29.4008H22.0726C23.137 29.4008 23.9998 28.5379 23.9998 27.4736V23C23.9998 21.9356 23.137 21.0728 22.0726 21.0728Z" fill="#FF3E4C"/>
      <path d="M10.063 26.2051V27.0775H9.16064V26.2051H10.063Z" fill="white"/>
      <path d="M11.3905 25.8116V27.0776H10.5181V23.4692H11.9317C12.3601 23.4692 12.6877 23.576 12.9121 23.7884C13.0256 23.9 13.1143 24.0344 13.1722 24.1826C13.2301 24.3309 13.2559 24.4898 13.2481 24.6488C13.2524 24.8586 13.2006 25.0658 13.0981 25.2488C12.9933 25.4278 12.838 25.5718 12.6517 25.6628C12.4269 25.7691 12.1802 25.8205 11.9317 25.8128L11.3905 25.8116ZM12.3601 24.6488C12.3601 24.3288 12.1849 24.1688 11.8345 24.1688H11.3905V25.1084H11.8345C12.1849 25.1116 12.3601 24.9584 12.3601 24.6488Z" fill="white"/>
      <path d="M16.6957 26.2078C16.5455 26.4807 16.3185 26.7035 16.0429 26.8486C15.7328 27.0074 15.388 27.0861 15.0397 27.0778H13.6777V23.4694H15.0397C15.3883 23.4607 15.7336 23.5377 16.0453 23.6938C16.3202 23.8358 16.5466 24.0564 16.6957 24.3274C16.8519 24.6151 16.9304 24.9385 16.9237 25.2658C16.9302 25.5942 16.8517 25.9187 16.6957 26.2078ZM15.7573 26.0266C15.8549 25.9265 15.9305 25.8071 15.9792 25.6761C16.028 25.5451 16.0489 25.4054 16.0405 25.2658C16.0489 25.1265 16.0281 24.9869 15.9793 24.856C15.9305 24.7252 15.8549 24.6061 15.7573 24.5062C15.5391 24.3147 15.254 24.2172 14.9641 24.235H14.5501V26.2966H14.9641C15.2537 26.3142 15.5386 26.2172 15.7573 26.0266Z" fill="white"/>
      <path d="M19.7712 23.4692V24.164H18.2916V24.9548H19.434V25.6184H18.2916V27.0776H17.418V23.4692H19.7712Z" fill="white"/>
      <path d="M15.4523 7.66406H13.3307C12.9609 7.66406 12.6611 7.96385 12.6611 8.33366V10.4553C12.6611 10.8251 12.9609 11.1249 13.3307 11.1249H15.4523C15.8221 11.1249 16.1219 10.8251 16.1219 10.4553V8.33366C16.1219 7.96385 15.8221 7.66406 15.4523 7.66406Z" fill="#FF3E4C"/>
    </svg>

  );
};

export default PdfIcon;
