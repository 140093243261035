const TarjamaLoader = ({ className = "", loaderText, lang }) => {
  return (
    <>
      {lang == "ar" ? (
        <div className="absolute right-4 top-4">
          <div className="flex justify-between items-center">
            <div className="w-full">
              <div className="dot-pulse"></div>
            </div>
            <span className="text-grey100">{loaderText}</span>
          </div>
        </div>
      ) : (
        <div className="absolute left-4 top-4">
          <div className="flex justify-between items-center">
            <span className="text-grey100 ml-6">{loaderText}</span>
            <div className="w-full">
              <div className="dot-pulse-ar"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TarjamaLoader;
