const XliffIcon = () => {
  return (
    <svg
      width="40"
      height="55"
      viewBox="0 12 35 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0882 72H3.91177C2.30364 72 1 70.465 1 68.5714V27.4286C1 25.535 2.30364 24 3.91177 24H23.8L37 39.6V68.5714C37 70.465 35.6964 72 34.0882 72Z"
        fill="url(#paint0_linear_755_10819)"
      />
      <path
        d="M4.20684 60L6.58684 56.67V56.27L4.20684 52.95H6.04684L7.94684 55.65L9.85684 52.95H11.6968L9.30684 56.27V56.67L11.6968 60H9.85684L7.94684 57.29L6.04684 60H4.20684ZM13.1621 60.1C12.8888 60.1067 12.6755 60.0367 12.5221 59.89C12.3755 59.7433 12.3021 59.5333 12.3021 59.26V52.95H13.9021V58.7L17.3321 58.6V60L13.1621 60.1ZM22.8322 54.35H21.2322V58.6H22.8322V60H18.0322V58.6H19.6322V54.35H18.0322V52.95H22.8322V54.35ZM23.7377 60V52.95H28.7677V54.35H25.3377V56.38L28.5377 56.18V57.6L25.3377 57.8V60H23.7377ZM29.5678 60V52.95H34.5978V54.35H31.1678V56.38L34.3678 56.18V57.6L31.1678 57.8V60H29.5678Z"
        fill="white"
      />
      <g filter="url(#filter0_d_755_10819)">
        <path
          d="M37 39.6C29.0095 39.6 22.8035 32.6367 23.7194 24.6989L23.8 24L37 39.6Z"
          fill="#5F4ACA"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_755_10819"
          x="0.629395"
          y="0"
          width="63.3706"
          height="65.6001"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="1" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_755_10819"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_755_10819"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_755_10819"
          x1="19"
          y1="24"
          x2="19"
          y2="72"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.000265127" stopColor="#7B61FF" />
          <stop offset="1" stopColor="#5F4ACA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default XliffIcon;
