const DocxIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7596 5.91044C16.1035 5.9098 15.4746 5.6489 15.0107 5.18502C14.5468 4.72115 14.2859 4.09217 14.2853 3.43615V0.000732645H3.23024C2.8061 0.000575029 2.38607 0.0839658 1.99417 0.24617C1.60226 0.408373 1.24615 0.646207 0.946183 0.946066C0.646212 1.24593 0.408255 1.60194 0.245906 1.99379C0.0835563 2.38563 -2.92858e-08 2.80562 0 3.22976V22.6952C-2.92858e-08 23.1193 0.0835563 23.5393 0.245906 23.9312C0.408255 24.323 0.646212 24.679 0.946183 24.9789C1.24615 25.2788 1.60226 25.5166 1.99417 25.6788C2.38607 25.841 2.8061 25.9244 3.23024 25.9242H16.5016C17.358 25.9242 18.1793 25.584 18.7848 24.9785C19.3904 24.3729 19.7306 23.5516 19.7306 22.6952V5.91044H16.7596Z" fill="#0072FF" />
      </g>
      <path d="M19.7305 5.91044H16.7594C16.1034 5.9098 15.4744 5.6489 15.0106 5.18502C14.5467 4.72115 14.2858 4.09217 14.2852 3.43615V0.000732422L19.7305 5.91044Z" fill="#0072FF" />
      <path d="M15.6714 10.0478H3.28325C3.16423 10.0478 3.05008 10.0005 2.96592 9.91635C2.88176 9.83219 2.83447 9.71805 2.83447 9.59903C2.83432 9.53999 2.84581 9.4815 2.8683 9.42691C2.89078 9.37233 2.92381 9.32271 2.9655 9.28091C3.00719 9.23911 3.0567 9.20595 3.11123 9.18332C3.16575 9.1607 3.22422 9.14905 3.28325 9.14905H15.6714C15.7908 9.14905 15.9052 9.19646 15.9896 9.28085C16.074 9.36524 16.1214 9.47968 16.1214 9.59903C16.1211 9.71816 16.0735 9.83231 15.9892 9.91644C15.9048 10.0006 15.7906 10.0478 15.6714 10.0478Z" fill="#0072FF" />
      <path d="M15.6714 12.7321H3.28325C3.16423 12.7321 3.05008 12.6848 2.96592 12.6007C2.88176 12.5165 2.83447 12.4024 2.83447 12.2834C2.83432 12.2243 2.84581 12.1658 2.8683 12.1112C2.89078 12.0567 2.92381 12.007 2.9655 11.9652C3.00719 11.9234 3.0567 11.8903 3.11123 11.8676C3.16575 11.845 3.22422 11.8334 3.28325 11.8334H15.6714C15.7908 11.8334 15.9052 11.8808 15.9896 11.9651C16.074 12.0495 16.1214 12.164 16.1214 12.2834C16.1211 12.4025 16.0735 12.5166 15.9892 12.6007C15.9048 12.6849 15.7906 12.7321 15.6714 12.7321Z" fill="#0072FF" />
      <path d="M15.6714 15.4163H3.28325C3.22422 15.4163 3.16575 15.4047 3.11123 15.3821C3.0567 15.3594 3.00719 15.3263 2.9655 15.2845C2.92381 15.2427 2.89078 15.1931 2.8683 15.1385C2.84581 15.0839 2.83432 15.0254 2.83447 14.9664C2.83447 14.8473 2.88176 14.7332 2.96592 14.649C3.05008 14.5649 3.16423 14.5176 3.28325 14.5176H15.6714C15.7906 14.5176 15.9048 14.5648 15.9892 14.6489C16.0735 14.7331 16.1211 14.8472 16.1214 14.9664C16.1214 15.0857 16.074 15.2001 15.9896 15.2845C15.9052 15.3689 15.7908 15.4163 15.6714 15.4163Z" fill="#0072FF" />
      <path d="M10.7829 18.1007H3.28325C3.22422 18.1007 3.16575 18.089 3.11123 18.0664C3.0567 18.0438 3.00719 18.0106 2.9655 17.9688C2.92381 17.927 2.89078 17.8774 2.8683 17.8228C2.84581 17.7682 2.83432 17.7097 2.83447 17.6507C2.83447 17.5317 2.88176 17.4175 2.96592 17.3333C3.05008 17.2492 3.16423 17.2019 3.28325 17.2019H10.7829C10.9019 17.2019 11.016 17.2492 11.1002 17.3333C11.1844 17.4175 11.2317 17.5317 11.2317 17.6507C11.2318 17.7097 11.2203 17.7682 11.1978 17.8228C11.1753 17.8774 11.1423 17.927 11.1006 17.9688C11.0589 18.0106 11.0094 18.0438 10.9549 18.0664C10.9004 18.089 10.8419 18.1007 10.7829 18.1007Z" fill="#0072FF" />
      <path d="M22.0728 20.4729H7.0687C6.0044 20.4729 5.1416 21.3357 5.1416 22.4V26.8734C5.1416 27.9377 6.0044 28.8005 7.0687 28.8005H22.0728C23.1371 28.8005 23.9999 27.9377 23.9999 26.8734V22.4C23.9999 21.3357 23.1371 20.4729 22.0728 20.4729Z" fill="#0072FF" />
      <path d="M7.34104 25.6051V26.4775H6.4375V25.6051H7.34104Z" fill="white" />
      <path d="M10.814 25.6074C10.6634 25.88 10.4365 26.1027 10.1612 26.2482C9.85107 26.4068 9.50629 26.4855 9.15805 26.4774H7.79492V22.8692H9.15805C9.50653 22.8607 9.85178 22.9377 10.1636 23.0935C10.4381 23.236 10.6643 23.4565 10.814 23.7271C10.9691 24.0151 11.0472 24.3384 11.0408 24.6655C11.0469 24.9937 10.9689 25.318 10.814 25.6074ZM9.87561 25.4262C9.97316 25.3261 10.0487 25.2067 10.0975 25.0757C10.1463 24.9447 10.1671 24.805 10.1588 24.6655C10.1672 24.5261 10.1463 24.3865 10.0976 24.2557C10.0488 24.1249 9.97319 24.0057 9.87561 23.9059C9.65699 23.7142 9.37145 23.6167 9.08125 23.6347H8.66848V25.6962H9.08125C9.37122 25.714 9.65654 25.617 9.87561 25.4262Z" fill="white" />
      <path d="M14.1773 23.0504C14.4518 23.2063 14.6787 23.434 14.8336 23.7091C14.996 23.9997 15.0788 24.3279 15.0736 24.6607C15.0797 24.9943 14.9969 25.3236 14.8336 25.6146C14.679 25.891 14.4516 26.1197 14.1761 26.2758C13.8955 26.436 13.5775 26.5188 13.2545 26.5158C12.9315 26.5188 12.6135 26.436 12.333 26.2758C12.0574 26.1197 11.83 25.891 11.6754 25.6146C11.5121 25.3236 11.4293 24.9943 11.4354 24.6607C11.4292 24.3278 11.512 23.9993 11.6754 23.7091C11.8308 23.434 12.0581 23.2063 12.333 23.0504C12.6135 22.8902 12.9315 22.8074 13.2545 22.8104C13.5779 22.8075 13.8962 22.8903 14.1773 23.0504ZM12.5693 23.9023C12.3923 24.1141 12.3023 24.3851 12.3174 24.6607C12.3022 24.9352 12.3923 25.2051 12.5693 25.4154C12.6556 25.5092 12.7613 25.5828 12.879 25.6314C12.9967 25.6799 13.1237 25.702 13.2509 25.6962C13.3777 25.7015 13.5041 25.6791 13.6214 25.6306C13.7387 25.5821 13.844 25.5087 13.9301 25.4154C14.1079 25.2055 14.1989 24.9355 14.1845 24.6607C14.1989 24.3863 14.1088 24.1166 13.9325 23.9059C13.8463 23.8121 13.7406 23.7384 13.6228 23.6899C13.5051 23.6414 13.3781 23.6193 13.2509 23.6251C13.124 23.6193 12.9974 23.6411 12.8797 23.6889C12.762 23.7368 12.6561 23.8096 12.5693 23.9023Z" fill="white" />
      <path d="M18.3521 23.1956C18.6565 23.4433 18.8634 23.7905 18.9365 24.176H18.0125C17.9502 24.0162 17.841 23.879 17.6994 23.7824C17.55 23.6852 17.3747 23.6354 17.1966 23.6396C17.0789 23.6358 16.962 23.6592 16.8548 23.7079C16.7477 23.7566 16.6532 23.8293 16.5786 23.9204C16.4116 24.1342 16.3265 24.4006 16.3386 24.6716C16.3264 24.9415 16.4116 25.2068 16.5786 25.4191C16.6538 25.5093 16.7485 25.5811 16.8556 25.6291C16.9627 25.6772 17.0793 25.7001 17.1966 25.6963C17.3748 25.701 17.5502 25.6512 17.6994 25.5535C17.8403 25.4586 17.9494 25.3236 18.0125 25.1659H18.9365C18.8622 25.5503 18.6554 25.8964 18.3521 26.1439C18.0257 26.3931 17.6225 26.5204 17.2122 26.5039C16.8889 26.5107 16.5697 26.4303 16.2882 26.2711C16.0248 26.1179 15.8111 25.8922 15.6727 25.6207C15.5235 25.3231 15.449 24.9936 15.4555 24.6608C15.4491 24.3279 15.5236 23.9985 15.6727 23.7008C15.8111 23.4294 16.0248 23.2036 16.2882 23.0504C16.5697 22.8913 16.8889 22.8108 17.2122 22.8176C17.6246 22.8067 18.0279 22.9405 18.3521 23.1956Z" fill="white" />
      <path d="M21.6156 26.4775L20.8548 25.3543L20.2021 26.4775H19.2013L20.3556 24.62L19.1665 22.8693H20.2021L20.9424 23.9564L21.5796 22.8693H22.5755L21.4368 24.686L22.6511 26.4775H21.6156Z" fill="white" />
    </svg>
  );
};

export default DocxIcon;
