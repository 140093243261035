import { useState, useContext } from "react";

import Button from "../../Button/Button";
import AppContext from "@/context/AppContext";
import TranslationContext from "@/context/TranslationContext";
import LanguageSwitchButton from "@/assets/icons/LanguageSwitchButton";

export default function LangSwitchBtn({
  onClickHandler = function () { },
  lLang = "",
  rLang = "",
  type = "",
  frontendModel, // New prop
  onlyShowButton = false,
}) {
  const translationcontext = useContext(TranslationContext);
  const model = translationcontext.model;
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className={`switchBtn flex items-center justify-center relative ${onlyShowButton || type === "doc" ? "" : "border-b"} ${type === "doc" ? "" : "border-grey200"
        }`}
    >
      {!onlyShowButton && <span
        className={`font-semibold text-grey ${locale == "en" ? "w-[57px]" : "w-[100px] text-center"
          } l-lang`}
      >
        {lLang.lang}
      </span>}
      <Button
        onClick={() => {
          if (frontendModel === "business" || frontendModel === "legal") {
            // Set the model to switch between en-ar and ar-en
            translationcontext.setModel(model === "en-ar" ? "ar-en" : "en-ar");
            onClickHandler(); // Call onClickHandler or add your logic here
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`langSwitch btn btn-outline-green bg-transparent hover:rounded-full hover:bg-transparent focus:bg-transparent focus:outline-0 focus:border-green ring-0 focus:shadow-none focus:ring-0 rounded-full w-[38px] h-[38px] relative p-1 m-2 ${model === "ar-en"
          ? "delay-100 rotate-180 duration-700 ease-in-out"
          : "delay-100 duration-700 ease-in-out"
          }`}
        type="button"
        disabled={frontendModel !== "business" && frontendModel !== 'legal'} // Disable the button when frontendModel is not "business" or "legal"
      >
        {/* <Image src={switchimg} width="30" height="30" /> */}
        {isHovered ? (
          <LanguageSwitchButton color={"#02847B"} />
        ) : (
          <LanguageSwitchButton color={"#00A499"} />
        )}
      </Button>

      {!onlyShowButton && <span
        className={`font-semibold text-grey text-center ${locale == "en" ? "w-[57px]" : "w-[100px]"
          }  r-lang`}
      >
        {rLang.lang}
      </span>}
    </div>
  );
}
