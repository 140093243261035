const DisLikeIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76471 10.0048V2.00482C4.76471 1.7396 4.66555 1.48525 4.48904 1.29771C4.31254 1.11017 4.07314 1.00482 3.82353 1.00482H1.94118C1.69156 1.00482 1.45217 1.11017 1.27566 1.29771C1.09916 1.48525 1 1.7396 1 2.00482V9.00482C1 9.27003 1.09916 9.52439 1.27566 9.71192C1.45217 9.89946 1.69156 10.0048 1.94118 10.0048H4.76471ZM4.76471 10.0048C5.76317 10.0048 6.72074 10.4262 7.42676 11.1764C8.13277 11.9265 8.52941 12.9439 8.52941 14.0048V15.0048C8.52941 15.5352 8.72773 16.044 9.08074 16.419C9.43375 16.7941 9.91253 17.0048 10.4118 17.0048C10.911 17.0048 11.3898 16.7941 11.7428 16.419C12.0958 16.044 12.2941 15.5352 12.2941 15.0048V10.0048H15.1176C15.6169 10.0048 16.0957 9.7941 16.4487 9.41903C16.8017 9.04396 17 8.53525 17 8.00482L16.0588 3.00482C15.9235 2.39134 15.6667 1.86457 15.3272 1.50385C14.9877 1.14313 14.5838 0.967992 14.1765 1.00482H7.58824C6.83939 1.00482 6.12121 1.32089 5.5917 1.8835C5.06218 2.4461 4.76471 3.20917 4.76471 4.00482"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DisLikeIcon;
