const XlsIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7604 5.91001C16.1044 5.90937 15.4754 5.64846 15.0115 5.18456C14.5476 4.72065 14.2866 4.09165 14.286 3.43559V0H3.22922C2.37278 0 1.55141 0.340228 0.94582 0.945821C0.340228 1.55141 0 2.37276 0 3.22919V22.6956C0 23.552 0.340228 24.3734 0.94582 24.979C1.55141 25.5846 2.37278 25.9248 3.22922 25.9248H16.5012C17.3576 25.9248 18.179 25.5846 18.7846 24.979C19.3902 24.3734 19.7304 23.552 19.7304 22.6956V5.91001H16.7604Z" fill="#00C650" />
      </g>
      <path d="M19.7305 5.91001H16.7605C16.1045 5.90937 15.4755 5.64846 15.0116 5.18456C14.5477 4.72065 14.2868 4.09165 14.2861 3.43559V0L19.7305 5.91001Z" fill="#00C650" />
      <path d="M22.0731 20.4733H7.06832C6.00396 20.4733 5.14111 21.3361 5.14111 22.4005V26.8741C5.14111 27.9384 6.00396 28.8013 7.06832 28.8013H22.0731C23.1375 28.8013 24.0003 27.9384 24.0003 26.8741V22.4005C24.0003 21.3361 23.1375 20.4733 22.0731 20.4733Z" fill="#00C650" />
      <path d="M10.492 25.6056V26.478H9.58838V25.6056H10.492Z" fill="white" />
      <path d="M13.1352 26.478L12.3756 25.3548L11.7217 26.478H10.722L11.8752 24.6204L10.686 22.8696H11.7217L12.462 23.9568L13.1004 22.8696H14.0952L12.9576 24.6864L14.172 26.478H13.1352Z" fill="white" />
      <path d="M20.5558 25.9885C20.4523 26.1542 20.3037 26.2869 20.1274 26.3713C19.9129 26.4717 19.6778 26.5206 19.441 26.514C19.0838 26.529 18.7317 26.4247 18.4402 26.2177C18.3113 26.1209 18.2058 25.9963 18.1317 25.8532C18.0575 25.71 18.0165 25.552 18.0118 25.3909H18.9406C18.9473 25.5124 18.999 25.6271 19.0858 25.7125C19.1722 25.793 19.287 25.8361 19.405 25.8325C19.4531 25.8353 19.5013 25.8286 19.5469 25.8127C19.5924 25.7969 19.6343 25.7722 19.6702 25.7401C19.7023 25.709 19.7274 25.6716 19.7442 25.6303C19.7609 25.589 19.7688 25.5446 19.7674 25.5001C19.7686 25.4578 19.7613 25.4157 19.7458 25.3763C19.7303 25.3369 19.7071 25.301 19.6774 25.2709C19.6139 25.2066 19.5385 25.1552 19.4554 25.1197C19.3682 25.0813 19.2458 25.0344 19.0882 24.9792C18.897 24.9162 18.7102 24.8404 18.529 24.7525C18.3802 24.6758 18.2524 24.5637 18.157 24.4261C18.0463 24.2595 17.9917 24.0619 18.001 23.8621C17.9967 23.6641 18.0537 23.4696 18.1642 23.3052C18.2778 23.1447 18.4347 23.0198 18.6166 22.9452C18.8264 22.8599 19.0515 22.8191 19.2778 22.8252C19.6215 22.8063 19.9609 22.9077 20.2378 23.1121C20.3557 23.2079 20.4522 23.3274 20.5213 23.4627C20.5903 23.5981 20.6302 23.7463 20.6386 23.898H19.6942C19.6837 23.7919 19.6381 23.6922 19.5646 23.6149C19.5271 23.5789 19.4826 23.551 19.4339 23.5328C19.3852 23.5147 19.3334 23.5066 19.2814 23.5092C19.1915 23.5052 19.1031 23.5342 19.033 23.5909C19.0007 23.6215 18.9756 23.6589 18.9594 23.7004C18.9432 23.7419 18.9364 23.7864 18.9394 23.8309C18.9376 23.9115 18.9678 23.9896 19.0234 24.048C19.0831 24.1104 19.155 24.1598 19.2346 24.1933C19.3198 24.2305 19.4422 24.2785 19.6018 24.3361C19.7965 24.3995 19.9863 24.4773 20.1694 24.5688C20.319 24.6496 20.448 24.7637 20.5462 24.9025C20.6582 25.0717 20.7137 25.2721 20.7046 25.4749C20.703 25.6565 20.6515 25.8341 20.5558 25.9885Z" fill="white" />
      <path d="M15.8712 25.8096H17.04V26.478H15V22.8696H15.8736L15.8712 25.8096Z" fill="white" />
      <path d="M14.2667 18.1008H5.46834C4.70634 18.1008 4.08594 17.3424 4.08594 16.4088V10.026C4.08594 9.09362 4.70634 8.33521 5.46834 8.33521H14.2704C15.0312 8.33521 15.6516 9.09362 15.6516 10.026V16.4088C15.648 17.3424 15.0275 18.1008 14.2667 18.1008ZM5.46834 9.1992C5.09514 9.1992 4.79275 9.57002 4.79275 10.026V16.4088C4.79275 16.8648 5.09514 17.2356 5.46834 17.2356H14.2704C14.6424 17.2356 14.9447 16.8648 14.9447 16.4088V10.026C14.9447 9.57002 14.6424 9.1992 14.2704 9.1992H5.46834Z" fill="#00C650" />
      <path d="M15.2953 14.2572H4.43652V15.1224H15.2953V14.2572Z" fill="#00C650" />
      <path d="M15.2953 11.2908H4.43652V12.156H15.2953V11.2908Z" fill="#00C650" />
      <path d="M12.5136 8.76721H11.6484V17.6676H12.5136V8.76721Z" fill="#00C650" />
      <path d="M8.08199 8.76721H7.2168V17.6676H8.08199V8.76721Z" fill="#00C650" />
    </svg>
  );
};

export default XlsIcon;
