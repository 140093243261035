import { useContext } from "react";
import FileWidget from "../FileWidget/FileWidget";
import WebFileWidget from "../FileWidget/WebFileWidget";
import TranslationContext from "../../../../context/TranslationContext";

const UploadedFile = ({ frontendUploadModel, files, isWebFile = false }) => {
  const translationcontext = useContext(TranslationContext);
  const displayDocs = translationcontext.displayDocs;
  const frontendTabs = frontendUploadModel;
  return (
    <div className="mb-4 mx-auto max-h-[210px] overflow-auto px-4">
      {isWebFile ?
        <WebFileWidget file={files[0]} frontendUploadModel={frontendTabs} />
        :
        files?.map((file, index) => (
          <FileWidget key={index} file={file} frontendUploadModel={frontendTabs} />
        ))}
    </div>
  );
};

export default UploadedFile;
