const JsonIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7652 5.9088C16.109 5.90816 15.4799 5.64731 15.0158 5.18344C14.5517 4.71957 14.2905 4.09057 14.2896 3.4344V0H3.22919C2.37275 0 1.5514 0.340217 0.945806 0.945809C0.340213 1.5514 0 2.37276 0 3.2292V22.6956C0.0012718 23.5512 0.34205 24.3713 0.947505 24.9759C1.55296 25.5804 2.37358 25.92 3.22919 25.92H16.5012C17.3576 25.92 18.179 25.5798 18.7846 24.9742C19.3902 24.3686 19.7304 23.5472 19.7304 22.6908V5.9088H16.7652Z" fill="#FF3E4C" />
      </g>
      <path d="M19.7303 5.9088H16.7652C16.109 5.90816 15.4799 5.64731 15.0158 5.18344C14.5517 4.71957 14.2905 4.09057 14.2896 3.4344V0L19.7303 5.9088Z" fill="#FF3E4C" />
      <path d="M22.0726 20.472H7.06782C6.00345 20.472 5.14062 21.3349 5.14062 22.3992V26.8728C5.14062 27.9372 6.00345 28.8 7.06782 28.8H22.0726C23.137 28.8 23.9998 27.9372 23.9998 26.8728V22.3992C23.9998 21.3349 23.137 20.472 22.0726 20.472Z" fill="#FF3E4C" />
      <path d="M8.00517 25.6045V26.4769H7.10156V25.6045H8.00517Z" fill="white" />
      <path d="M22.1463 23V26.6364H21.3224L20.0085 24.7259H19.9872V26.6364H19V23H19.8381L21.1307 24.9034H21.1591V23H22.1463Z" fill="white" />
      <path d="M18.5369 24.8679C18.5369 25.2727 18.4582 25.6142 18.3008 25.8924C18.1433 26.1694 17.9309 26.3795 17.6634 26.5227C17.3958 26.6648 17.0975 26.7358 16.7685 26.7358C16.437 26.7358 16.1375 26.6642 15.87 26.521C15.6037 26.3765 15.3918 26.1658 15.2344 25.8888C15.0781 25.6107 15 25.2704 15 24.8679C15 24.4631 15.0781 24.1222 15.2344 23.8452C15.3918 23.567 15.6037 23.3569 15.87 23.2148C16.1375 23.0716 16.437 23 16.7685 23C17.0975 23 17.3958 23.0716 17.6634 23.2148C17.9309 23.3569 18.1433 23.567 18.3008 23.8452C18.4582 24.1222 18.5369 24.4631 18.5369 24.8679ZM17.5213 24.8679C17.5213 24.6501 17.4923 24.4666 17.4343 24.3175C17.3775 24.1671 17.2929 24.0535 17.1804 23.9766C17.0691 23.8984 16.9318 23.8594 16.7685 23.8594C16.6051 23.8594 16.4672 23.8984 16.3548 23.9766C16.2435 24.0535 16.1589 24.1671 16.1009 24.3175C16.044 24.4666 16.0156 24.6501 16.0156 24.8679C16.0156 25.0857 16.044 25.2698 16.1009 25.4201C16.1589 25.5692 16.2435 25.6829 16.3548 25.761C16.4672 25.8379 16.6051 25.8764 16.7685 25.8764C16.9318 25.8764 17.0691 25.8379 17.1804 25.761C17.2929 25.6829 17.3775 25.5692 17.4343 25.4201C17.4923 25.2698 17.5213 25.0857 17.5213 24.8679Z" fill="white" />
      <path d="M14.0526 24.1861C14.0431 24.0677 13.9987 23.9754 13.9194 23.9091C13.8413 23.8428 13.7223 23.8097 13.5625 23.8097C13.4607 23.8097 13.3772 23.8221 13.3121 23.8469C13.2482 23.8706 13.2009 23.9032 13.1701 23.9446C13.1393 23.986 13.1233 24.0334 13.1222 24.0866C13.1198 24.1304 13.1275 24.1701 13.1452 24.2056C13.1642 24.2399 13.1938 24.2713 13.234 24.2997C13.2743 24.3269 13.3258 24.3518 13.3885 24.3743C13.4512 24.3968 13.5258 24.4169 13.6122 24.4347L13.9105 24.4986C14.1117 24.5412 14.284 24.5974 14.4272 24.6673C14.5704 24.7371 14.6876 24.8194 14.7788 24.9141C14.8699 25.0076 14.9368 25.1129 14.9794 25.2301C15.0232 25.3473 15.0457 25.4751 15.0469 25.6136C15.0457 25.8527 14.9859 26.0552 14.8675 26.2209C14.7492 26.3866 14.5799 26.5127 14.3597 26.5991C14.1407 26.6855 13.8774 26.7287 13.5696 26.7287C13.2536 26.7287 12.9777 26.6819 12.7422 26.5884C12.5078 26.4949 12.3255 26.3511 12.1953 26.157C12.0663 25.9616 12.0012 25.7119 12 25.4077H12.9375C12.9434 25.5189 12.9712 25.6125 13.021 25.6882C13.0707 25.764 13.1405 25.8214 13.2305 25.8604C13.3216 25.8995 13.4299 25.919 13.5554 25.919C13.6607 25.919 13.7489 25.906 13.82 25.88C13.891 25.8539 13.9448 25.8178 13.9815 25.7717C14.0182 25.7255 14.0372 25.6728 14.0384 25.6136C14.0372 25.558 14.0188 25.5095 13.9833 25.468C13.949 25.4254 13.8922 25.3875 13.8129 25.3544C13.7335 25.3201 13.6264 25.2881 13.4915 25.2585L13.1293 25.1804C12.8073 25.1106 12.5534 24.994 12.3675 24.8306C12.1829 24.6661 12.0911 24.4418 12.0923 24.1577C12.0911 23.9268 12.1527 23.725 12.277 23.5522C12.4025 23.3782 12.5759 23.2427 12.7972 23.1456C13.0198 23.0485 13.2749 23 13.5625 23C13.8561 23 14.11 23.0491 14.3242 23.1474C14.5385 23.2456 14.7036 23.3841 14.8196 23.5629C14.9368 23.7404 14.996 23.9482 14.9972 24.1861H14.0526Z" fill="white" />
      <path d="M10.6762 23H11.6492V25.4929C11.648 25.7296 11.5882 25.938 11.4698 26.1179C11.3526 26.2966 11.1905 26.4363 10.9833 26.5369C10.7774 26.6364 10.54 26.6861 10.2713 26.6861C10.0381 26.6861 9.82507 26.6458 9.63212 26.5653C9.43918 26.4837 9.28529 26.3558 9.17047 26.1818C9.05565 26.0066 8.99883 25.7794 9.00002 25.5H9.98723C9.99079 25.5911 10.0062 25.6681 10.0334 25.7308C10.0618 25.7936 10.1009 25.8409 10.1506 25.8729C10.2015 25.9036 10.263 25.919 10.3352 25.919C10.4086 25.919 10.4702 25.9031 10.5199 25.8711C10.5708 25.8391 10.6093 25.7918 10.6353 25.729C10.6614 25.6651 10.675 25.5864 10.6762 25.4929V23Z" fill="white" />
      <path d="M8.0533 18.4969H7.78809C7.17609 18.4969 6.68849 18.3173 6.32529 17.9581C5.96209 17.5989 5.78089 17.1137 5.78169 16.5025V14.5441C5.78169 13.7953 5.4837 13.4209 4.8877 13.4209V12.5017C5.4837 12.5017 5.78169 12.1273 5.78169 11.3785V9.42006C5.78169 8.80806 5.96289 8.32246 6.32529 7.96326C6.68769 7.60406 7.17529 7.42486 7.78809 7.42566H8.0533V8.34486H7.87209C7.15209 8.34486 6.79209 8.75166 6.79209 9.56526V11.5717C6.78329 12.2917 6.50329 12.7469 5.95209 12.9373V12.9853C6.49209 13.1629 6.77209 13.6061 6.79209 14.3149V16.3549C6.79209 17.1685 7.15209 17.5753 7.87209 17.5753H8.0533V18.4969Z" fill="#FF3E4C" />
      <path d="M10.6193 12.8122L10.5511 14.5909C10.5028 14.9148 10.429 15.2614 10.3295 15.6307C10.233 16 10.1321 16.348 10.027 16.6747C9.92188 17.0014 9.83523 17.2614 9.76705 17.4545H9C9.03693 17.2727 9.08523 17.0327 9.14489 16.7344C9.20455 16.4361 9.2642 16.1023 9.32386 15.733C9.38636 15.3665 9.4375 14.9915 9.47727 14.608L9.52841 12.8122H10.6193ZM10.0057 10.5341C9.79545 10.5341 9.61506 10.4588 9.46449 10.3082C9.31392 10.1577 9.23864 9.97727 9.23864 9.76705C9.23864 9.55682 9.31392 9.37642 9.46449 9.22585C9.61506 9.07528 9.79545 9 10.0057 9C10.2159 9 10.3963 9.07528 10.5469 9.22585C10.6974 9.37642 10.7727 9.55682 10.7727 9.76705C10.7727 9.90625 10.7372 10.0341 10.6662 10.1506C10.598 10.267 10.5057 10.3608 10.3892 10.4318C10.2756 10.5 10.1477 10.5341 10.0057 10.5341Z" fill="#FF3E4C" />
      <path d="M11.6758 18.4968V17.5776H11.857C12.577 17.5776 12.937 17.1708 12.937 16.3572V14.3172C12.953 13.6076 13.233 13.1644 13.777 12.9876V12.9396C13.2282 12.746 12.9482 12.2908 12.937 11.574V9.56758C12.937 8.75398 12.577 8.34718 11.857 8.34718H11.6758V7.42798H11.9422C12.5542 7.42798 13.0414 7.60718 13.4038 7.96558C13.7662 8.32398 13.9478 8.80958 13.9486 9.42238V11.3808C13.9486 12.1296 14.2466 12.504 14.8426 12.504V13.4232C14.2466 13.4232 13.9486 13.7976 13.9486 14.5464V16.5048C13.9486 17.1168 13.767 17.602 13.4038 17.9604C13.0406 18.3188 12.5534 18.4984 11.9422 18.4992L11.6758 18.4968Z" fill="#FF3E4C" />
    </svg>
  );
};

export default JsonIcon;
