export default function LangIcon({ fill }) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55427 10.1497C8.79068 10.3693 8.86794 10.6986 8.75166 10.9911C8.53673 11.5317 7.79869 11.6824 7.36439 11.2743L5.63154 9.64629L1.95708 13.0985C1.62375 13.4117 1.08332 13.4117 0.749994 13.0985C0.415266 12.7841 0.416883 12.2737 0.753588 11.9611L4.49359 8.48874C3.70877 7.6688 3.03915 6.78394 2.49145 5.8532C2.21291 5.37985 2.59407 4.82314 3.16976 4.82314C3.46771 4.82314 3.73884 4.98075 3.8843 5.22505C4.36574 6.0336 4.94815 6.80152 5.63154 7.51607C6.86361 6.2299 7.76626 4.75884 8.3438 3.20739H1.42366C0.953484 3.20739 0.572334 2.84929 0.572334 2.40755C0.572334 1.96582 0.953484 1.60771 1.42366 1.60771H4.77594V0.803857C4.77594 0.359899 5.15901 0 5.63154 0C6.10408 0 6.48714 0.359899 6.48714 0.803857V1.60771H12.2159C12.6885 1.60771 13.0715 1.96963 13.0715 2.41358C13.0715 2.85754 12.6885 3.21945 12.2159 3.21945H9.96944C9.34485 5.11655 8.2839 6.90915 6.79516 8.46864L6.76949 8.49275L8.55427 10.1497ZM11.9355 8.88867C12.3335 8.88867 12.6899 9.12809 12.8297 9.48949L16.4418 18.8285C16.6609 19.3952 16.2288 19.9999 15.6045 19.9999C15.2318 19.9999 14.8981 19.7757 14.7673 19.4373L13.9104 17.2221H9.37418L8.51735 19.4373C8.38651 19.7757 8.05283 19.9999 7.6801 19.9999C7.05581 19.9999 6.62367 19.3952 6.84284 18.8285L10.455 9.48949C10.5947 9.12809 10.9511 8.88867 11.3491 8.88867H11.9355ZM10.0904 15.3702L11.6423 11.3563L13.1942 15.3702H10.0904Z"
        fill={fill}
      />
    </svg>
  );
}
