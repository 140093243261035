export default function RoundedCloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11L11 17M11 11L17 17L11 11Z"
        stroke="#ABADB0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="14" cy="14" r="9" stroke="#ABADB0" strokeWidth="2" />
    </svg>
  );
}
