const HtmlIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7604 5.9088C16.1043 5.90816 15.4753 5.64727 15.0114 5.18336C14.5475 4.71946 14.2866 4.09046 14.286 3.4344V0H3.22919C2.37296 -5.91198e-08 1.55179 0.340055 0.946231 0.945388C0.340672 1.55072 0.000318183 2.37177 0 3.228V22.6944C0.000954084 23.5502 0.341591 24.3706 0.94708 24.9755C1.55257 25.5803 2.37338 25.92 3.22919 25.92H16.5012C16.9254 25.9202 17.3454 25.8367 17.7373 25.6745C18.1292 25.5123 18.4854 25.2745 18.7854 24.9746C19.0853 24.6747 19.3233 24.3187 19.4857 23.9268C19.648 23.535 19.7316 23.115 19.7316 22.6908V5.9088H16.7604Z" fill="#00C650" />
      </g>
      <path d="M19.7317 5.9088H16.7605C16.1045 5.90816 15.4755 5.64727 15.0116 5.18336C14.5477 4.71946 14.2868 4.09046 14.2861 3.4344V0L19.7317 5.9088Z" fill="#00C650" />
      <path d="M22.0726 20.472H7.0678C6.00344 20.472 5.14062 21.3349 5.14062 22.3992V26.8728C5.14062 27.9372 6.00344 28.8 7.0678 28.8H22.0726C23.137 28.8 23.9998 27.9372 23.9998 26.8728V22.3992C23.9998 21.3349 23.137 20.472 22.0726 20.472Z" fill="#00C650" />
      <path d="M8.15995 25.6045V26.4769H7.25635V25.6045H8.15995Z" fill="white" />
      <path d="M11.7384 22.8684V26.4768H10.86V24.9708H9.49203V26.4768H8.61963V22.8684H9.49203V24.2664H10.86V22.8684H11.7384Z" fill="white" />
      <path d="M14.9328 22.8684V23.562H13.9536V26.4768H13.08V23.562H12.1104V22.8684H14.9328Z" fill="white" />
      <path d="M19.3586 22.8937V26.4769H18.4802V24.2401L17.705 26.4805H16.949L16.169 24.2353V26.4805H15.2954V22.8973H16.3622L17.3378 25.3933L18.2918 22.8973L19.3586 22.8937Z" fill="white" />
      <path d="M20.833 25.8084H21.997V26.4768H19.957V22.8684H20.8306L20.833 25.8084Z" fill="white" />
      <path d="M4.99666 13.7028C4.99534 13.6511 5.01141 13.6004 5.04227 13.5588C5.07867 13.5121 5.12246 13.4715 5.17188 13.4388C5.24664 13.3999 5.32402 13.3662 5.40347 13.338L5.63507 13.242L6.56146 12.852C6.69026 12.792 6.93026 12.692 7.28146 12.552L7.72188 12.372L7.88026 12.3156L7.90786 12.3036C7.91536 12.3019 7.92318 12.3019 7.93068 12.3036C7.94841 12.2957 7.96764 12.2916 7.98708 12.2916C8.02423 12.2897 8.06139 12.2952 8.09641 12.3078C8.13143 12.3203 8.16361 12.3397 8.19107 12.3648C8.21397 12.387 8.23223 12.4134 8.2448 12.4427C8.25737 12.4719 8.264 12.5034 8.26428 12.5352V12.84C8.26321 12.8968 8.24522 12.9519 8.21266 12.9984C8.18001 13.0509 8.13051 13.0906 8.07227 13.1112L6.17987 13.8852L8.07227 14.6592C8.12846 14.6822 8.17708 14.7205 8.21266 14.7696C8.24647 14.8141 8.26464 14.8686 8.26428 14.9244V15.2292C8.26355 15.2942 8.23724 15.3563 8.19107 15.402C8.16409 15.428 8.13204 15.4481 8.09694 15.4611C8.06184 15.4741 8.02445 15.4797 7.98708 15.4776C7.96777 15.4775 7.94866 15.4739 7.93068 15.4668L7.88026 15.45L7.72188 15.3936L7.28146 15.2124L6.56146 14.9136L5.63507 14.5236L5.40347 14.4276C5.32787 14.3976 5.25108 14.364 5.17188 14.3256C5.12259 14.3028 5.08033 14.2671 5.04951 14.2224C5.01869 14.1776 5.00042 14.1254 4.99666 14.0712V13.7028Z" fill="#00C650" />
      <path d="M8.78652 15.8041C8.71172 15.8067 8.63861 15.7815 8.58133 15.7333C8.55583 15.7135 8.53514 15.6883 8.5208 15.6594C8.50646 15.6305 8.49885 15.5987 8.49854 15.5665C8.49854 15.5209 8.63412 15.2065 8.90532 14.6065L9.30133 13.7365L9.38532 13.5553L10.0237 12.1381L10.2493 11.6473L10.2949 11.5345C10.3124 11.4928 10.3333 11.4527 10.3573 11.4145C10.389 11.3625 10.4339 11.3199 10.4875 11.291C10.541 11.2621 10.6013 11.248 10.6621 11.2501H10.9441C11.0193 11.2475 11.0928 11.2727 11.1505 11.3209C11.1758 11.3407 11.1963 11.3661 11.2104 11.3949C11.2245 11.4238 11.232 11.4555 11.2321 11.4877C11.1361 11.7675 11.0231 12.0412 10.8937 12.3073L10.8313 12.4429L10.3513 13.4929L9.71292 14.9101L9.48733 15.4021L9.44172 15.5149C9.42559 15.56 9.40509 15.6034 9.38052 15.6445C9.34652 15.6956 9.3003 15.7374 9.24602 15.7661C9.19174 15.7949 9.13114 15.8095 9.06974 15.8089L8.78652 15.8041Z" fill="#00C650" />
      <path d="M11.7517 15.48C11.7136 15.4824 11.6754 15.4769 11.6395 15.4639C11.6035 15.4509 11.5707 15.4306 11.5429 15.4044C11.5197 15.382 11.5013 15.3551 11.4888 15.3254C11.4762 15.2957 11.4697 15.2638 11.4697 15.2316V14.9268C11.4692 14.8714 11.486 14.8173 11.5177 14.772C11.5536 14.7216 11.6038 14.6832 11.6617 14.6616L13.5589 13.8876L11.6617 13.1136C11.6039 13.0881 11.5548 13.0464 11.5201 12.9936C11.4883 12.9477 11.4707 12.8934 11.4697 12.8376V12.528C11.4698 12.4959 11.4765 12.4641 11.4895 12.4348C11.5025 12.4054 11.5215 12.3791 11.5453 12.3576C11.6019 12.3072 11.676 12.2809 11.7517 12.2844C11.7697 12.2839 11.7874 12.288 11.8033 12.2964C11.8137 12.2945 11.8242 12.2945 11.8345 12.2964L11.8597 12.3084C11.8513 12.3084 11.9029 12.3228 12.0121 12.3648L12.4585 12.5448C12.4353 12.5344 12.6753 12.6344 13.1785 12.8448C13.4529 12.9576 13.7673 13.0912 14.1217 13.2456C14.1445 13.2528 14.1745 13.2648 14.2129 13.2792L14.3425 13.3308C14.4185 13.3579 14.492 13.3916 14.5621 13.4316C14.6127 13.4543 14.6559 13.4908 14.6869 13.5368C14.7178 13.5828 14.7353 13.6365 14.7373 13.692V14.064C14.7368 14.1151 14.72 14.1647 14.6893 14.2056C14.6561 14.2524 14.6126 14.291 14.5621 14.3184C14.4949 14.352 14.4133 14.388 14.3221 14.4264L14.0821 14.5224L11.9917 15.3972C11.9317 15.4272 11.8957 15.4464 11.8849 15.4536L11.8621 15.4704L11.7517 15.48Z" fill="#00C650" />
    </svg>
  );
};

export default HtmlIcon;
