import multiDownload from "multi-download";
import { useState, useContext, useEffect } from "react";

import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import TranslationContext from "@/context/TranslationContext";
import customSingleDownload from '@/common/utils/custom-single-download';
import AuthValidationErrors from "../AuthValidationErrors/AuthValidationErrors";

import Text from "../Text/Text";
import Button from "../Button/Button";
import LanguageIcon from '@mui/icons-material/Language';

const FileWidget = ({ file, frontendUploadModel }) => {
    const { downloadTranslatedFile } = useAuth(
        {
            middleware: "guest",
        }
    );
    const [errors, setErrors] = useState([]);
    const frontendTabs = frontendUploadModel;
    const translationcontext = useContext(TranslationContext);
    const appcontext = useContext(AppContext);
    const t = appcontext.t;
    const locale = appcontext.locale;
    const isArabic = locale === 'ar';
    const [allTranslatedDocuments, setAllTranslatedDocuments] = useState([]);

    useEffect(() => {
        docTranslate();

    }, []);
    const docTranslate = async () => {
        file.uploadState = 1;
        let projectId = file.doc_convert_token;
        if (projectId) {
            let count = 0;
            let downloadpathinterval = setInterval(async function () {
                const response = await downloadTranslatedFile({
                    setErrors,
                    projectId,
                });
                file.words = response.data.words_count;
                switch (response.data.status) {
                    case 'create':
                        if (count >= 50) {
                            setErrors([t.FileLargeNeedMoreTime]);
                            file.uploadState = 5;
                            clearInterval(downloadpathinterval);
                        }
                        count++;
                        break;

                    case 'pretranslate':
                        file.uploadState = 3;
                        break;
                    case 'generate':
                        let translatedDocument = [];
                        translatedDocument[0] = response.data.url;
                        setAllTranslatedDocuments(
                            allTranslatedDocuments.concat(translatedDocument)
                        );
                        clearInterval(downloadpathinterval);
                        file.uploadState = 4;
                        break;
                    case 'error':
                        setErrors([t.thereIsIssueWithUploadedFile]);
                        file.uploadState = 5;
                        clearInterval(downloadpathinterval);
                        break;

                    case 'exceeded':
                        file.uploadState = 6;
                        clearInterval(downloadpathinterval);
                        break;

                    default:
                        setErrors([t.thereIsIssueWithUploadedFile]);
                        file.uploadState = 5;
                        clearInterval(downloadpathinterval);
                }
            }, 3000);
        } else  {
            setErrors([t.limitExceeded]);
            file.uploadState = 6;
        } 
    };

    const downloadFile = (id) => {
        let translatedDocumentPath = allTranslatedDocuments[0];
        translatedDocumentPath = translatedDocumentPath.replace(/^https?:\/\//, '//')
        multiDownload([translatedDocumentPath]);
        //let translatedDocumentPath = file.name.replace(/^https?:\/\//, '//')
        //customSingleDownload(translatedDocumentPath);
    };

    return (
        <>
            <AuthValidationErrors className="mb-4" errors={errors} t={t} />
            <div
                className={`flex gap-0 flex-col border border-grey100 items-center pl-1 pr-3 rounded-sm justify-between mb-4 py-2 sm:py-2 md:py-0 bg-white ${locale == "ar" ? "flex-row-reverse" : ""
                    }`}
            >
                <div className={`flex w-full py-3 items-center${isArabic ? 'flex-row-reverse' : ''}`}>
                    <div className="flex items-center justify-center h-fit mt-2 ms-2">
                        <LanguageIcon fontSize="large" />
                    </div>
                    <div className="w-full md:w-[350px] lg:w-[350px] xl:w-[408px] m-auto truncate ...">
                        <div
                            className={`flex flex-col sm:flex-row justify-between text-base ${locale == "ar" ? "flex-row-reverse items-end" : "items-start"} `}
                        >
                            <div className="flex gap-1 text-left flex-col md:flex-row">
                                <Text
                                    as="p"
                                    className="max-w-[100px] md:max-w-[150px] lg:max-w-[200px] font-semibold truncate ..."
                                >
                                    {file.name}
                                </Text>
                                <Text as="span" className="font-medium flex">
                                    <Text as="span" className="relative">
                                        {file.words > 0 && (
                                            <span className="ml-2"> • {file.words} {t.Words}</span>
                                        )}
                                    </Text>
                                </Text>
                            </div>
                        </div>
                        <div className="hidden md:block relative">
                            <div
                                className="w-full h-[1px] border-[1.5px] mt-2 border-grey100"
                            ></div>
                            <div className="w-1/3 w-2/3 w-3/3 w-0/3"></div>
                            <div
                                className={`w-${file.uploadState == 2
                                    ? "1/3"
                                    : file.uploadState == 3
                                        ? "2/3"
                                        : file.uploadState == 4
                                            ? "full"
                                            : "0"
                                    } h-[1px] absolute border-green border-[1.5px] top-0 ${isArabic ? 'right-0' : 'left-0'}`}
                            ></div>
                        </div>
                    </div>
                    <div className="w-fit sm:w-auto relative flex items-center">
                        {file.uploadState == 1 && (
                            <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                                {t.Analyzing}...
                            </div>
                        )}
                        {file.uploadState == 2 && (
                            <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                                {t.Translating}...
                            </div>
                        )}
                        {file.uploadState == 3 && (
                            <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                                {t.GeneratingFile}...
                            </div>
                        )}
                        {file.uploadState == 4 && (
                            <div className="flex justify-between items-center font-bold text-base">
                                <Button
                                    type="button"
                                    className="text-green hover:bg-transparent hover:text-red px-3 text-base focus:ring-0"
                                    onClick={() => downloadFile(file.id)}
                                >
                                    {t.Download}
                                </Button>
                            </div>
                        )}
                        {file.uploadState == 5 && (
                            <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                                {t.Error}
                            </div>
                        )}
                        {file.uploadState == 7 && (
                            <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                                {t.waiting}
                            </div>
                        )}
                    </div>
                </div>
                <div className="md:hidden relative w-full">
                    <div
                        className="w-full h-[1px] border-[1.5px] border-grey100"
                    ></div>
                    <div className="w-1/3 w-2/3 w-3/3 w-0/3"></div>
                    <div
                        className={`w-${file.uploadState == 2
                            ? "1/3"
                            : file.uploadState == 3
                                ? "2/3"
                                : file.uploadState == 4
                                    ? "full"
                                    : "0"
                            } h-[1px] absolute border-green border-[1.5px] top-0 ${isArabic ? 'right-0' : 'left-0'}`}
                    ></div>
                </div>
            </div>
        </>
    );
};

export default FileWidget;
