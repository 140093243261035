export default function DocsIcon({ fill }) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.44789 20H13.5501C14.5331 20 15.2687 19.7544 15.7571 19.2631C16.2517 18.778 16.499 18.0504 16.499 17.0802V8.61009C16.499 8.20582 16.4768 7.86692 16.4325 7.59328C16.3881 7.31965 16.2993 7.0709 16.1661 6.84702C16.0393 6.61692 15.849 6.37438 15.5953 6.1194L10.354 0.886191C10.113 0.643655 9.87832 0.4602 9.64999 0.335818C9.42175 0.205227 9.17121 0.118164 8.89855 0.0746273C8.6322 0.0248727 8.32142 0 7.96632 0H3.44789C2.46493 0 1.72613 0.245645 1.23148 0.736936C0.743174 1.22824 0.499023 1.95895 0.499023 2.92911V17.0802C0.499023 18.0566 0.743174 18.7873 1.23148 19.2724C1.72613 19.7575 2.46493 20 3.44789 20ZM3.51448 18.4981C3.02618 18.4981 2.65518 18.3737 2.40152 18.125C2.1542 17.8763 2.03053 17.5186 2.03053 17.0523V2.95709C2.03053 2.49689 2.1542 2.1393 2.40152 1.88433C2.65518 1.62935 3.02935 1.50186 3.52399 1.50186H7.75709V6.92164C7.75709 7.50622 7.90608 7.94465 8.20415 8.23691C8.50222 8.52927 8.94928 8.67536 9.54542 8.67536H14.9675V17.0523C14.9675 17.5186 14.8407 17.8763 14.587 18.125C14.3397 18.3737 13.9687 18.4981 13.4741 18.4981H3.51448ZM9.71662 7.26679C9.52641 7.26679 9.39004 7.22637 9.30759 7.14553C9.23145 7.06467 9.19342 6.93097 9.19342 6.7444V1.79105L14.6727 7.26679H9.71662Z"
        fill={fill}
      />
    </svg>
  );
}
