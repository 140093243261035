const CsvIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7604 5.91C16.1043 5.90937 15.4753 5.64845 15.0114 5.18455C14.5475 4.72065 14.2866 4.09164 14.286 3.43559V0H3.2292C2.37276 0 1.5514 0.340228 0.945811 0.94582C0.340219 1.55141 0 2.37275 0 3.22919V22.6956C0.0012718 23.5512 0.342052 24.3713 0.947507 24.9759C1.55296 25.5804 2.37359 25.92 3.2292 25.92H16.5012C16.9254 25.9202 17.3454 25.8368 17.7373 25.6746C18.1293 25.5123 18.4854 25.2745 18.7854 24.9746C19.0854 24.6747 19.3233 24.3187 19.4857 23.9268C19.648 23.535 19.7316 23.115 19.7316 22.6908V5.9052L16.7604 5.91Z" fill="#00C650" />
      </g>
      <path d="M19.7317 5.91H16.7605C16.1045 5.90937 15.4755 5.64845 15.0116 5.18455C14.5477 4.72065 14.2868 4.09164 14.2861 3.43559V0L19.7317 5.91Z" fill="#00C650" />
      <path d="M22.0726 20.4733H7.06783C6.00346 20.4733 5.14062 21.3361 5.14062 22.4005V26.8741C5.14062 27.9384 6.00346 28.8013 7.06783 28.8013H22.0726C23.137 28.8013 23.9998 27.9384 23.9998 26.8741V22.4005C23.9998 21.3361 23.137 20.4733 22.0726 20.4733Z" fill="#00C650" />
      <path d="M9.65856 25.5745V26.4445H8.75977V25.5745H9.65856Z" fill="white" />
      <path d="M12.8879 23.1768C13.1916 23.4226 13.3979 23.7685 13.4699 24.1524H12.5507C12.4894 23.9931 12.381 23.8562 12.2399 23.76C12.0915 23.6627 11.9169 23.6129 11.7395 23.6172C11.6225 23.6137 11.5063 23.6371 11.3998 23.6855C11.2932 23.734 11.1993 23.8063 11.1251 23.8968C10.9602 24.11 10.8772 24.3753 10.8911 24.6445C10.8771 24.9125 10.9602 25.1767 11.1251 25.3884C11.1996 25.4785 11.2937 25.5503 11.4002 25.5983C11.5067 25.6463 11.6227 25.6694 11.7395 25.6656C11.9169 25.67 12.0915 25.6202 12.2399 25.5228C12.3809 25.4286 12.4901 25.2939 12.5531 25.1364H13.4723C13.3988 25.5192 13.1927 25.8638 12.8903 26.1096C12.5658 26.3591 12.164 26.4865 11.7551 26.4696C11.433 26.4767 11.1151 26.3967 10.8347 26.238C10.572 26.0861 10.3593 25.861 10.2227 25.59C10.0748 25.2958 10.0007 24.9701 10.0067 24.6409C10.0005 24.3112 10.0747 23.985 10.2227 23.6904C10.3593 23.4195 10.572 23.1943 10.8347 23.0424C11.1151 22.8838 11.433 22.8038 11.7551 22.8109C12.1639 22.7968 12.5646 22.9263 12.8879 23.1768Z" fill="white" />
      <path d="M16.3956 25.956C16.2921 26.1211 16.144 26.2534 15.9684 26.3376C15.7546 26.4377 15.5203 26.4862 15.2844 26.4792C14.929 26.4936 14.5789 26.3902 14.2884 26.1852C14.16 26.0887 14.055 25.9647 13.9811 25.8222C13.9071 25.6797 13.8662 25.5225 13.8612 25.362H14.7864C14.7928 25.4829 14.8446 25.5969 14.9316 25.6812C15.0175 25.7617 15.1319 25.8048 15.2496 25.8012C15.346 25.8061 15.4407 25.7734 15.5136 25.71C15.5452 25.6787 15.57 25.6413 15.5865 25.6C15.603 25.5587 15.6109 25.5145 15.6096 25.47C15.6107 25.428 15.6034 25.3862 15.5882 25.347C15.5729 25.3079 15.55 25.2722 15.5208 25.242C15.4577 25.178 15.3827 25.127 15.3 25.092C15.2112 25.0524 15.09 25.0056 14.934 24.9516C14.7435 24.8889 14.5575 24.8136 14.3772 24.726C14.229 24.6494 14.1017 24.5377 14.0064 24.4008C13.8967 24.2347 13.8425 24.038 13.8516 23.8392C13.8467 23.642 13.9033 23.4483 14.0136 23.2848C14.127 23.1251 14.2829 23.0004 14.4636 22.9248C14.6726 22.8402 14.8969 22.7994 15.1224 22.8048C15.4633 22.7868 15.7999 22.8872 16.0752 23.0892C16.1926 23.1844 16.2888 23.3033 16.3575 23.4381C16.4261 23.5729 16.4656 23.7206 16.4736 23.8716H15.534C15.5224 23.766 15.4769 23.6671 15.4044 23.5896C15.3668 23.5541 15.3225 23.5265 15.274 23.5085C15.2256 23.4906 15.174 23.4827 15.1224 23.4852C15.0327 23.4806 14.9445 23.5097 14.8752 23.5668C14.8427 23.5973 14.8175 23.6347 14.8013 23.6762C14.7851 23.7178 14.7784 23.7623 14.7816 23.8068C14.7805 23.8466 14.7874 23.8862 14.8019 23.9232C14.8163 23.9603 14.8379 23.9942 14.8656 24.0228C14.925 24.0854 14.997 24.1349 15.0768 24.168C15.1608 24.2052 15.2832 24.2532 15.4368 24.3108C15.6307 24.3732 15.8197 24.4502 16.002 24.5412C16.1515 24.6216 16.2801 24.7358 16.3776 24.8748C16.4888 25.043 16.5439 25.2421 16.5348 25.4436C16.5356 25.6239 16.4875 25.801 16.3956 25.956Z" fill="white" />
      <path d="M17.6927 22.8517L18.5723 25.5553L19.4519 22.8517H20.3819L19.1315 26.4445H18.0083L16.7627 22.8517H17.6927Z" fill="white" />
      <path d="M14.2667 18.0961H5.4647C4.7027 18.0961 4.0835 17.3376 4.0835 16.404V10.0213C4.0835 9.08885 4.7027 8.33044 5.4647 8.33044H14.2667C15.0287 8.33044 15.6479 9.08885 15.6479 10.0213V16.404C15.6479 17.3376 15.0287 18.0961 14.2667 18.0961ZM5.4647 9.19447C5.0927 9.19447 4.7891 9.56526 4.7891 10.0213V16.404C4.7891 16.86 5.0927 17.2309 5.4647 17.2309H14.2667C14.6387 17.2309 14.9411 16.86 14.9411 16.404V10.0213C14.9411 9.56526 14.6387 9.19447 14.2667 9.19447H5.4647Z" fill="#00C650" />
      <path d="M15.2953 14.2524H4.43652V15.1176H15.2953V14.2524Z" fill="#00C650" />
      <path d="M15.2953 11.286H4.43652V12.1512H15.2953V11.286Z" fill="#00C650" />
      <path d="M12.5146 8.76245H11.6494V17.6629H12.5146V8.76245Z" fill="#00C650" />
      <path d="M8.082 8.76245H7.2168V17.6629H8.082V8.76245Z" fill="#00C650" />
    </svg>

  );
};

export default CsvIcon;
