const XlsxIcon = () => {
  return (
    <svg
      width="40"
      height="55"
      viewBox="0 12 35 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0882 72H3.91177C2.30364 72 1 70.465 1 68.5714V27.4286C1 25.535 2.30364 24 3.91177 24H23.8L37 39.6V68.5714C37 70.465 35.6964 72 34.0882 72Z"
        fill="url(#paint0_linear_263_5199)"
      />
      <path
        d="M7.07539 62L8.96539 59.29L10.8754 62H12.7254L10.3354 58.67V58.27L12.7254 54.95H10.8754L8.96539 57.65L7.07539 54.95H5.22539L7.60539 58.27V58.67L5.22539 62H7.07539ZM18.3705 62V60.59L14.9405 60.69V54.95H13.3305V61.26C13.3305 61.81 13.6405 62.11 14.1905 62.1L18.3705 62ZM20.6105 62L22.5005 59.29L24.4105 62H26.2605L23.8705 58.67V58.27L26.2605 54.95H24.4105L22.5005 57.65L20.6105 54.95H18.7605L21.1405 58.27V58.67L18.7605 62H20.6105ZM32.6752 60.06C32.6752 58.59 30.6852 58.03 29.3852 57.59C28.2552 57.21 28.0952 57.09 28.0952 56.77C28.0952 56.42 28.5352 56.26 29.2552 56.26C30.1252 56.26 30.7852 56.49 30.7852 57.35H32.3852C32.3852 55.95 31.4152 54.85 29.2552 54.85C27.4352 54.85 26.4852 55.51 26.4852 56.71C26.4852 58.09 27.6952 58.49 29.1852 58.99C30.2152 59.34 31.0752 59.57 31.0752 60.08C31.0752 60.46 30.7352 60.69 29.7052 60.69C28.3052 60.69 27.7752 60.35 27.7752 59.28H26.1652C26.1652 61.06 27.1152 62.1 29.6552 62.1C31.8252 62.1 32.6752 61.27 32.6752 60.06Z"
        fill="white"
      />
      <g filter="url(#filter0_d_263_5199)">
        <path
          d="M37 39.6C29.0095 39.6 22.8035 32.6367 23.7194 24.6989L23.8 24L37 39.6Z"
          fill="url(#paint1_linear_263_5199)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_263_5199"
          x="0.629395"
          y="0"
          width="63.3706"
          height="65.6001"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="1" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_263_5199"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_263_5199"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_263_5199"
          x1="19"
          y1="24"
          x2="19"
          y2="72"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.000265127" stopColor="#83CC70" />
          <stop offset="1" stopColor="#61A850" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_263_5199"
          x1="29.5"
          y1="24"
          x2="29.5"
          y2="39.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#62A950" />
          <stop offset="1" stopColor="#55A640" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default XlsxIcon;
