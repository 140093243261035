import { useState, useContext, useEffect } from "react";
import multiDownload from "multi-download";

import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import TranslationContext from "@/context/TranslationContext";
import AuthValidationErrors from "../AuthValidationErrors/AuthValidationErrors";

import Text from "../Text/Text";
import Button from "../Button/Button";
import TxtIcon from "../Icons/TxtIcon";
import CsvIcon from "../Icons/CsvIcon";
import PdfIcon from "../Icons/PdfIcon";
import XmlIcon from "../Icons/XmlIcon";
import PptIcon from "../Icons/PptIcon";
import XlsIcon from "../Icons/XlsIcon";
import DocIcon from "../Icons/DocIcon";
import DocxIcon from "../Icons/DocxIcon";
import PptxIcon from "../Icons/PptxIcon";
import XlsxIcon from "../Icons/XlsxIcon";
import JsonIcon from "../Icons/JsonIcon";
import HtmlIcon from "../Icons/HtmlIcon";
import XliffIcon from "../Icons/XliffIcon";

const FileWidget = ({ file, frontendUploadModel }) => {
  const { documentTranslation, downloadTranslatedFile, getfilecount } = useAuth(
    {
      middleware: "guest",
    }
  );
  const usercontext = useContext(UserContext);
  const allowLLM = usercontext.user?.llm;
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState(""); // State variable for the message
  const frontendTabs = frontendUploadModel;
  const translationcontext = useContext(TranslationContext);
  const setLimitExceedType = translationcontext.setLimitExceedType;
  const setDocLimitExceed = translationcontext.setDocLimitExceed;
  const setDocLimitExceedMsg = translationcontext.setDocLimitExceedMsg;
  const docLimitExceedMsg = translationcontext.docLimitExceedMsg;
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const isArabic = locale === 'ar';

  const docs = translationcontext.docs;
  const setDocs = translationcontext.setDocs;
  const model = translationcontext.model;
  const allTranslate = translationcontext.allTranslate;
  const setAllTranslate = translationcontext.setAllTranslate;

  const setAllTranslatedDocuments =
    translationcontext.setAllTranslatedDocuments;
  const allTranslatedDocuments = translationcontext.allTranslatedDocuments;

  const setDisplayDocs = translationcontext.setDisplayDocs;
  const displayDocs = translationcontext.displayDocs;

  const removeFile = (fileIndex) => {
    let files = [];
    let uploadedDisplayFiles = [];
    files = Array.from(docs);
    uploadedDisplayFiles = Array.from(displayDocs);

    let updatedFileList = [];
    let updatedDisplayFileList = [];

    for (let i = 0; i < files.length; i++) {
      if (i !== fileIndex) {
        updatedFileList.push(files[i]);
        updatedDisplayFileList.push(uploadedDisplayFiles[i]);
      }
    }
    setDisplayDocs(updatedDisplayFileList);
    setDocs(updatedFileList);

    if (updatedDisplayFileList.length === 0) {
      setLimitExceedType(0);
      setDocLimitExceed(false);
    }
  };

  const docTranslate = async (fileIndex) => {
    let translatingFiles = Array.from(docs);
    let docfile = translatingFiles[fileIndex];
    file.uploadState = 1;
    // const docCountResp = await getfilecount({ setErrors, docfile });
    //if (docCountResp.status == 200) {
    file.uploadState = 1;
    const docResponse = await documentTranslation({
      setErrors,
      docfile,
      model,
      frontendTabs: allowLLM && (model === 'ar-en' || model === 'en-ar') ? 'llm' : frontendTabs,
    });
    // file.words = docCountResp.doc_words;
    // file.uploadState = 2;
    let projectId = docResponse.doc_convert_token;
    if (projectId) {
      let count = 0;
      let downloadpathinterval = setInterval(async function () {
        const response = await downloadTranslatedFile({
          setErrors,
          projectId,
        });
        file.words = response.data.words_count;
        switch (response.data.status) {
          case 'create':
            if (count >= 50) {
              setErrors([t.FileLargeNeedMoreTime]);
              file.uploadState = 5;
              clearInterval(downloadpathinterval);
            }
            count++;
            break;

          case 'pretranslate':
            file.uploadState = 3;
            break;
          case 'generate':

            let translatedDocument = [];
            translatedDocument[fileIndex] = response.data.url;
            setAllTranslatedDocuments(
              allTranslatedDocuments.concat(translatedDocument)
            );

            clearInterval(downloadpathinterval);
            file.uploadState = 4;
            break;
          case 'error':

            setErrors([t.thereIsIssueWithUploadedFile]);
            file.uploadState = 5;
            clearInterval(downloadpathinterval);
            break;

          case 'exceeded':

            setDocLimitExceed(true);
            setLimitExceedType(4);
            file.uploadState = 6;
            //setErrors(['You have exceeded your words limit']);
            setDocLimitExceedMsg('You have exceeded your words limit');
            clearInterval(downloadpathinterval);
            break;

          default:
            setErrors([t.thereIsIssueWithUploadedFile]);
            file.uploadState = 5;
            clearInterval(downloadpathinterval);
        }
      }, 3000);
    } else if (docResponse.status == 402) {
      file.uploadState = 6;
      setLimitExceedType(3);
      setDocLimitExceed(true);
      setDocLimitExceedMsg(docResponse.error);
    } else {
      setErrors(docResponse.errors);
    }
  };

  const downloadFile = (id) => {
    let translatedDocumentPath = allTranslatedDocuments.filter(item => item)[id];
    translatedDocumentPath = translatedDocumentPath.replace(/^https?:\/\//, '//')
    multiDownload([translatedDocumentPath]);
  };

  useEffect(() => {
    if (file.type === "application/pdf") {
      setMessage(t.pdfSelectedMessage);
    } else {
      setMessage("");
    }
  }, [file.type]);

  return (
    <>
      <AuthValidationErrors className="mb-4" errors={errors} t={t} />
      {message && <div className="mb-4 text-green-600">{message}</div>}

      <div
        className={`flex gap-0 flex-col border border-grey100 items-center pl-1 pr-3 rounded-sm justify-between mb-4 py-2 sm:py-2 md:py-0 bg-white ${locale == "ar" ? "flex-row-reverse" : ""
          }`}
      >
        <div className={`flex w-full ${isArabic ? 'flex-row-reverse' : ''}`}>
          <div className="flex items-center justify-center h-fit mt-2 ms-2">
            {file.type ==
              "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                <PptxIcon />
              )}
            {file.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                <XlsxIcon />
              )}
            {file.type ==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                <DocxIcon />
              )}
            {(file.type == "application/x-xliff+xml" || file.type == "xliff") && (
              <XliffIcon />
            )}
            {file.type == "text/plain" && <TxtIcon />}
            {file.type == "application/pdf" && <PdfIcon />}
            {file.type == "text/csv" && <CsvIcon />}
            {file.type == "text/xml" && <XmlIcon />}
            {file.type == "application/msword" && <DocIcon />}
            {file.type == "application/json" && <JsonIcon />}
            {file.type == "text/html" && <HtmlIcon />}
            {file.type == "application/vnd.ms-powerpoint" && <PptIcon />}
            {file.type == "application/vnd.ms-excel" && <XlsIcon />}
          </div>
          <div className="w-full md:w-[350px] lg:w-[350px] xl:w-[408px] m-auto truncate ...">
            <div
              className={`flex flex-col sm:flex-row justify-between text-base ${locale == "ar" ? "flex-row-reverse items-end" : "items-start"} `}
            >
              <div className="flex gap-1 text-left flex-col md:flex-row">
                <Text
                  as="p"
                  className="max-w-[100px] md:max-w-[150px] lg:max-w-[200px] font-semibold truncate ..."
                >
                  {file.name}
                </Text>
                <Text as="span" className="font-medium flex">
                  <Text as="span" className="relative">
                    {file.words > 0 && (
                      <span className="ml-2"> • {file.words} {t.Words}</span>
                    )}
                  </Text>
                </Text>
              </div>
              <div className="text-grey400 font-medium text-sm md:text-base">
                <Text as="span">{file.displaySize}</Text>
              </div>
            </div>
            <div className="hidden md:block relative">
              <div
                className="w-full h-[1px] border-[1.5px] mt-2 border-grey100"
              ></div>
              <div className="w-1/3 w-2/3 w-3/3 w-0/3"></div>
              <div
                className={`w-${file.uploadState == 2
                  ? "1/3"
                  : file.uploadState == 3
                    ? "2/3"
                    : file.uploadState == 4
                      ? "full"
                      : "0"
                  } h-[1px] absolute border-green border-[1.5px] top-0 ${isArabic ? 'right-0' : 'left-0'}`}
              ></div>
            </div>
          </div>
          <div className="w-fit sm:w-auto relative flex items-center">
            {file.uploadState == 0 && (
              <div className={`flex justify-end md:justify-between items-center font-semibold ${isArabic ? 'flex-row-reverse' : ''}`}>
                <Button
                  type="button"
                  disabled={allTranslate}
                  className="text-green hover:bg-transparent disabled:opacity-[0.4] hover:text-red px-3 text-base focus:outline-0 focus:ring-0"
                  onClick={() => {
                    docTranslate(file.id);
                  }}
                >
                  {t.Translate}
                </Button>
                <Button
                  type="button"
                  className="bg-red hover:bg-red hover:text-white text-sm text-white w-2 h-2 px-[10px] py-[10px] flex items-center justify-center font-medium"
                  onClick={() => removeFile(file.id)}
                >
                  x
                </Button>
              </div>
            )}
            {file.uploadState == 1 && (
              <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                {t.Analyzing}...
              </div>
            )}
            {file.uploadState == 2 && (
              <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                {t.Translating}...
              </div>
            )}
            {file.uploadState == 3 && (
              <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                {t.GeneratingFile}...
              </div>
            )}
            {file.uploadState == 4 && (
              <div className="flex justify-between items-center font-bold text-base">
                <Button
                  type="button"
                  className="text-green hover:bg-transparent hover:text-red px-3 text-base focus:ring-0"
                  onClick={() => downloadFile(file.id)}
                >
                  {t.Download}
                </Button>
              </div>
            )}
            {file.uploadState == 5 && (
              <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                {t.Error}
              </div>
            )}
            {file.uploadState == 6 && (
              <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                {t.limitExceeded}
              </div>
            )}
            {file.uploadState == 7 && (
              <div className="flex justify-end md:justify-between items-center font-semibold text-base">
                {t.waiting}
              </div>
            )}
          </div>
        </div>
        <div className="md:hidden relative w-full">
          <div
            className="w-full h-[1px] border-[1.5px] border-grey100"
          ></div>
          <div className="w-1/3 w-2/3 w-3/3 w-0/3"></div>
          <div
            className={`w-${file.uploadState == 2
              ? "1/3"
              : file.uploadState == 3
                ? "2/3"
                : file.uploadState == 4
                  ? "full"
                  : "0"
              } h-[1px] absolute border-green border-[1.5px] top-0 ${isArabic ? 'right-0' : 'left-0'}`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default FileWidget;
