const XmlIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7604 5.9088C16.1044 5.90816 15.4754 5.64727 15.0115 5.18336C14.5476 4.71946 14.2866 4.09046 14.286 3.4344V0H3.22922C2.37278 0 1.55141 0.340217 0.94582 0.945809C0.340228 1.5514 0 2.37276 0 3.2292V22.6956C0.0012718 23.5512 0.342065 24.3713 0.947519 24.9759C1.55297 25.5804 2.37361 25.92 3.22922 25.92H16.5012C17.3576 25.92 18.179 25.5798 18.7846 24.9742C19.3902 24.3686 19.7304 23.5472 19.7304 22.6908V5.9088H16.7604Z" fill="#A140FF" />
      </g>
      <path d="M19.7305 5.9088H16.7605C16.1045 5.90816 15.4755 5.64727 15.0116 5.18336C14.5477 4.71946 14.2868 4.09046 14.2861 3.4344V0L19.7305 5.9088Z" fill="#A140FF" />
      <path d="M22.0726 20.472H7.06783C6.00347 20.472 5.14062 21.3349 5.14062 22.3992V26.8728C5.14062 27.9372 6.00347 28.8 7.06783 28.8H22.0726C23.137 28.8 23.9998 27.9372 23.9998 26.8728V22.3992C23.9998 21.3349 23.137 20.472 22.0726 20.472Z" fill="#A140FF" />
      <path d="M9.70097 25.6045V26.4769H8.79736V25.6045H9.70097Z" fill="white" />
      <path d="M12.3442 26.4768L11.5834 25.3536L10.9306 26.4768H9.92982L11.0842 24.6192L9.89502 22.8684H10.9306L11.671 23.9556L12.3082 22.8684H13.3042L12.1654 24.6852L13.381 26.4768H12.3442Z" fill="white" />
      <path d="M17.7757 22.8937V26.4769H16.9021V24.2401L16.1269 26.4805H15.3709L14.5909 24.2353V26.4805H13.7173V22.8973H14.7841L15.7597 25.3933L16.7137 22.8973L17.7757 22.8937Z" fill="white" />
      <path d="M19.2505 25.8084H20.4145V26.4768H18.3745V22.8684H19.2481L19.2505 25.8084Z" fill="white" />
      <path d="M4.86145 13.7029C4.85978 13.6513 4.87542 13.6006 4.90586 13.5589C4.94226 13.5121 4.98602 13.4716 5.03544 13.4389C5.1103 13.4001 5.1877 13.3665 5.26706 13.3381L5.49866 13.2421L6.42505 12.8521C6.55385 12.7921 6.79385 12.6921 7.14505 12.5521L7.58544 12.3721L7.74385 12.3157L7.77145 12.3037C7.77897 12.3021 7.78672 12.3021 7.79424 12.3037C7.81201 12.2958 7.83124 12.2917 7.85067 12.2917C7.88784 12.2896 7.92502 12.295 7.96006 12.3076C7.9951 12.3202 8.02728 12.3397 8.05466 12.3649C8.07789 12.3868 8.09636 12.4132 8.10895 12.4425C8.12154 12.4718 8.12797 12.5034 8.12785 12.5353V12.8401C8.1267 12.8966 8.1092 12.9517 8.07745 12.9985C8.04407 13.0508 7.99427 13.0904 7.93586 13.1113L6.04346 13.8853L7.93586 14.6593C7.9922 14.6826 8.04114 14.7207 8.07745 14.7697C8.11044 14.8145 8.12811 14.8688 8.12785 14.9245V15.2293C8.12788 15.2615 8.12141 15.2935 8.10883 15.3231C8.09625 15.3528 8.07784 15.3797 8.05466 15.4021C8.02772 15.4281 7.99567 15.4482 7.96056 15.4612C7.92545 15.4742 7.88805 15.4799 7.85067 15.4777C7.83137 15.4775 7.81225 15.4738 7.79424 15.4669L7.74385 15.4501L7.58544 15.3937L7.14866 15.2161L6.42866 14.9173L5.50226 14.5273L5.27067 14.4313C5.19627 14.4013 5.11825 14.3677 5.03905 14.3293C4.9899 14.3064 4.94781 14.2707 4.9172 14.2259C4.88659 14.1812 4.86858 14.129 4.86505 14.0749L4.86145 13.7029Z" fill="#A140FF" />
      <path d="M10.5145 15.8041C10.4544 15.8056 10.3951 15.7911 10.3424 15.7623C10.2897 15.7334 10.2456 15.6911 10.2145 15.6397C10.1887 15.6027 10.1681 15.5623 10.1533 15.5197L10.1137 15.4129L9.24969 13.5037L9.18729 13.3621C9.11529 13.2085 8.97609 12.9037 8.76969 12.4477C8.50169 11.8677 8.36768 11.5497 8.36768 11.4937C8.36771 11.4599 8.37542 11.4266 8.39018 11.3963C8.40493 11.366 8.42638 11.3394 8.4529 11.3185C8.51277 11.2679 8.58932 11.2413 8.66768 11.2441H8.94849C9.00765 11.2431 9.06603 11.2577 9.11768 11.2866C9.16932 11.3155 9.21237 11.3576 9.24248 11.4085L9.30488 11.5285L9.34449 11.6353L9.57008 12.1321L10.2085 13.5445L10.2925 13.7245L10.6885 14.5945C10.8403 14.9066 10.9741 15.2271 11.0893 15.5545C11.0891 15.588 11.0813 15.6211 11.0666 15.6512C11.0518 15.6813 11.0304 15.7078 11.0041 15.7285C10.9443 15.7792 10.8677 15.8057 10.7893 15.8029L10.5145 15.8041Z" fill="#A140FF" />
      <path d="M11.5596 15.4801C11.5213 15.4826 11.4828 15.4771 11.4467 15.4641C11.4106 15.4511 11.3775 15.4308 11.3496 15.4045C11.3034 15.3587 11.2771 15.2967 11.2764 15.2317V14.9269C11.2758 14.8715 11.2926 14.8174 11.3244 14.7721C11.3603 14.7217 11.4104 14.6833 11.4684 14.6617L13.3668 13.8877L11.4684 13.1149C11.4108 13.0896 11.3619 13.0479 11.328 12.9949C11.2953 12.9494 11.2773 12.8949 11.2764 12.8389V12.5293C11.276 12.4976 11.2822 12.4662 11.2946 12.4371C11.307 12.408 11.3253 12.3817 11.3484 12.3601C11.4048 12.3093 11.479 12.283 11.5548 12.2869C11.5723 12.2866 11.5897 12.2907 11.6052 12.2989C11.6155 12.2971 11.6261 12.2971 11.6364 12.2989L11.6616 12.3109C11.6544 12.3109 11.7048 12.3253 11.814 12.3673L12.2604 12.5473C12.2372 12.5369 12.4772 12.6369 12.9804 12.8473C13.2548 12.9601 13.5692 13.0937 13.9236 13.2481L14.0136 13.2817L14.1432 13.3333C14.2196 13.3604 14.2934 13.3941 14.364 13.4341C14.4144 13.4571 14.4575 13.4936 14.4884 13.5395C14.5193 13.5855 14.5369 13.6391 14.5392 13.6945V14.0665C14.5381 14.1175 14.5214 14.1669 14.4912 14.2081C14.4577 14.2546 14.4142 14.2932 14.364 14.3209C14.2956 14.3545 14.2152 14.3905 14.124 14.4289L13.884 14.5249L11.7936 15.3997C11.7336 15.4297 11.6976 15.4489 11.6868 15.4561L11.664 15.4729L11.5596 15.4801Z" fill="#A140FF" />
    </svg>
  );
};

export default XmlIcon;
