import React, { useContext } from "react";

import AppContext from "@/context/AppContext";

const UpgradePlan = () => {
  const context = useContext(AppContext);
  const t = context.t;
  const locale = context.locale;
  const isArabic = locale === "ar";
  

  return (
    <div className={`p-5 lg:p-12 bg-white lg:rounded-md ${isArabic ? 'text-right' : 'text-left'}`}>
      <p>{t.UpgradePlan}</p> 
    </div>
 );
};

export default UpgradePlan;
