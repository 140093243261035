import React, { useState, useContext } from 'react';

import Image from "next/image";
import AppContext from "@/context/AppContext";
import { userFeedback } from "@/common/hooks/auth";
import SadRobotIcon from "@/assets/images/sad-robot.svg";
import SatisRobotIcon from "@/assets/images/satis-robot.svg";
import HappyRobotIcon from "@/assets/images/happy-robot.svg";
import Toast from "@/common/components/elements/ToastPopup/ToastPopup";


const Modal = ({ isOpen, onClose }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [errors, setErrors] = useState([]);
  const context = useContext(AppContext);
  const t = context.t;
  const isArabic = context.locale === "ar";

  const [open, setOpen] = useState(false);
  const [toastMsg, seToastMsg] = useState('');
  const [successMessage, setSuccessMessage] = useState("");

  const handleRatingChange = (event) => {
    setRating(parseInt(event.target.value, 10));
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };
  const handleToastClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setErrors([]);
      setSuccessMessage(""); // Clear previous success messages

      await userFeedback({ rating, feedback, setErrors, setSuccessMessage });
      setOpen(true);
      seToastMsg('Feedback submitted successfully');
      setRating(0);
      setFeedback('');
      onClose(); // Optionally, close the modal after submitting
    } catch (error) {
      setOpen(true);
      seToastMsg('Error submitting feedback');
      console.error('Error submitting feedback:', error);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Toast open={open} toastMsg={toastMsg} handleClose={handleToastClose} />
      <div className="modal-feedback">
        <div className="flex flex-col mx-auto w-full bg-white shadow-sm max-w-[480px]">
          <header className={`flex gap-5 justify-between py-0.5 pr-2 text-lg font-medium leading-9 text-right whitespace-nowrap
              ${isArabic ? 'flex-row-reverse' : ''}`}>

            <div className="flex-none">{t.Feedback}</div>
            <div className="w-6 aspect-square cursor-pointer" onClick={onClose}>
              &times;
            </div>

          </header>
          <div className={`mt-7 ${isArabic ? "font-urwarabic" : "font-inter"} font-medium leading-5 text-gray-900 max-w-[281px]`} style={{ fontSize: '16px', lineHeight: '20px' }}>
            {t.WhatDoYouThinkOfOurTranslation}
          </div>
          <div className={`flex gap-5 justify-center items-center my-3 ${isArabic ? 'flex-row-reverse' : ''}`}>

            {[1, 2, 3].map((value) => (
              <span
                key={value}
                role="img"
                aria-label={`Rating ${value}`}
                onClick={() => handleRatingChange({ target: { value } })}
                style={{ cursor: 'pointer' }}
              >
                <div className={`flex justify-center items-center border-solid aspect-square h-[60px] rounded-[46.154px] w-[60px] ${value == rating ? 'bg-lightRed border-[0.923px] border-[color:var(--Primary-Red-Base,#F16D78)]' : ''}`}>
                  <Image src={value === 1 ? SadRobotIcon : value === 2 ? SatisRobotIcon : HappyRobotIcon} className="w-full aspect-[0.93]" />
                </div>
              </span>
            ))}
          </div>
          <div className={`${isArabic ? "font-urwarabic" : "font-inter"} font-medium leading-5 text-gray-900 max-w-[281px] ${isArabic ? 'text-right' : 'text-left'}`} style={{ fontSize: '16px', lineHeight: '20px' }}>
            {t.DoYouHaveAnyThoughts}
          </div>
          <textarea
            className={`mt-20 px-2.5 pt-4 pb-20 mt-2.5 text-base whitespace-nowrap bg-white rounded-lg border border-solid border-[color:var(--Gray-4,#ABADB0)] text-zinc-60 ${isArabic ? 'text-right' : 'text-left'}`}
            id="feedback"
            value={feedback}
            onChange={handleFeedbackChange}
            rows={3}
            placeholder={t.PleaseWriteyourThoughtsHere}
          >
          </textarea>
          <div className={`flex ${isArabic ? 'justify-start' : 'justify-end'}`}>
            <button className="mt-20 w-1/3 justify-center items-center px-5 py-3 font-semibold text-center text-white whitespace-nowrap bg-teal-600 rounded-[32px]"
              style={{ fontSize: '16px', lineHeight: '20px' }}
              onClick={handleSubmit}>
              {t.Submit}
            </button>
          </div>
        </div>
        {errors.length > 0 && (
          <div className="error-message">
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

      </div>
    </>
  );
};

export default Modal;