const DocIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7596 5.91043C16.1035 5.9098 15.4746 5.6489 15.0107 5.18502C14.5468 4.72114 14.2859 4.09217 14.2853 3.43614V0.000732645H3.23024C2.8061 0.000575029 2.38607 0.0839657 1.99417 0.246169C1.60226 0.408373 1.24617 0.646206 0.946198 0.946066C0.646227 1.24593 0.40827 1.60194 0.24592 1.99379C0.0835709 2.38563 -2.92858e-08 2.80561 0 3.22976V22.6952C-2.92857e-08 23.1193 0.0835709 23.5393 0.24592 23.9312C0.40827 24.323 0.646227 24.679 0.946198 24.9789C1.24617 25.2788 1.60226 25.5166 1.99417 25.6788C2.38607 25.841 2.8061 25.9244 3.23024 25.9242H16.5016C16.9256 25.9242 17.3455 25.8407 17.7373 25.6784C18.129 25.5162 18.485 25.2783 18.7849 24.9785C19.0847 24.6786 19.3225 24.3226 19.4848 23.9309C19.6471 23.5391 19.7306 23.1192 19.7306 22.6952V5.91043H16.7596Z" fill="#0072FF" />
      </g>
      <path d="M19.731 5.91031H16.7599C16.1039 5.90968 15.4749 5.64878 15.011 5.1849C14.5472 4.72102 14.2863 4.09205 14.2856 3.43602V0.000610352L19.731 5.91031Z" fill="#0072FF" />
      <path d="M15.6714 10.0477H3.28325C3.16423 10.0477 3.05008 10.0004 2.96592 9.91623C2.88176 9.83207 2.83447 9.71793 2.83447 9.5989C2.83432 9.53987 2.84581 9.48138 2.8683 9.42679C2.89078 9.37221 2.92381 9.32258 2.9655 9.28078C3.00719 9.23898 3.05672 9.20583 3.11124 9.1832C3.16577 9.16057 3.22422 9.14893 3.28325 9.14893H15.6714C15.7908 9.14893 15.9052 9.19634 15.9896 9.28073C16.074 9.36511 16.1214 9.47956 16.1214 9.5989C16.1211 9.71804 16.0736 9.83219 15.9892 9.91632C15.9048 10.0004 15.7906 10.0477 15.6714 10.0477Z" fill="#0072FF" />
      <path d="M15.6714 12.732H3.28325C3.16423 12.732 3.05008 12.6847 2.96592 12.6006C2.88176 12.5164 2.83447 12.4023 2.83447 12.2832C2.83432 12.2242 2.84581 12.1657 2.8683 12.1111C2.89078 12.0565 2.92381 12.0069 2.9655 11.9651C3.00719 11.9233 3.05672 11.8902 3.11124 11.8675C3.16577 11.8449 3.22422 11.8333 3.28325 11.8333H15.6714C15.7908 11.8333 15.9052 11.8806 15.9896 11.965C16.074 12.0494 16.1214 12.1639 16.1214 12.2832C16.1211 12.4024 16.0736 12.5165 15.9892 12.6006C15.9048 12.6847 15.7906 12.732 15.6714 12.732Z" fill="#0072FF" />
      <path d="M15.6714 15.4162H3.28325C3.22422 15.4162 3.16577 15.4046 3.11124 15.3819C3.05672 15.3593 3.00719 15.3262 2.9655 15.2844C2.92381 15.2426 2.89078 15.1929 2.8683 15.1383C2.84581 15.0838 2.83432 15.0253 2.83447 14.9662C2.83447 14.8472 2.88176 14.7331 2.96592 14.6489C3.05008 14.5647 3.16423 14.5175 3.28325 14.5175H15.6714C15.7906 14.5175 15.9048 14.5647 15.9892 14.6488C16.0736 14.7329 16.1211 14.8471 16.1214 14.9662C16.1214 15.0856 16.074 15.2 15.9896 15.2844C15.9052 15.3688 15.7908 15.4162 15.6714 15.4162Z" fill="#0072FF" />
      <path d="M10.7853 18.1005H3.28325C3.22422 18.1005 3.16577 18.0889 3.11124 18.0663C3.05672 18.0436 3.00719 18.0104 2.9655 17.9686C2.92381 17.9268 2.89078 17.8773 2.8683 17.8227C2.84581 17.7681 2.83432 17.7096 2.83447 17.6506C2.83447 17.5315 2.88176 17.4174 2.96592 17.3332C3.05008 17.249 3.16423 17.2018 3.28325 17.2018H10.7853C10.9043 17.2018 11.0185 17.249 11.1026 17.3332C11.1868 17.4174 11.2341 17.5315 11.2341 17.6506C11.2342 17.7096 11.2227 17.7681 11.2002 17.8227C11.1778 17.8773 11.1447 17.9268 11.103 17.9686C11.0614 18.0104 11.0118 18.0436 10.9573 18.0663C10.9028 18.0889 10.8443 18.1005 10.7853 18.1005Z" fill="#0072FF" />
      <path d="M22.0732 20.4728H7.06919C6.00488 20.4728 5.14209 21.3356 5.14209 22.3999V26.8733C5.14209 27.9376 6.00488 28.8004 7.06919 28.8004H22.0732C23.1376 28.8004 24.0003 27.9376 24.0003 26.8733V22.3999C24.0003 21.3356 23.1376 20.4728 22.0732 20.4728Z" fill="#0072FF" />
      <path d="M9.14623 25.605V26.4773H8.24268V25.605H9.14623Z" fill="white" />
      <path d="M12.6187 25.6073C12.4684 25.8801 12.2415 26.1029 11.9659 26.2481C11.6558 26.4067 11.311 26.4854 10.9627 26.4773H9.59961V22.8691H10.9627C11.3112 22.8604 11.6565 22.9375 11.9683 23.0934C12.2428 23.2359 12.469 23.4564 12.6187 23.727C12.7738 24.015 12.8519 24.3383 12.8455 24.6654C12.8516 24.9936 12.7735 25.3179 12.6187 25.6073ZM11.6803 25.4261C11.7779 25.326 11.8534 25.2066 11.9022 25.0756C11.9509 24.9446 11.9718 24.8049 11.9635 24.6654C11.9719 24.526 11.951 24.3864 11.9023 24.2556C11.8535 24.1248 11.7779 24.0056 11.6803 23.9058C11.4617 23.7141 11.1762 23.6166 10.886 23.6346H10.4744V25.6961H10.8871C11.1767 25.7136 11.4615 25.6166 11.6803 25.4261Z" fill="white" />
      <path d="M15.9822 23.0502C16.2567 23.2062 16.4836 23.4339 16.6385 23.709C16.8009 23.9996 16.8836 24.3278 16.8785 24.6606C16.884 24.9941 16.8012 25.3232 16.6385 25.6145C16.4831 25.8907 16.2553 26.1192 15.9798 26.2757C15.6992 26.4359 15.3812 26.5187 15.0582 26.5157C14.7352 26.5185 14.4172 26.4357 14.1366 26.2757C13.8611 26.1196 13.6337 25.8909 13.4791 25.6145C13.3159 25.3234 13.233 24.9942 13.2391 24.6606C13.2329 24.3277 13.3157 23.9991 13.4791 23.709C13.6345 23.4339 13.8618 23.2061 14.1366 23.0502C14.4172 22.8902 14.7352 22.8074 15.0582 22.8103C15.382 22.8072 15.7008 22.89 15.9822 23.0502ZM14.3742 23.9022C14.1972 24.1139 14.1072 24.385 14.1223 24.6606C14.1071 24.935 14.1972 25.205 14.3742 25.4153C14.4605 25.509 14.5662 25.5827 14.6839 25.6312C14.8016 25.6797 14.9286 25.7019 15.0558 25.6961C15.1826 25.7015 15.3091 25.6792 15.4264 25.6307C15.5437 25.5822 15.649 25.5087 15.735 25.4153C15.9128 25.2053 16.0038 24.9354 15.9893 24.6606C16.0038 24.3862 15.9137 24.1165 15.7374 23.9058C15.6512 23.812 15.5455 23.7383 15.4277 23.6898C15.31 23.6413 15.183 23.6191 15.0558 23.625C14.9289 23.6192 14.8023 23.6409 14.6846 23.6888C14.5669 23.7367 14.461 23.8095 14.3742 23.9022Z" fill="white" />
      <path d="M20.1566 23.1955C20.461 23.4431 20.668 23.7904 20.741 24.1759H19.817C19.7547 24.0161 19.6455 23.8789 19.5039 23.7823C19.3545 23.685 19.1793 23.6353 19.0011 23.6395C18.8834 23.6357 18.7665 23.6591 18.6593 23.7078C18.5522 23.7565 18.4577 23.8292 18.3831 23.9203C18.216 24.134 18.1309 24.4004 18.1431 24.6714C18.1309 24.9413 18.2161 25.2066 18.3831 25.419C18.4583 25.5092 18.553 25.581 18.6601 25.629C18.7672 25.6771 18.8838 25.7 19.0011 25.6962C19.1793 25.7009 19.3547 25.6511 19.5039 25.5534C19.6448 25.4585 19.7539 25.3235 19.817 25.1658H20.741C20.6667 25.5502 20.4599 25.8963 20.1566 26.1438C19.8302 26.393 19.427 26.5203 19.0167 26.5038C18.6934 26.5106 18.3742 26.4301 18.0927 26.2709C17.8293 26.1178 17.6156 25.892 17.4772 25.6206C17.3284 25.3228 17.2543 24.9934 17.2612 24.6606C17.2544 24.3279 17.3285 23.9985 17.4772 23.7007C17.6156 23.4292 17.8293 23.2034 18.0927 23.0503C18.3742 22.8911 18.6934 22.8107 19.0167 22.8175C19.4291 22.8066 19.8324 22.9403 20.1566 23.1955Z" fill="white" />
    </svg>
  );
};

export default DocIcon;
