const PptIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7604 5.91C16.1043 5.90937 15.4753 5.64845 15.0114 5.18455C14.5475 4.72065 14.2866 4.09164 14.286 3.43559V0H3.22919C2.37275 0 1.55141 0.340228 0.94582 0.94582C0.340228 1.55141 0 2.37275 0 3.22919V22.6956C0 23.552 0.340228 24.3734 0.94582 24.979C1.55141 25.5846 2.37275 25.9248 3.22919 25.9248H16.5012C16.9254 25.925 17.3454 25.8415 17.7373 25.6793C18.1292 25.5171 18.4854 25.2793 18.7854 24.9794C19.0853 24.6796 19.3233 24.3235 19.4857 23.9317C19.648 23.5398 19.7316 23.1198 19.7316 22.6956V5.91H16.7604Z" fill="#FF3E4C" />
      </g>
      <path d="M19.7317 5.91H16.7605C16.1045 5.90937 15.4755 5.64845 15.0116 5.18455C14.5477 4.72065 14.2868 4.09164 14.2861 3.43559V0L19.7317 5.91Z" fill="#FF3E4C" />
      <path d="M22.0726 20.4733H7.0678C6.00344 20.4733 5.14062 21.3361 5.14062 22.4005V26.8741C5.14062 27.9384 6.00344 28.8013 7.0678 28.8013H22.0726C23.137 28.8013 23.9998 27.9384 23.9998 26.8741V22.4005C23.9998 21.3361 23.137 20.4733 22.0726 20.4733Z" fill="#FF3E4C" />
      <path d="M10.4158 25.6056V26.478H9.51221V25.6056H10.4158Z" fill="white" />
      <path d="M11.743 25.212V26.478H10.8706V22.8696H12.2842C12.713 22.8696 13.0394 22.976 13.2634 23.1888C13.3771 23.3003 13.4659 23.4347 13.5241 23.5829C13.5822 23.7312 13.6083 23.8901 13.6006 24.0492C13.6044 24.2589 13.5526 24.4659 13.4506 24.6492C13.3457 24.8285 13.1899 24.9726 13.003 25.0632C12.7784 25.1699 12.5315 25.2213 12.283 25.2132L11.743 25.212ZM12.7126 24.0492C12.7126 23.7292 12.5374 23.5692 12.187 23.5692H11.743V24.5088H12.187C12.5374 24.512 12.7126 24.3588 12.7126 24.0492Z" fill="white" />
      <path d="M14.9029 25.212V26.478H14.0293V22.8696H15.4429C15.8725 22.8696 16.1993 22.976 16.4233 23.1888C16.537 23.3003 16.6258 23.4347 16.6839 23.5829C16.7421 23.7312 16.7681 23.8901 16.7605 24.0492C16.7644 24.2591 16.7122 24.4662 16.6093 24.6492C16.5052 24.8287 16.3497 24.9729 16.1629 25.0632C15.9383 25.1699 15.6914 25.2213 15.4429 25.2132L14.9029 25.212ZM15.8725 24.0492C15.8725 23.7292 15.6973 23.5692 15.3469 23.5692H14.9029V24.5088H15.3469C15.6973 24.512 15.8725 24.3588 15.8725 24.0492Z" fill="white" />
      <path d="M19.782 22.8696V23.5644H18.8016V26.478H17.9244V23.5644H16.9536V22.8696H19.782Z" fill="white" />
      <path d="M8.96281 10.0476H3.72002C3.60088 10.0476 3.48659 10.0004 3.40224 9.91624C3.31788 9.8321 3.27034 9.71794 3.27002 9.5988C3.27002 9.47946 3.31743 9.365 3.40183 9.28061C3.48622 9.19622 3.60067 9.1488 3.72002 9.1488H8.95801C9.07735 9.1488 9.19184 9.19622 9.27623 9.28061C9.36062 9.365 9.40801 9.47946 9.40801 9.5988C9.4077 9.71712 9.36081 9.83054 9.27749 9.91454C9.19417 9.99853 9.08112 10.0463 8.96281 10.0476Z" fill="#FF3E4C" />
      <path d="M8.96281 12.7321H3.72002C3.60088 12.7321 3.48659 12.6848 3.40224 12.6007C3.31788 12.5165 3.27034 12.4024 3.27002 12.2833C3.27002 12.1639 3.31743 12.0494 3.40183 11.965C3.48622 11.8806 3.60067 11.8333 3.72002 11.8333H8.95801C9.07735 11.8333 9.19184 11.8806 9.27623 11.965C9.36062 12.0494 9.40801 12.1639 9.40801 12.2833C9.4077 12.4016 9.36081 12.515 9.27749 12.599C9.19417 12.683 9.08112 12.7308 8.96281 12.7321Z" fill="#FF3E4C" />
      <path d="M8.96281 15.4164H3.72002C3.60067 15.4164 3.48622 15.369 3.40183 15.2846C3.31743 15.2002 3.27002 15.0858 3.27002 14.9664C3.27097 14.8477 3.3188 14.7342 3.40309 14.6505C3.48737 14.5669 3.60129 14.52 3.72002 14.52H8.95801C9.07715 14.52 9.19143 14.5673 9.27579 14.6514C9.36015 14.7355 9.40769 14.8497 9.40801 14.9688C9.40738 15.0869 9.36037 15.2001 9.27708 15.2838C9.19379 15.3675 9.08091 15.4152 8.96281 15.4164Z" fill="#FF3E4C" />
      <path d="M8.96281 18.1008H3.72002C3.60067 18.1008 3.48622 18.0534 3.40183 17.969C3.31743 17.8846 3.27002 17.7702 3.27002 17.6508C3.27034 17.5317 3.31788 17.4175 3.40224 17.3334C3.48659 17.2493 3.60088 17.202 3.72002 17.202H8.95801C9.07715 17.202 9.19143 17.2493 9.27579 17.3334C9.36015 17.4175 9.40769 17.5317 9.40801 17.6508C9.40801 17.7693 9.36129 17.8831 9.27793 17.9673C9.19457 18.0516 9.08133 18.0996 8.96281 18.1008Z" fill="#FF3E4C" />
      <path d="M14.0702 14.1601V10.1713C13.0985 10.2226 12.1834 10.6447 11.5136 11.3506C10.8439 12.0566 10.4704 12.9925 10.4702 13.9657C10.4701 14.4646 10.5682 14.9587 10.7591 15.4197C10.95 15.8808 11.2298 16.2996 11.5826 16.6525C11.9354 17.0053 12.3543 17.2851 12.8153 17.476C13.2764 17.6669 13.7704 17.765 14.2694 17.7649C15.2425 17.765 16.1785 17.3916 16.8843 16.7217C17.5901 16.0518 18.0119 15.1366 18.0626 14.1649L14.0702 14.1601Z" fill="#FF3E4C" />
      <path d="M14.7974 9.86768V13.4677H18.3974C18.3493 12.5285 17.9546 11.6405 17.2896 10.9755C16.6246 10.3105 15.7366 9.91576 14.7974 9.86768Z" fill="#FF3E4C" />
    </svg>
  );
};

export default PptIcon;
