import Tabs from "../../common/components/elements/Tabs/Tabs";
import BlurBackground from "../../common/components/elements/Background/BlurBackground";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import {
  CONTACT_US_LINK,
  CONTACT_US_LINK_AR,
} from "../../common/utils/constant";
import UserContext from "../../context/UserContext";
import HelpCircle from "../../assets/icons/HelpCircle";

const TranslationWidget = () => {
  const context = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const user = usercontext.user;
  const t = context.t;
  const locale = context.locale;
  const userPlan = usercontext.user?.plan;
  const isArabic = context.locale === "ar";

  let link = isArabic ? CONTACT_US_LINK_AR : CONTACT_US_LINK;
  link += user ? "?firstname=" + user?.name + "&email=" + user?.email : "";

  return (
    <>
      <div className={`${userPlan !== 3 ? "md:px-6 lg:px-8 xl:px-main" : ""}`} style={{
        'direction': isArabic ? 'rtl' : 'ltr'
      }}>
        <Tabs />
      </div>
      <BlurBackground />
    </>
  );
};

export default TranslationWidget;
