const TxtIcon = () => {
  return (
    <svg width="44" height="49" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.3" d="M16.7604 5.91001C16.1042 5.90937 15.4751 5.64851 15.011 5.18464C14.5469 4.72078 14.2857 4.09176 14.2848 3.43559V0H3.22919C2.37275 0 1.55138 0.340228 0.945791 0.945821C0.340198 1.55141 0 2.37276 0 3.22919V22.6956C0 23.552 0.340198 24.3734 0.945791 24.979C1.55138 25.5846 2.37275 25.9248 3.22919 25.9248H16.5012C17.3576 25.9248 18.179 25.5846 18.7846 24.979C19.3902 24.3734 19.7304 23.552 19.7304 22.6956V5.91001H16.7604Z" fill="#0072FF" />
      </g>
      <path d="M19.7308 5.90988H16.7608C16.1046 5.90925 15.4755 5.64839 15.0114 5.18452C14.5473 4.72065 14.2861 4.09164 14.2852 3.43547V-0.00012207L19.7308 5.90988Z" fill="#0072FF" />
      <path d="M15.6723 10.0475H3.28229C3.16336 10.0472 3.0494 9.99977 2.9653 9.91567C2.8812 9.83158 2.83381 9.71761 2.8335 9.59868C2.8335 9.47954 2.88073 9.36526 2.96486 9.2809C3.049 9.19654 3.16316 9.149 3.28229 9.14868H15.6723C15.7914 9.149 15.9056 9.19654 15.9897 9.2809C16.0739 9.36526 16.1211 9.47954 16.1211 9.59868C16.1208 9.71761 16.0734 9.83158 15.9893 9.91567C15.9052 9.99977 15.7912 10.0472 15.6723 10.0475Z" fill="#0072FF" />
      <path d="M15.6723 12.7319H3.28229C3.16336 12.7316 3.0494 12.6842 2.9653 12.6001C2.8812 12.516 2.83381 12.4021 2.8335 12.2831C2.8335 12.164 2.88073 12.0497 2.96486 11.9653C3.049 11.881 3.16316 11.8334 3.28229 11.8331H15.6723C15.7914 11.8334 15.9056 11.881 15.9897 11.9653C16.0739 12.0497 16.1211 12.164 16.1211 12.2831C16.1208 12.4021 16.0734 12.516 15.9893 12.6001C15.9052 12.6842 15.7912 12.7316 15.6723 12.7319Z" fill="#0072FF" />
      <path d="M15.6723 15.4163H3.28229C3.16316 15.4159 3.049 15.3684 2.96486 15.284C2.88073 15.1997 2.8335 15.0854 2.8335 14.9663C2.83381 14.8473 2.8812 14.7334 2.9653 14.6493C3.0494 14.5652 3.16336 14.5178 3.28229 14.5175H15.6723C15.7912 14.5178 15.9052 14.5652 15.9893 14.6493C16.0734 14.7334 16.1208 14.8473 16.1211 14.9663C16.1211 15.0854 16.0739 15.1997 15.9897 15.284C15.9056 15.3684 15.7914 15.4159 15.6723 15.4163Z" fill="#0072FF" />
      <path d="M15.6723 18.0995H3.28229C3.16327 18.0995 3.04912 18.0522 2.96495 17.968C2.88078 17.8839 2.8335 17.7697 2.8335 17.6507C2.8335 17.5315 2.88073 17.4173 2.96486 17.3329C3.049 17.2485 3.16316 17.201 3.28229 17.2007H15.6723C15.7914 17.201 15.9056 17.2485 15.9897 17.3329C16.0739 17.4173 16.1211 17.5315 16.1211 17.6507C16.1211 17.7697 16.0738 17.8839 15.9896 17.968C15.9055 18.0522 15.7913 18.0995 15.6723 18.0995Z" fill="#0072FF" />
      <path d="M22.0731 20.4731H7.06832C6.00396 20.4731 5.14111 21.336 5.14111 22.4003V26.874C5.14111 27.9383 6.00396 28.8012 7.06832 28.8012H22.0731C23.1375 28.8012 24.0003 27.9383 24.0003 26.874V22.4003C24.0003 21.336 23.1375 20.4731 22.0731 20.4731Z" fill="#0072FF" />
      <path d="M10.2674 25.6055V26.4779H9.36377V25.6055H10.2674Z" fill="white" />
      <path d="M13.3142 22.8695V23.5643H12.3338V26.4779H11.4567V23.5643H10.4858V22.8695H13.3142Z" fill="white" />
      <path d="M15.8713 26.4779L15.1105 25.3547L14.4565 26.4779H13.4569L14.6101 24.6203L13.4209 22.8695H14.4565L15.1969 23.9567L15.8353 22.8695H16.8301L15.6925 24.6863L16.9069 26.4779H15.8713Z" fill="white" />
      <path d="M19.836 22.8695V23.5643H18.8568V26.4779H17.9784V23.5643H17.0088V22.8695H19.836Z" fill="white" />
    </svg>
  );
};

export default TxtIcon;
