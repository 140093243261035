import React from "react";

const LanguageSwitchButton = ({ color }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="14" fill={color} />
      <path
        d="M19.1431 8.85718L21.7145 11.4286L19.1431 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M14.8569 11.4285H20.8569"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8.85707 14L6.28564 16.5714L8.85707 19.1429"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M7.14307 16.5715H13.1431"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LanguageSwitchButton;
