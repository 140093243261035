import MenuItem from '@mui/material/MenuItem';
import { isMobile } from "react-device-detect";
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useContext, useReducer, useState, useEffect } from "react";

import Text from "../../Text/Text";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import BlackUploadIcon from "@/assets/icons/UploadIcon";
import { ADMIN_ROLE_ID } from "@/common/utils/constant";
import LangSwitchBtn from "../LangSwitchBtn/LangSwitchBtn";
import TranslationContext from "@/context/TranslationContext";
import AccountOverlay from "../../AccountOverlay/AccountOverlay";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";

const DocsUploadTab = ({
  t,
  setErrors,
  errors,
  activeLLM,
  frontendModel,
  supportedLanguages,
  selectedLanguages,
  onHandleLanguageChange,
  sourceFastLang,
  targetFastLang,
  onSetSourceFastLang,
  onSetTargetFastLang,
  onSetSelectedLanguages
}) => {
  const frontend = frontendModel.prop;

  const translationcontext = useContext(TranslationContext);

  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);

  const setModalType = usercontext.setModalType;
  const setShowModal = usercontext.setShowModal;

  const showModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const [isHovering, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const isArabic = appcontext.locale === "ar";

  const maxDocSize = usercontext.maxDocSize;
  const user = usercontext.user;
  const allowedFormats = usercontext.allowedFormats;

  const model = translationcontext.model;
  const setDocs = translationcontext.setDocs;
  const setDisplayDocs = translationcontext.setDisplayDocs;

  const locale = appcontext.locale;

  const lLang = translationcontext.lLang;
  const setLLang = translationcontext.setLLang;
  const rLang = translationcontext.rLang;
  const setRLang = translationcontext.setRLang;

  const limitExceedType = translationcontext.limitExceedType;
  const setLimitExceedType = translationcontext.setLimitExceedType;
  const setDocLimitExceed = translationcontext.setDocLimitExceed;
  const setDocLimitExceedMsg = translationcontext.setDocLimitExceedMsg;
  const docLimitExceed = translationcontext.docLimitExceed;
  const docLimitExceedMsg = translationcontext.docLimitExceedMsg;
  const setAllTranslate = translationcontext.setAllTranslate;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,//ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [sourceAnchorEl, setSourceAnchorEl] = useState(null);
  const [targetAnchorEl, setTargetAnchorEl] = useState(null);
  const [isSourceDropdownOpen, setSourceDropdownOpen] = useState(false);
  const [isTargetDropdownOpen, setTargetDropdownOpen] = useState(false);
  /* start enterprise plan*/

  const userPlan = usercontext.user?.plan;
  // Only show dropdowns for users with plan value of 3
  const shouldShowDropdowns = userPlan === 3 && frontend === 'business';

  const handleLanguageChange = (value, type, isFastLanguage) => {
    if ((type === 'source' && value === selectedLanguages.target) || (type === 'target' && value === selectedLanguages.source)) {
      return;
    }
    onHandleLanguageChange(value, type, isFastLanguage);
    setSourceAnchorEl(null);
    setTargetAnchorEl(null);
  };

  const handleClick = (e, type) => {
    if (type === 'source') {
      setSourceDropdownOpen(true);
      setSourceAnchorEl(e.currentTarget);
    } else {
      setTargetDropdownOpen(true);
      setTargetAnchorEl(e.currentTarget);
    }
  };

  const handleClose = (type) => {
    if (type === 'source') {
      setSourceAnchorEl(null);
      setSourceDropdownOpen(false);
    } else {
      setTargetAnchorEl(null);
      setTargetDropdownOpen(false);
    }
  };

  // Check if the user is not authenticated
  const isAuthenticated = !!usercontext.user;

  const uploadedDocs = (uploads) => {
    let uploadedfiles = Array.from(uploads);
    setAllTranslate(false);
    setLimitExceedType(0);
    setDocLimitExceed(false);
    validateFiles(uploadedfiles);
  };

  const validateFiles = async (uploadedfiles) => {
    let files = [];
    setErrors([]);
    let maxUploadSize = maxDocSize;
    let newuploadedfiles = [];
    let sizeMB = "";
    for (let i = 0; i < uploadedfiles.length; i++) {
      let doc = uploadedfiles[i];
      const fileName = doc.name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var tmpAllowedFileFormat = allowedFormats?.toLowerCase()?.split(",");
      let isValidated = false;
      if (user?.role_id !== ADMIN_ROLE_ID) {
        if (!tmpAllowedFileFormat.includes(ext.toLowerCase()) && allowedFormats !== "_all") {
          setErrors([t.fileFormatError]);
          setLimitExceedType(5);
          isValidated = false;
        } else {
          isValidated = true;
        }
      } else {
        isValidated = true;
      }
      if (isValidated) {
        setLimitExceedType(0);
        let size = doc.size;
        let displaySize = "";
        let length = doc.size.toString().length;
        if (length < 4) {
          displaySize = size.toString() + "B";
          sizeMB = size / (1000 * 1000);
        } else if (length >= 4 && length < 7) {
          size = Math.round((size / 1000) * 10) / 10;
          displaySize = size.toString() + "KB";
          sizeMB = size / 1000;
        } else if (size > 6) {
          size = Math.round((size / (1000 * 1000)) * 10) / 10;
          displaySize = size.toString() + "MB";
          sizeMB = size;
        }
        if (sizeMB > parseFloat(maxUploadSize)) {
          setErrors([t.maxFileSize + " " + t.themaxFileSize + " " + maxDocSize + " " + t.MB]);
        } else {
          let file = {
            name: doc.name,
            words: 0,
            type: doc.type,
            size: doc.size,
            displaySize: displaySize,
            id: i,
            uploadState: 0,
          };
          files.push(file);
          newuploadedfiles.push(doc);
        }
      }
    }
    if (files.length > 0) {
      setDisplayDocs(files);
      setDocs(newuploadedfiles);
    }
  };
  // reducer function to handle state changes
  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };

  // destructuring state and dispatch, initializing fileList to empty array
  const [data, dispatch] = useReducer(reducer, {
    inDropZone: false,
    fileList: [],
  });

  // onDragEnter sets inDropZone to true
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  // onDragLeave sets inDropZone to false
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };

  // onDragOver sets inDropZone to true
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // set dropEffect to copy i.e copy of the source item
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  // onDrop sets inDropZone to false and adds files to fileList
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // get files from event on the dataTransfer object as an array
    let files = [...e.dataTransfer.files];
    // ensure a file or files are dropped
    if (files && files.length > 0) {
      // loop over existing files
      const existingFiles = data.fileList.map((f) => f.name);
      // check if file already exists, if so, don't add to fileList
      // this is to prevent duplicates
      files = files.filter((f) => !existingFiles.includes(f.name));

      // dispatch action to add droped file or files to fileList
      dispatch({ type: "ADD_FILE_TO_LIST", files });
      // reset inDropZone to false
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
      validateFiles(files);
    }
  };
  const onLangSwitchClickHandler = () => {
    setLLang(rLang);
    setRLang(lLang);
    translationcontext.setModel(rLang.model + "-" + lLang.model);

    let sourceLangAlreadyExist = sourceFastLang.find(lang => lang.value == rLang.model);
    let targetLangAlreadyExist = targetFastLang.find(lang => lang.value == lLang.model);
    if (!sourceLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == rLang.model);
      const tempArray = [newLang, sourceFastLang[0]];
      onSetSourceFastLang(tempArray);
    }
    if (!targetLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == lLang.model);
      const tempArray = [newLang, targetFastLang[0]];
      onSetTargetFastLang(tempArray);
    }
    onSetSelectedLanguages(rLang, lLang);
  };
  return (
    <div className="h-auto sm:h-[545px] flex flex-col justify-between items-center pb-4 px-2 sm:px-3 lg:mx-[20px] bg-white shadow-xl rounded-md">
      {shouldShowDropdowns ? (
        <div className="switchBtn flex items-center justify-center relative">
          {/* Source Language Buttons */}
          <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
            {sourceFastLang.map((lang, key) => {
              return (
                <div className={`hidden md:flex ml-20 rounded-full  py-1 px-2 font-semibold
                    ${selectedLanguages.source === lang.value ? 'bg-red10' : ''}
                    ${selectedLanguages.target === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                  key={key} onClick={() => { handleLanguageChange(lang.value, 'source', true) }}>
                  {isArabic ? lang.labelAr : lang.labelEn}
                </div>
              )
            })}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e, 'source') }}
              aria-label="Open to show more"
              title="Open to show more"
            >
              {isSourceDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.source)?.labelEn}</span>
            <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 10 }}>
              <Select
                className="invisible"
                anchorel={sourceAnchorEl}
                open={Boolean(sourceAnchorEl)}
                MenuProps={MenuProps}
                onClose={() => { handleClose('source') }}
                onChange={(e) => handleLanguageChange(e.target.value, 'source')}
              >
                {supportedLanguages.map((lang) => (
                  <MenuItem
                    key={lang.value}
                    value={lang.value}
                    disabled={lang.value === selectedLanguages.target}
                  >
                    {isArabic ? lang.labelAr : lang.labelEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <LangSwitchBtn
            model={model}
            type={"doc"}
            lLang={lLang}
            rLang={rLang}
            frontendModel={frontend} // Pass the frontendModel prop
            onClickHandler={onLangSwitchClickHandler}
            onlyShowButton="true"
          />
          {/* Target Language Buttons */}
          <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
            {targetFastLang.map((lang, key) => {
              return (
                <div className={`hidden md:flex mr-20 rounded-full  py-1 px-2 font-semibold
                     ${selectedLanguages.target === lang.value ? 'bg-red10' : ''}
                     ${selectedLanguages.source === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                  key={key} onClick={() => { handleLanguageChange(lang.value, 'target') }}>
                  {isArabic ? lang.labelAr : lang.labelEn}
                </div>
              )
            })}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e, 'target') }}
              aria-label="Open to show more"
              title="Open to show more"
            >
              {isTargetDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.target)?.labelEn}</span>
            <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 10 }}>
              <Select
                className="invisible"
                anchorel={targetAnchorEl}
                open={Boolean(targetAnchorEl)}
                MenuProps={MenuProps}
                onClose={() => { handleClose('target') }}
                onChange={(e) => handleLanguageChange(e.target.value, 'target')}
              >
                {supportedLanguages.map((lang) => (
                  <MenuItem
                    key={lang.value}
                    value={lang.value}
                    disabled={lang.value === selectedLanguages.source}
                  >
                    {isArabic ? lang.labelAr : lang.labelEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      ) : (
        <LangSwitchBtn
          model={model}
          type={"doc"}
          lLang={lLang}
          rLang={rLang}
          frontendModel={frontend} // Pass the frontendModel prop
          onClickHandler={onLangSwitchClickHandler}
        />
      )}


      <div
        className="bg-white flex flex-col justify-center text-center items-center px-3 w-[98%] py-3 lg:py-3 h-[500px] sm:h-[540px] md:h-[500px] md:h-[470px] lg:h-[470px] xl:h-[470px] outline-dashed bg-grey200opaque outline-grey200 rounded-mm"
        onDragEnter={(e) => handleDragEnter(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDrop={(e) => handleDrop(e)}
      >
        <AuthValidationErrors className="mb-4" errors={errors} t={t} />
        {/* <Text as="h3" className="text-lg font-bold mb-4">
          <span>
            <UploadIcon />
          </span>
        </Text> */}
        <Text as="h5" className="text-md md:text-lg font-semibold mb-4">
          {t.DocUploadTitle}
        </Text>
        <Text as="p" className="text-md text-grey400 font-normal uppercase">
          {allowedFormats !== "_all" ?
            <>{allowedFormats} {t.file}</> :
            <span>{t.allSupported}</span>
          }
        </Text>
        {/* Add a message for non-authenticated users */}
        {!isAuthenticated && (
          <Text as="p" className="text-sm md:text-md">
            {t.otherFormats}{" "}
            <a href="#" onClick={() => showModal("signup")}>
              <strong>{t.tryFree}</strong>
            </a>
          </Text>
        )}

        <div>
          <label>
            <span
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              className=" cursor-pointer mt-4 text-green flex flex-row gap-3 text-base leading-normal font-semibold stroke-inherit border p-3 rounded-3xl border-green"
            >
              <BlackUploadIcon color={"#00A499"} />
              {t.browseBtnText}
            </span>
            <input
              type="file"
              multiple
              className="hidden"
              accept={
                allowedFormats !== "_all"
                  ? "." + allowedFormats?.split(",").join(",.")
                  : ""
              }
              onChange={(e) => {
                uploadedDocs(e.target.files);
              }}
            />
          </label>
        </div>

        {/* <Text
          as="h5"
          className={`text-[14px] font-semibold mt-4 text-grey flex items-center justify-between ${
            locale == "ar" ? "flex-row-reverse" : ""
          }`}
        >
          <span className="mr-1">{t.maxFileSize}</span>
          <span>{maxDocSize}</span>
          <span>{t.MB}</span>
        </Text> */}
      </div>
      {
        docLimitExceed && limitExceedType > 2 && (
          <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={docLimitExceedMsg} />
        )
      }
    </div >
  );
};

export default DocsUploadTab;
