import React, { useState, useEffect, useRef, useContext } from "react";

import Swal from "sweetalert2";
import IconButton from '@mui/material/IconButton';
import { isAndroid, isMobile } from "react-device-detect";

import Image from "next/image";
import Text from "../../Text/Text";
import axios from "@/common/libs/axios";
import styles from "./twidget.module.scss";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import UserContext from "@/context/UserContext";
import Loader from "../../Loader/TarjamaLoader";
import copyIcon from "@/assets/images/copy-icon.png";
import feedbackIcon from "@/assets/images/feedback.png"
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import LangSwitchBtn from "../LangSwitchBtn/LangSwitchBtn";
import messageIcon from "@/assets/images/message-plus-icon.png";
import AccountOverlay from "../../AccountOverlay/AccountOverlay";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import LikeIcon from "../../Icons/LikeIcon";
import ShareIcon from "../../Icons/ShareIcon";
import DisLikeIcon from "../../Icons/DisLikeIcon";
import TranslationContext from "@/context/TranslationContext";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
import { AMT_LINK } from "@/common/utils/constant";
import Notifier from "../../Notifier/Notifier";
import CheckIcon from "../../Icons/CheckIcon";
import RoundedCloseIcon from "../../Icons/RoundedCloseIcon";
import FeedbackModel from "../FeedbackModel/FeedbackModel";

import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,//ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Twidget = (
  {
    t,
    frontend,
    activeLLM,
    supportedLanguages,
    selectedLanguages,
    onHandleLanguageChange,
    sourceFastLang,
    targetFastLang,
    onSetSourceFastLang,
    onSetTargetFastLang,
    onSetSelectedLanguages }
) => {
  const frontendModel = frontend.prop;
  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const translationcontext = useContext(TranslationContext);
  const limitExceed = translationcontext.limitExceed;
  const limitExceedType = translationcontext.limitExceedType;
  const setLimitExceed = translationcontext.setLimitExceed;
  const setLimitExceedType = translationcontext.setLimitExceedType;
  const setLimitExceedMsg = translationcontext.setLimitExceedMsg;
  const limitExceedMsg = translationcontext.limitExceedMsg;

  const lang = appcontext.locale;
  const user = usercontext.user;
  //const charLimit = usercontext.charLimit;

  const setShowModal = usercontext.setShowModal;
  const setModalType = usercontext.setModalType;
  const model = translationcontext.model;
  const setTranslations = translationcontext.setTranslations;
  const translations = translationcontext.translations;
  const charLimit =
    (translations && translations.char_limit) ||
    usercontext.charLimit ||
    500;
  const lLang = translationcontext.lLang;
  const setLLang = translationcontext.setLLang;
  const rLang = translationcontext.rLang;
  const setRLang = translationcontext.setRLang;
  const [ttext, setTtext] = useState("");
  const isArabic = appcontext.locale === "ar";

  const textAreaRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const [loader, setLoader] = useState(false);
  const [nBgColor, setNBgColor] = useState("");
  const [reaction, setReaction] = useState("");
  const [nPosition, setNPosition] = useState("");
  const [dictionary, setDictionary] = useState([]);
  const [suggestion, setSuggestion] = useState("");
  const [nTextColor, setNTextColor] = useState("");
  const [toggleShare, setToggleShare] = useState(false);
  const [showNotifier, setShowNotifier] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  /* start enterprise plan*/

  const userPlan = usercontext.user?.plan;
  // Only show dropdowns for users with plan value of 3
  const shouldShowDropdowns = userPlan === 3 && frontendModel === 'business'

  const [isModalOpen, setModalOpen] = useState(false);
  const [isSourceDropdownOpen, setSourceDropdownOpen] = useState(false);
  const [isTargetDropdownOpen, setTargetDropdownOpen] = useState(false);
  const [detectLanguage, setDetectLanguage] = useState(false);
  const [sourceAnchorEl, setSourceAnchorEl] = useState(null);
  const [targetAnchorEl, setTargetAnchorEl] = useState(null);
  const [detectedLanguage, setDetectedLanguage] = useState(null);
  const isLangSwitchDisabled = ['legal', 'ecommerce', 'callak'].includes(frontendModel);

  const handleClick = (e, type) => {
    if (type === 'source') {
      setSourceDropdownOpen(true);
      setSourceAnchorEl(e.currentTarget);
    } else {
      setTargetDropdownOpen(true);
      setTargetAnchorEl(e.currentTarget);
    }
  };

  const handleClose = (type) => {
    if (type === 'source') {
      setSourceAnchorEl(null);
      setSourceDropdownOpen(false);
    } else {
      setTargetAnchorEl(null);
      setTargetDropdownOpen(false);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    updateModel();
  }, [selectedLanguages]);
  const updateModel = () => {
    const newModel = `${selectedLanguages.source}-${selectedLanguages.target}`;
    console.log("New Model:", newModel);
    translationcontext.setModel(newModel);

    const laguagePair = newModel.split('-');
    const sourceLangText = supportedLanguages.find(lang => lang.value === laguagePair[0])?.labelEn;// laguagePair[0] === "ar" ? t.Arabic : t.English;
    const targetLangText = supportedLanguages.find(lang => lang.value === laguagePair[1])?.labelEn;//laguagePair[1] === "ar" ? t.Arabic : t.English;
    setLLang({ lang: sourceLangText, model: laguagePair[0] });
    setRLang({ lang: targetLangText, model: laguagePair[1] });
  };
  const handleLanguageChange = (value, type, isFastLanguage) => {
    if ((type === 'source' && value === selectedLanguages.target) || (type === 'target' && value === selectedLanguages.source)) {
      return;
    }
    onHandleLanguageChange(value, type, isFastLanguage);
    setSourceAnchorEl(null);
    setTargetAnchorEl(null);
  };

  // Use useEffect to update the model when source or target language changes
  useEffect(() => {
    updateModel();
  }, [selectedLanguages]);
  /* end enterprise plan */

  let nIcon = null;
  const [defaultLikeIconColor, setDefaultLikeIconColor] = useState("#ABADB0");
  const [defaultDislikeIconColor, setDefaultDislikeLikeIconColor] = useState("#ABADB0");
  const { fetchtranslation, fetchdictionary, userReaction } = useAuth({
    middleware: "guest",
  });
  /**
   * The fetch dictionary function
   */
  const handleMouseUp = () => {
    const select = window.getSelection().toString();
    if (select != "") {
      //setLoader(true);
      fetchdictionary({ select, model, setErrors, setDictionary });
    }
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (ttext !== "") {
        if (translations) {
          translations.translatedText = "";
        }
        if (detectLanguage) {
          onDetectLanguage();
        } else {
          submitForm();
        }
      } else {
        translations.translatedText = "";
        removeText();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [ttext, model, lang, user]);

  useEffect(() => {
    if (dictionary.length > 0) {
      if (Array.isArray(dictionary) && dictionary[0]["hwi"] == null) {
        setSuggestion(dictionary.join(","));
      } else {
        setSuggestion("");
      }
    }
  }, [dictionary]);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setNotificationText(t.clipboardCopied);
    setNTextColor("text-white");
    setNBgColor("!bg-green");
    setShowNotifier(true);
    setNPosition("bottom-16 right-0");
    nIcon = CheckIcon;
  };

  const reactUser = async (reaction) => {
    let translationId = translations.translationId;
    const resp = await userReaction({ reaction, translationId });
    if (resp.status == 200) {
      if (reaction == "like") {
        setDefaultLikeIconColor("#00A499");
        setDefaultDislikeLikeIconColor("#ABADB0");
        setNotificationText(t.thanksForFeedback);
        setNTextColor("text-black");
        setNBgColor("bg-white");
        setShowNotifier(true);
        setNPosition("bottom-unset right-unset");
      } else if (reaction == "dislike") {
        setDefaultDislikeLikeIconColor("#F16D78");
        setDefaultLikeIconColor("#ABADB0");
        setNotificationText(t.thanksForFeedback);
        setNTextColor("text-black");
        setNBgColor("bg-white");
        setNPosition("bottom-unset right-unset");
        setShowNotifier(true);
      }
    }
  };
  const showSuggestionModal = (e) => {
    e.preventDefault();
    if (user) {
      setShowModal(true);
      setModalType("suggested");
    } else {
      Swal.fire({
        icon: "warning",
        title: "Alert!",
        text: "You should have to login first!",
      });
    }
  };
  const submitForm = async () => {
    setLoader(true);
    if (reaction !== "") {
      setReaction("");
    }
    fetchtranslation({
      ttext,
      model,
      setErrors,
      setTranslations,
      setDictionary,
      setLoader,
      setLimitExceed,
      setLimitExceedType,
      setLimitExceedMsg,
      frontendModel: activeLLM ? 'llm' : frontendModel, // Pass frontendModel here
    });
  };
  const removeText = () => {
    setTtext("");
    setDictionary("");
    setSuggestion("");
    translations.translatedText = "";
  };
  useEffect(() => {
    if (showNotifier) {
      const timeId = setTimeout(() => {
        setShowNotifier(false);
      }, 2000);
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [showNotifier]);
  const onLangSwitchClickHandler = () => {
    setDictionary("");
    setSuggestion("");
    setTtext("");
    translations.translatedText = "";
    setLLang(rLang);
    setRLang(lLang);
    translationcontext.setModel(rLang.model + "-" + lLang.model);

    let sourceLangAlreadyExist = sourceFastLang.find(lang => lang.value == rLang.model);
    let targetLangAlreadyExist = targetFastLang.find(lang => lang.value == lLang.model);
    if (!sourceLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == rLang.model);
      const tempArray = [newLang, sourceFastLang[0]];
      onSetSourceFastLang(tempArray);
    }
    if (!targetLangAlreadyExist) {
      const newLang = supportedLanguages.find(lang => lang.value == lLang.model);
      const tempArray = [newLang, targetFastLang[0]];
      onSetTargetFastLang(tempArray);
    }
    onSetSelectedLanguages(rLang, lLang);
  };

  const changeTextArea = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= charLimit) {
      setTtext(inputText);
    }
  };

  const onDetectLanguage = () => {
    setDetectLanguage(true);
    if (ttext?.length) {
      axios
        .get(`/api/language/detect?text=${ttext}`)
        .then((res) => {
          setDetectedLanguage(res?.data?.language);
          setSelectedLanguages(prevLanguages => ({
            ...prevLanguages,
            ['source']: res?.data?.language,
          }));
          submitForm();
        })
        .catch((error) => {
          if (error?.response?.status !== 409) throw error;
        })
    }
  };

  useEffect(() => {
    if (reaction !== "") {
      reactUser(reaction);
    } else {
      setDefaultDislikeLikeIconColor("#ABADB0");
      setDefaultLikeIconColor("#ABADB0");
    }
  }, [reaction]);
  return (
    <>

      <form onSubmit={submitForm} id="translateForm">
        {/* The Alert Box for the error message */}
        <div className="shadow-xl lg:rounded-md bg-white">
          {shouldShowDropdowns ? (
            <div className="switchBtn flex items-center justify-center relative border-b border-grey200">
              {/* Source Language Buttons */}
              <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
                <p className={`hidden md:flex w-fit ml-20 font-semibold cursor-pointer rounded-full  py-1 px-2  ${detectLanguage ? 'bg-red10' : ''}`} onClick={() => onDetectLanguage()}>
                  {!detectedLanguage ?
                    <span className="text-nowrap">{t.DetectLanguage}</span>
                    :
                    <>
                      <span className="text-nowrap">{t.Detected}</span>
                      <span className="capitalize text-nowrap">-{detectedLanguage}</span>
                    </>
                  }
                </p>
                {sourceFastLang.map((lang, key) => {
                  return (
                    <div className={`hidden md:flex ml-20 rounded-full  py-1 px-2 font-semibold
                    ${selectedLanguages.source === lang.value && !detectLanguage ? 'bg-red10' : ''}
                    ${selectedLanguages.target === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                      key={key} onClick={() => { handleLanguageChange(lang.value, 'source', true) }}>
                      {isArabic ? lang.labelAr : lang.labelEn}
                    </div>
                  )
                })}
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => { handleClick(e, 'source') }}
                  aria-label="Open to show more"
                  title="Open to show more"
                >
                  {isSourceDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.source)?.labelEn}</span>
                <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 100 }}>
                  <Select
                    className="invisible"
                    anchorel={sourceAnchorEl}
                    open={Boolean(sourceAnchorEl)}
                    MenuProps={MenuProps}
                    onClose={() => { handleClose('source') }}
                    onChange={(e) => handleLanguageChange(e.target.value, 'source')}
                  >
                    {supportedLanguages.map((lang) => (
                      <MenuItem
                        key={lang.value}
                        value={lang.value}
                        disabled={lang.value === selectedLanguages.target}
                      >
                        {isArabic ? lang.labelAr : lang.labelEn}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <LangSwitchBtn
                model={model}
                setDictionary={setDictionary}
                setSuggestion={setSuggestion}
                setTtext={setTtext}
                lLang={lLang}
                rLang={rLang}
                onClickHandler={onLangSwitchClickHandler}
                translatedText={translations.translatedText}
                frontendModel={frontendModel} // Pass the frontendModel prop
                disabled={isLangSwitchDisabled} // Add the disabled attribute
                onlyShowButton="true"
              />
              {/* Target Language Buttons */}
              <div className={`flex items-center w-1/2 ${isArabic ? 'justify-start' : ''}`}>
                {targetFastLang.map((lang, key) => {
                  return (
                    <div className={`hidden md:flex mr-20 rounded-full  py-1 px-2 font-semibold
                     ${selectedLanguages.target === lang.value ? 'bg-red10' : ''}
                     ${selectedLanguages.source === lang.value ? 'cursor-auto text-grey200' : 'cursor-pointer text-black'}`}
                      key={key} onClick={() => { handleLanguageChange(lang.value, 'target') }}>
                      {isArabic ? lang.labelAr : lang.labelEn}
                    </div>
                  )
                })}
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => { handleClick(e, 'target') }}
                  aria-label="Open to show more"
                  title="Open to show more"
                >
                  {isTargetDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <span className="block md:hidden">{supportedLanguages.find(lang => lang.value === selectedLanguages.target)?.labelEn}</span>
                <FormControl variant="standard" sx={{ m: 1, width: isMobile ? 0 : 100 }}>
                  <Select
                    className="invisible"
                    anchorel={targetAnchorEl}
                    open={Boolean(targetAnchorEl)}
                    MenuProps={MenuProps}
                    onClose={() => { handleClose('target') }}
                    onChange={(e) => handleLanguageChange(e.target.value, 'target')}
                  >
                    {supportedLanguages.map((lang) => (
                      <MenuItem
                        key={lang.value}
                        value={lang.value}
                        disabled={lang.value === selectedLanguages.source}
                      >
                        {isArabic ? lang.labelAr : lang.labelEn}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : (
            <LangSwitchBtn
              model={model}
              setDictionary={setDictionary}
              setSuggestion={setSuggestion}
              setTtext={setTtext}
              lLang={lLang}
              rLang={rLang}
              onClickHandler={onLangSwitchClickHandler}
              translatedText={translations.translatedText}
              frontendModel={frontendModel} // Pass the frontendModel prop
              disabled={isLangSwitchDisabled} // Add the disabled attribute

            />
          )}
          {/* Add the button to open the modal */}
          {lang === "en" && model.startsWith('en-') && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}

          {(lang === "ar" || model.startsWith('ar-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                {ttext.length > 0 && (
                  <div
                    className="absolute left-3 top-2 z-30 cursor-pointer"
                    onClick={() => removeText()}
                  >
                    <RoundedCloseIcon />
                  </div>
                )}
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder}`}
                >
                  <div className={styles.translateTextAreaContainer}>
                    <textarea
                      className={`${styles.translateTextArea} ${styles.translateTextAreaAR} border-0 text-right font-urwarabic`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder="أنت تكتب، و AMT تُترجم"
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right pr-6 pt-2 text-base`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={`${styles.twidgetTranslateContainer}`}>
                  <div
                    className={`${styles.translateTextAreaContainer} px-2 pb-4 ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}
                  >
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={t.tarjamaEN} lang={"en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0`}
                          value={translations?.translatedText ?? ""}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex items-center justify-between">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>
              {dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "tr" || model.startsWith('tr-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                {ttext.length > 0 && (
                  <div
                    className="absolute left-3 top-2 z-30 cursor-pointer"
                    onClick={() => removeText()}
                  >
                    <RoundedCloseIcon />
                  </div>
                )}
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder}`}
                >
                  <div className={styles.translateTextAreaContainer}>
                    <textarea
                      className={`${styles.translateTextArea} border-0 font-urwarabic`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right pr-6 pt-2 text-base`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={`${styles.twidgetTranslateContainer}`}>
                  <div
                    className={`${styles.translateTextAreaContainer} px-2 pb-4 ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}
                  >
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText ?? ""}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex items-center justify-between">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>
              {dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "fa" || model.startsWith('fa-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                {ttext.length > 0 && (
                  <div
                    className="absolute left-3 top-2 z-30 cursor-pointer"
                    onClick={() => removeText()}
                  >
                    <RoundedCloseIcon />
                  </div>
                )}
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder}`}
                >
                  <div className={styles.translateTextAreaContainer}>
                    <textarea
                      className={`${styles.translateTextArea} ${styles.translateTextAreaAR} border-0 text-right font-urwarabic`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right pr-6 pt-2 text-base`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={`${styles.twidgetTranslateContainer}`}>
                  <div
                    className={`${styles.translateTextAreaContainer} px-2 pb-4 ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}
                  >
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText ?? ""}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex items-center justify-between">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>
              {dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "fr" || model.startsWith('fr-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "de" || model.startsWith('de-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "it" || model.startsWith('it-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "ed" || model.startsWith('es-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "uk" || model.startsWith('uk-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "ru" || model.startsWith('ru-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "vi" || model.startsWith('vi-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "pt" || model.startsWith('pt-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "ur" || model.startsWith('ur-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} ${styles.translateTextAreaAR} border-0 text-right font-urwarabic`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">{loader ?
                      <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                      <textarea
                        ref={textAreaRef}
                        readOnly="readonly"
                        className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                        value={translations?.translatedText}
                      ></textarea>
                    }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "hi" || model.startsWith('hi-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "zh" || model.startsWith('zh-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "fa" || model.startsWith('fa-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "nl" || model.startsWith('nl-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "ml" || model.startsWith('ml-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "ta" || model.startsWith('ta-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "kn" || model.startsWith('kn-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "ja" || model.startsWith('ja-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          {(lang === "th" || model.startsWith('th-')) && (
            <div className={`${styles.twidgetContainer} rounded-md`}>
              <div className={`${styles.twidget} rounded-md`}>
                <div
                  className={`${styles.twidgetTranslateContainer} ${isArabic ? styles.twidgetBorderAr : styles.twidgetBorder} relative`}
                >
                  {ttext.length > 0 && (
                    <div
                      className="absolute right-4 top-1 z-30 cursor-pointer"
                      onClick={() => removeText()}
                    >
                      <RoundedCloseIcon />
                    </div>
                  )}
                  <div
                    className={`${styles.translateTextAreaContainer} pl-2 pb-4`}
                  >
                    <textarea
                      className={`${styles.translateTextArea} border-0 p-1 pr-10`}
                      onChange={(e) => changeTextArea(e)}
                      onMouseUp={handleMouseUp}
                      value={ttext}
                      placeholder={t.TextareaPlaceholder}
                    ></textarea>
                    <div
                      className={`${styles.charCounter} font-medium text-right text-base pr-6 pt-2`}
                    >
                      {ttext.length}/{charLimit}
                    </div>
                  </div>
                </div>
                <div className={styles.twidgetTranslateContainer}>
                  <div className={`${styles.translateTextAreaContainer} ${loader && isMobile ? 'border' : ''}`}
                    style={{ minHeight: '230px' }}>
                    <div className="relative">
                      {loader ?
                        <Loader loaderText={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? t.tarjamaAR : t.tarjamaEN} lang={model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? "ar" : "en"} /> :
                        <textarea
                          ref={textAreaRef}
                          readOnly="readonly"
                          className={`${styles.translateTextArea} border-0 font-urwarabic rtl-input ${model.endsWith('ar') || model.endsWith('fa') || model.endsWith('ur') ? 'text-right' : 'text-left'}`}
                          value={translations?.translatedText}
                        ></textarea>
                      }
                    </div>
                    {translations?.translatedText && (
                      <div className="iconContainer px-3 flex justify-between items-center font-urwarabic">
                        <div className="leftIcon flex gap-5">
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("like")}
                          >
                            <LikeIcon color={defaultLikeIconColor} />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => setReaction("dislike")}
                          >
                            <DisLikeIcon color={defaultDislikeIconColor} />
                          </span>
                        </div>
                        <div className="RightIcon flex items-center">
                          <span className="pr-3 cursor-pointer">
                            <div className="share-content flex justify-center">
                              <button
                                type="button"
                                onClick={() =>
                                  setToggleShare(
                                    toggleShare == true ? false : true
                                  )
                                }
                              >
                                <ShareIcon />
                              </button>
                            </div>
                          </span>
                          <button
                            className="pr-3 cursor-pointer"
                            onClick={(e) => showSuggestionModal(e)}
                          >
                            <Image src={messageIcon} width={23} height={23} />
                          </button>
                          <span
                            className="cursor-pointer"
                            onClick={copyToClipboard}
                          >
                            <Image src={copyIcon} width={23} height={23} />
                          </span>
                        </div>
                      </div>
                    )}
                    <Notifier
                      notificationText={notificationText}
                      showNotifier={showNotifier}
                      nTextColor={nTextColor}
                      nBgColor={nBgColor}
                      nPosition={nPosition}
                      icon={nIcon}
                      setShowNotifier={setShowNotifier}
                    />
                    {ttext && limitExceed && (
                      <AccountOverlay t={t} limitExceedType={limitExceedType} limitExceedMsg={limitExceedMsg} />
                    )}
                  </div>
                </div>
              </div>

              {ttext && dictionary.length > 0 && (
                <div className="p-10">
                  {/* {dictionary} */}
                  {dictionary &&
                    dictionary[0] &&
                    dictionary[0]["hwi"] &&
                    dictionary[0]["hwi"]["prs"] && (
                      <>
                        <p>
                          <b>{dictionary[0]?.fl}</b>
                        </p>
                        <p>{dictionary[0]?.shortdef[0]}</p>
                        {dictionary[0]["hwi"]["prs"][0]["sound"] && (
                          <AudioPlayer
                            key={
                              dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]
                            }
                            link={`https://media.merriam-webster.com/audio/prons/en/us/mp3/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]["0"]}/${dictionary[0]["hwi"]["prs"][0]["sound"]["audio"]}.mp3`}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
              {suggestion !== "" && (
                <div className="p-10">
                  <b>{t.Suggestions}</b>
                  <Text as="p">{suggestion}</Text>
                </div>
              )}
            </div>
          )}
          <div
            className={`share w-[200px] bg-white rounded-sm absolute shadow-xl right-6 p-3 -bottom-24 ${toggleShare ? "expand" : "hidden"
              }`}
          >
            <div
              className="close absolute right-2 top-1 text-red cursor-pointer"
              onClick={() => {
                setToggleShare(false);
              }}
            >
              ×
            </div>
            <div
              className={`mb-4 font-semibold text-[16px] ${lang == "ar" ? "text-right" : ""
                }`}
            >
              {t.share}:
            </div>
            <ul className="flex justify-between">
              <li className="linkedin text-[8px] text-center font-semibold text-grey800">
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${AMT_LINK}`} target="_blank" >
                  <LinkedinIcon size={32} round className="mb-1 mx-auto" />
                  {t.linkedin}
                </a>
              </li>
              <li className="facebook text-[8px] text-center font-semibold text-center text-grey800">
                <FacebookShareButton url={AMT_LINK}>
                  <FacebookIcon size={32} round className="mb-1 mx-auto" />{" "}
                  {t.facebook}
                </FacebookShareButton>
              </li>
              <li className="twitter text-[8px] text-center font-semibold text-center text-grey800">
                <TwitterShareButton url={AMT_LINK}>
                  <TwitterIcon size={32} round className="mb-1 mx-auto" />
                  {t.twitter}
                </TwitterShareButton>
              </li>
              <li className="whatsapp text-[8px] text-center font-semibold text-center text-grey800">
                <WhatsappShareButton
                  title="Tarjama"
                  separator="-"
                  url={AMT_LINK}
                >
                  <WhatsappIcon size={32} round className="mb-1 mx-auto" />
                  {t.whatsapp}
                </WhatsappShareButton>
              </li>
            </ul>
          </div>
        </div>
      </form>
      {
        (!userPlan || userPlan !== 3) && <div className="hidden md:block">
          <button className={`absolute top-24 p-1 bg-blue-500 text-white rounded border border-red ${translations.translatedText?.length ? 'robot-show' : 'robot-hide'}`}
            onClick={handleOpenModal}>
            <Image src={feedbackIcon} width={35} height={35} />
          </button>
          <FeedbackModel isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
      }
    </>
  );
};

export default Twidget;
