import React from "react";
import { useEffect, useState } from "react";

const AudioPlayer = ({ link = false }) => {
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    setAudio(new Audio(link)); // only call client
  }, [link]);

  const start = () => {
    audio.play();
  };
  return (
    <>
      <span className="pr-[10px] mb-5 leading-[35px] w-[10px] cursor-pointer">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width={40}
          onClick={start}
        >
          <path
            d="M15.5355 8.46448C17.4881 10.4171 17.4881 13.5829 15.5355 15.5355M18.364 5.63599C21.8787 9.15071 21.8787 14.8492 18.364 18.3639M5.58579 15.0001H4C3.44772 15.0001 3 14.5523 3 14.0001V10.0001C3 9.44777 3.44772 9.00005 4 9.00005H5.58579L10.2929 4.29294C10.9229 3.66298 12 4.10915 12 5.00005V19.0001C12 19.891 10.9229 20.3371 10.2929 19.7072L5.58579 15.0001Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </span>
    </>
  );
};

export default AudioPlayer;
