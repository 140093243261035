const PptxIcon = () => {
  return (
    <svg
      width="40"
      height="55"
      viewBox="0 5 35 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0882 64H3.91177C2.30364 64 1 62.465 1 60.5714V19.4286C1 17.535 2.30364 16 3.91177 16H23.8L37 31.6V60.5714C37 62.465 35.6964 64 34.0882 64Z"
        fill="url(#paint0_linear_263_5211)"
      />
      <path
        d="M9.57141 46.95H7.04141V54H8.65141V51.9H9.57141C11.3514 51.9 12.7214 51.56 12.7214 49.42C12.7214 47.29 11.3514 46.95 9.57141 46.95ZM8.65141 50.5V48.36H9.67141C10.6214 48.36 11.1114 48.46 11.1114 49.42C11.1114 50.4 10.6214 50.5 9.67141 50.5H8.65141ZM16.0558 46.95H13.5258V54H15.1358V51.9H16.0558C17.8358 51.9 19.2058 51.56 19.2058 49.42C19.2058 47.29 17.8358 46.95 16.0558 46.95ZM15.1358 50.5V48.36H16.1558C17.1058 48.36 17.5958 48.46 17.5958 49.42C17.5958 50.4 17.1058 50.5 16.1558 50.5H15.1358ZM19.4074 46.95V48.36H21.7174V54H23.3174V48.36H25.6174V46.95H19.4074ZM27.0205 54L28.1805 52.37L29.3505 54H31.2605L29.3405 51.67V51.27L31.2605 48.95H29.3505L28.1805 50.57L27.0205 48.95H25.1105L27.0305 51.27V51.67L25.1105 54H27.0205Z"
        fill="white"
      />
      <g filter="url(#filter0_d_263_5211)">
        <path
          d="M37 31.6C29.0095 31.6 22.8035 24.6367 23.7194 16.6989L23.8 16L37 31.6Z"
          fill="url(#paint1_linear_263_5211)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_263_5211"
          x="0.629395"
          y="-8"
          width="63.3706"
          height="65.6001"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="1" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_263_5211"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_263_5211"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_263_5211"
          x1="19"
          y1="16"
          x2="19"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7245" />
          <stop offset="1" stopColor="#FF5721" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_263_5211"
          x1="29.5"
          y1="16"
          x2="29.5"
          y2="31.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5822" />
          <stop offset="1" stopColor="#E93F08" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PptxIcon;
