import Link from "next/link";
import Image from "next/image";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { useContext, useState } from "react";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import styles from "./AccountOverlay.module.css";
import SignupModal from "../ModalPopup/ModalPopup";
import userIconRd from "@/assets/images/user-white.png";
import userIconBlack from "@/assets/images/user-green.png";
import { USER_MEMBERSHIP_MANAGEMENT } from "@/common/utils/constant";

const AccountOverlay = ({ t, limitExceedType, limitExceedMsg }) => {
  const usercontext = useContext(UserContext);
  const user = usercontext.user;
  const [isHovering, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const setModalType = usercontext.setModalType;
  const setShowModal = usercontext.setShowModal;
  const charLimit = usercontext.charLimit;
  const requestQuota = usercontext.requestQuota;
  const docRequestQuota = usercontext.docRequestQuota;
  const docWordsCount = usercontext.docWordsCount;
  const allowedFormats = usercontext.allowedFormats;
  const appcontext = useContext(AppContext);
  const isArabic = appcontext.locale === "ar";

  const showModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };
  return (
    <div
      className={`${styles.accountOverlay} text-white w-full left-0 right-0 bottom-0 ${isArabic ? "rtl-input" : ""}`}
    >
      <div className={styles.accountOverlayContent}>
        <div className="flex justify-between flex-col md:flex-row">
          <div className={`${isArabic ? "" : "text-left"}`}>
            <Text
              as="p"
              className={`text-base sm:text-base text-medium font-light ${isArabic ? 'text-right' : 'text-left'}`}
            >
              <span className="italic font-normal">
                {limitExceedMsg}
              </span>
            </Text>
            <Text as="h5" className="text-md font-semibold sm:text-[20px]">
              {user && user.plan !== 3 ? limitExceedType === 5 ? t.allowedFileFormatsErrorText1 : t.upgradeYourAccount : ''}
              {!user && t.createAccountMoreTranslationText}
            </Text>
          </div>
          {user ? (user.plan !== 3 &&
            <div className="flex justify-between items-center w-full sm:w-[50%] md:w-[70%] lg:w-[50%] xl:w-[40%] max-w-[250px]">
              <Link href={USER_MEMBERSHIP_MANAGEMENT}>
                <a className="btn-red bg-white text-green rounded-full">
                  {t.upgrade}
                </a>
              </Link>
            </div>
          ) : (
            <div className={`flex justify-between items-center w-full ${isArabic
              ? "sm:w-[50%] md:w-[80%] lg:w-[70%] xl:w-[60%]"
              : "sm:w-[50%] md:w-[70%] lg:w-[50%] xl:w-[40%]"
              }  max-w-[250px] mt-4`}>
              <Button
                type="button"
                className="btn btn-red bg-white text-green rounded-full py-[8px] text-base sm:text-[13px] btn mt-0"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={() => showModal("signup")}
              >
                <span className={` ${isArabic
                  ? "pl-[5px]"
                  : "pr-[5px]"
                  }`}>
                  {isHovering ? (
                    <Image src={userIconRd} width={20} height={20} />
                  ) : (
                    <Image src={userIconBlack} width={20} height={20} />
                  )}
                </span>
                {t.Signup}
              </Button>
              <span>{t.Or}</span>
              <button type="button" onClick={() => showModal("signin")}>
                <a>{t.Login}</a>
              </button>
            </div>
          )}
        </div>
      </div>
      <SignupModal />
    </div >
  );
};

export default AccountOverlay;
