export default function Notifier({ ...props }) {
  const {
    showNotifier,
    notificationText,
    icon,
    nTextColor,
    nBgColor,
    setShowNotifier,
    nPosition,
  } = props;
  const Icon = icon;
  return (
    <>
      {showNotifier ? (
        <div
          className={`p-2 absolute notifier shadow-xl bg-white rounded-sm pr-6 ${nBgColor} easeExtend ${nPosition}`}
        >
          <div
            className={`close absolute right-2 top-1 text-red cursor-pointer ${nTextColor}`}
            onClick={() => {
              setShowNotifier(false);
            }}
          >
            ×
          </div>
          <p
            className={`text-sm ${nTextColor} flex items-center font-semibold`}
          >
            {Icon && <span className="mr-4 w-[18px] h-[18px]">{Icon}</span>}{" "}
            {notificationText}
          </p>
        </div>
      ) : null}
    </>
  );
}
